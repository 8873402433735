import { mapGetters } from "vuex";
import productionLogHeader from "@/assets/table/production/productionLogHeader";
import PorgressTooltip from "@/components/atoms/VTooltip/ProgressTooltip.vue";
import editForm from "@/components/organisms/EditForm/editForm.vue";
import { downloadFileNamed } from "@/api/file";
import docContextMenu from "@/components/atoms/ContextMenu/DocContextMenu.vue";
export default {
  data() {
    return {
      productionLogHeader,
      onRow: "",
      progressItem: { memo: "" },
      menuKindChecks: [7, 8, 9, 10, 12, 15, 16, 17],
    };
  },
  components: {
    PorgressTooltip,
    editForm,
    docContextMenu,
  },
  computed: {
    ...mapGetters({
      userList: "getUserList",
      form: "getProductionForm",
      loginUser: "getLoginUser",
      editRowData: "getEditRowData",
    }),
    fullNullLength() {
      let nullLength = 0;
      if (this.form.progress.length) {
        for (let i = 0; this.form.progress.length + i <= 17; i++) {
          nullLength = i;
        }
      }
      return nullLength;
    },
  },
  methods: {
    closeStatus(kind) {
      return kind === "release" ? true : false;
    },

    shrinkageKind(id) {
      const manufacture = this.form.manufacture.find((x) => x.id === id);
      return `가공의뢰서 (${
        manufacture.shrigkage ? manufacture.shrigkage : 0
      }%)`;
    },

    progressKind(item) {
      if (!item.kind) return "";
      return item.kind === 1
        ? "오더"
        : item.kind === 2
        ? "BT 의뢰"
        : item.kind === 3
        ? "BT 전달"
        : item.kind === 4
        ? "BT CMF"
        : item.kind === 5
        ? "MAIN 전달"
        : item.kind === 6
        ? "MAIN CMF"
        : item.kind === 7
        ? "가공의뢰서"
        : item.kind === 8
        ? "매입 출고의뢰서"
        : item.kind === 9
        ? "매출 출고의뢰서"
        : item.kind === 10
        ? "검사 의뢰서"
        : item.kind === 11
        ? "외관 검사서"
        : item.kind === 12
        ? "선매입"
        : item.kind === 13
        ? "이화학 의뢰"
        : item.kind === 14
        ? "이화학 결과"
        : item.kind === 15
        ? "매입/매출 출고의뢰서"
        : item.kind === 16
        ? "출고의뢰서"
        : item.kind === 17
        ? "재고 매입"
        : item.kind === 18
        ? this.shrinkageKind(item.id)
        : item.kind === 19
        ? "생지 매입"
        : "";
    },

    cancelLabel(status) {
      return status === "99"
        ? "취소 완료"
        : status !== "40" || status !== "90"
        ? "취소"
        : status === "40"
        ? "취소"
        : "취소 요청";
    },
    //progress status 검색 함수
    progrStatus(status, kind) {
      return status === "00"
        ? "마감"
        : status === "20"
        ? "승인 요청"
        : status === "30"
        ? "반려"
        : status === "40"
        ? kind === "appearance" || kind === "requestAppearance"
          ? ""
          : "승인 완료"
        : status === "50"
        ? "수정 요청"
        : status === "90"
        ? "취소 요청"
        : status === "99"
        ? "취소 완료"
        : status === "70"
        ? "임시 의뢰"
        : "";
    },
    // progress 클릭시 각 의뢰서 open
    async openDialog(data, index) {
      this.$store.dispatch("EDIT_FORM_CLOSE");
      await this.$store.dispatch(
        "PRODUCTION_ORDER_CHANGE",
        this.$store.state.productionOrderForm,
      );
      if (data.id) await this.$store.dispatch("GET_ATTACHMENTLIST", data.id);

      if (this.requestDocCheck(data.kind)) {
        this.$store.commit("setProductionProgress", {
          ...data,
          index: index,
          isIndex: !index,
        });

        this.$store.dispatch("PRODUCTION_OPENDIALOG");
      } else {
        const progress = this.copyData(data);
        progress.index = index;
        if (progress.kind !== 1) this.$store.commit("setIsProgressDialog");
        this.$store.commit(
          "setProgressFileList",
          this.$store.state.attachmentList,
        );
        this.$store.commit("setProgressData", progress);
      }
    },

    // fabric 문서를 클릭할경우
    openFabricForm(data, index) {
      this.$store.commit("setProductionProgress", {
        ...data,
        index: index,
        isIndex: !index,
      });

      this.$store.dispatch("FABRIC_PRODUCTION_DOC_OPEN", {
        order: this.form.order,
        company: this.form.company,
        id: data.id,
        kind: data.kind,
      });
    },

    // 기존 문서와 fabric 문서 확인하여 문서폼 을 출력 함수
    clickProgress(item, index) {
      if (item.kind === 18 || item.kind === 19)
        this.openFabricForm(item, index);
      else this.openDialog(item, index);
    },

    // progress body class 를 분별하여 반환 하는 함수
    progressBodyClass(progress, head) {
      let className = head === "special_note" ? "bodyItemRight" : "bodyItem";

      if (
        head === "special_note" &&
        this.requestDocCheck(progress.kind) &&
        progress.id
      )
        className = `${className} bodyItemBackColor`;
      return className;
    },

    clieckRow(i, item) {
      this.onRow = i;
      this.progressItem = this.copyData(item);
    },
    clearRow() {
      this.onRow = "";
      this.progressItem = { memo: "" };
    },
    async savePrgressMemo() {
      this.$store.commit("setAPILoading", true);
      await this.$store.dispatch("SELECT_ORDER", this.form);
      const saveOrder = this.$store.state.selectOrder;
      this.$store.commit("setSelectOrder");
      saveOrder.progress[this.onRow] = this.progressItem;

      this.$store.commit("setSaveData", saveOrder);
      this.$store.dispatch("SAVE_ORDER");
      this.clearRow();
    },
    async previewImage(file) {
      this.$store.commit("setIsPreviewDialog", true);
      try {
        const result = await downloadFileNamed(
          this.$store.state.auth_company,
          file.name,
        );

        if (result.status !== 200) throw `error: ${result.status}`;

        const imageFile = new Blob([result.data]);
        const reader = new FileReader();
        reader.onload = () => {
          const imageUrl = reader.result; // Base64 인코딩된 이미지 URL
          this.$store.commit("setPreviewImage", imageUrl);
        };

        reader.readAsDataURL(imageFile);
      } catch (e) {
        console.log(e);
      }
    },
    menuOpen(event, item) {
      if (this.menuKindChecks.findIndex((x) => x === +item.kind) < 0) return;
      this.$store.dispatch("PRODUCTION_DOC_CONTEXT_MENU_OPEN", item);
      this.$refs.docMenu.$children[0].open(event);
    },
  },
};
