<template>
  <div>
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: right;
        padding-bottom: 2px;
      "
    >
      <div style="font-weight: 700; margin-top: 2px">
        {{ this.toggleLabel }}
      </div>
      <input v-model="isCardProgress" type="checkbox" class="inputToggle" />
    </div>

    <template v-if="$store.state.productionOrderForm.order">
      <CardPorgress v-if="isCardProgress" />
      <ListProgress v-else />
    </template>
    <div v-else class="notOrderDiv">오더를 선택해주세요</div>
  </div>
</template>
<script>
import CardPorgress from "@/components/pages/production/progress/list/CardProgress.vue";
import ListProgress from "@/components/pages/production/progress/list/ListProgress.vue";

export default {
  components: {
    CardPorgress,
    ListProgress,
  },

  computed: {
    toggleLabel() {
      return this.isCardProgress ? "리스트 형" : "카드 형";
    },
  },

  data() {
    return {
      isCardProgress: true,
    };
  },

  created() {
    if (this.$store.state.auth_progress_type === 2) this.isCardProgress = false;
  },
};
</script>
