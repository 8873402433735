import { mapGetters } from "vuex";
import releaseHeaders from "@/assets/table/production/releaseColorHeader";
import releaseAppearanceHeaders from "@/assets/table/production/releaseAppearanceHeaders";
import PrintReleaseDialog from "@/components/organisms/VDialog/PrintReleaseDialog.vue";
import settingFormDialog from "@/components/organisms/VDialog/SettingFormDialog";
import ClientComboBox from "@/components/atoms/VComboBox/DenseDialogClientComboBox";
import DocForm from "@/components/organisms/docDataForm/docForm.vue";
import DenseSubItemComboBox from "@/components/atoms/VComboBox/DenseSubItemComboBox.vue";
import DenseLabelSelect from "@/components/atoms/VSelect/DenseLabelSelect.vue";
import TitleForm from "@/components/atoms/InputForm/TitleForm";
import ReleaseDocCacul from "@/components/atoms/OutPutTxt/ReleaseDocCacul";

import { getFabricStocks } from "@/api/production";

export default {
  components: {
    PrintReleaseDialog,
    settingFormDialog,
    ClientComboBox,
    DocForm,
    DenseSubItemComboBox,
    TitleForm,
    ReleaseDocCacul,
    DenseLabelSelect,
  },
  data() {
    return {
      menu_date_delivery: false,
      dialog_bak_release: false,
      deadlineRelease: {},
      bakupData: {},
      useColorList: [],
      releaseHeaders,
      releaseAppearanceHeaders,
      releaseAppearanceColor: {},
      memo: "",
      releaseStyleColorList: [],
      selectType: "출고의뢰서",
      releaseType: "",
      stocks: [],
      useId: "",

      isOverSize: false,
      selectRelease: {
        item: {},
        order: {},
        liaison: {},
        place_forward: {},
        place_receive: {},
      },

      stockKind: [
        {
          value: 1,
          label: "전 구좌 사용",
        },
        {
          value: 2,
          label: "현 구좌 사용",
        },
      ],

      fabricTypes: [
        {
          value: false,
          name: "생지 매입",
        },
        {
          value: true,
          name: "생지 재고",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      form: "getProductionForm",
      fabric: "getFabricForm",
      itemList: "getItemList",
      fabricItems: "getFabricItems",
      clientList: "getUseClientList",
      beforVersion: "getBeforProductionData",
      loginUser: "getLoginUser",
    }),

    unitSigns() {
      return [...this.$store.state.unit_sign, "kg"];
    },

    emptyColors() {
      return Array.from({ length: 6 }, (_, i) => {
        return { id: i };
      });
    },

    fabricQuantity() {
      return this.usingComma(this.fabric.color_list[0].quantity);
    },

    totalQuantity() {
      return `${this.fabricQuantity} ${
        this.fabricQuantity && this.fabric.unit_sign
      }`;
    },

    unitPrice() {
      return this.usingComma(this.fabric.purchaseUnitPrice);
    },

    eschamgePrice() {
      return this.usingComma(this.fabric.eschamge);
    },

    vatBtnClass() {
      return this.fabric.isNotVat ? "vatBtnRedStyle" : "vatBtnGreenStyle";
    },

    stockRemaining() {
      const color = this.fabric.color_list[0];
      const reaminQuantity = +color.stockQuantity - (+color.quantity || 0);
      return isNaN(reaminQuantity) ? "" : reaminQuantity;
    },

    unitPriceTotal() {
      let price = this.unitPrice;
      if (this.fabric.currency_sign !== "₩")
        price += this.fabric.eschamge ? ` (₩${this.eschamgePrice}) ` : "";
      return price;
    },

    AmountTotal() {
      let price = "";
      const amount =
        this.fabric.purchaseUnitPrice * this.fabric.color_list[0].quantity;

      if (!isNaN(price) && amount)
        price += `${this.fabric.currency_sign} ${this.usingComma(
          this.roundsData(amount),
        )}`;

      if (this.fabric.currency_sign !== "₩" && this.fabric.eschamge) {
        const totalEshamge = this.fabric.eschamge * amount;
        price +=
          !isNaN(totalEshamge) && totalEshamge
            ? `($${this.usingComma(this.roundsData(totalEshamge))})`
            : "";
      }

      return price;
    },

    wherHouseList() {
      const list = this.copyData(this.clientList);
      list.unshift({
        name: "미지정",
      });
      return list;
    },

    currencyLable() {
      return this.fabric.currency_sign === "₩"
        ? !this.isDocDataBtn
          ? "매입단가"
          : "단가"
        : !this.isDocDataBtn
        ? "매입 단가/환율"
        : "단가 환율";
    },

    stocksParam() {
      return {
        company: this.$store.state.auth_company,
        use_id: this.useId,
      };
    },

    colorConvertSign() {
      return this.fabric.unit_sign === "kg"
        ? this.fabric.weight_unit_sign
        : "kg";
    },
  },

  methods: {
    //////////////////////////////////////// 공통 기능 /////////////////////////////////////
    snackbarOn(text) {
      this.$store.commit("setSnackBar", text);
    },

    //////////////////////////////////////// 출고의로시 입력 기능 /////////////////////////////////////
    async getFabricStocks() {
      try {
        const result = await getFabricStocks(this.stocksParam);

        this.stocks = result.data;
      } catch (e) {
        console.log(e);
      }
    },

    conmmitFabricForm() {
      this.$store.commit("setFabricForm", this.fabric);
    },

    chageIsVat() {
      this.fabric.isNotvat = !this.fabric.isNotvat;
      this.conmmitFabricForm();
    },

    inputPrice(price, name) {
      this.fabric[name] = price;
      this.conmmitFabricForm();
    },

    inputQuantity(quantity) {
      this.fabric.color_list[0].quantity = this.parsingNum(quantity);
      this.conmmitFabricForm();
    },

    // 재고 사용 수량 입력
    inputStockQuantity(quantity) {
      const reaminQuantity =
        this.fabric.color_list[0].stockQuantity - +quantity;
      this.inputQuantity(
        isNaN(reaminQuantity) || reaminQuantity < 0 ? 0 : quantity,
      );
    },

    // 단가 단위 변경시 환율 초기화
    chageCurrencySign() {
      this.fabric.eschamge = "";
    },

    unitSignChange() {
      if (this.fabric.unit_sign !== "g" && this.fabric.unit_sign !== "kg")
        this.fabric.weight_unit_sign = this.fabric.unit_sign;
    },

    // 출고 방법 변경
    chageFabricTypes() {
      this.fabric.color_list = [
        {
          stock_name: "",
          quantity: "",
        },
      ];
      if (this.fabric.isFabricStock) {
        this.fabric.purchaseUnitPrice = 0;
        this.getFabricStocks();
      }
    },

    // 생지 재고 변경
    changeFabricColor(fabircStock, quantity) {
      const stock = this.copyData(fabircStock);
      const color = {
        name: stock.name,
        stock_name: stock.stockName,
        stockQuantity: stock.reaminQuantity,
        quantity,
        stock_id: stock.id,
      };
      this.fabric.unit_sign = stock.unit_sign;
      this.fabric.color_list[0] = color;
      this.conmmitFabricForm();
    },

    // 생지 문서를 열경우 생지 item을 검색하여 데이터에 넣어주는 함수
    setFabricItems() {
      this.fabric.color_list[0].stock_name = this.fabricItems.find(
        (x) => x._id === this.fabric.color_list[0].stock_name,
      );
    },

    // 재고 생지 사용 할경우 실행
    async setFabricStocks() {
      const color = this.fabric.color_list[0];
      this.useId = color.use_stock_id;
      await this.getFabricStocks();
      this.changeFabricColor(
        this.stocks.find((x) => x.id === color.stock_id),
        color.quantity,
      );
    },

    //////////////////////////////////////// 출고의로시 기타 기능 /////////////////////////////////////

    //신규 다이얼로그 오픈
    checkNewSettingForm(e, kind, refs) {
      if (e.key !== "Enter") return;

      const editedItem = {};
      editedItem.doc = "release";
      this.$store.commit("setEditedItemForm");
      const input =
        typeof e.target.value === "string" && e.target.value !== null
          ? e.target.value.trim().toLowerCase()
          : e.target.value.name.trim().toLowerCase();
      if (
        kind === "place_forward" ||
        kind === "place_receive" ||
        kind === "place_sales"
      ) {
        if (!this.clientList.some((x) => x.name.toLowerCase() === input)) {
          editedItem.name_only = input;
          editedItem.target = kind;
          editedItem.type = "Client";
          this.$store.commit("setEditedItemForm", editedItem);
          this.$store.commit("setDailogStatus", {
            status: true,
            kind: "setting_form",
          });
        }
      }
      if (kind === "item") {
        if (!this.itemList.some((x) => x.name.toLowerCase() === input)) {
          editedItem.unit_price_sign = "₩";
          editedItem.unit_length = "y";
          editedItem.unit_width = "in";
          editedItem.unit_weight = "g/y";
          editedItem.inspect_status = true;
          editedItem.name = input;
          editedItem.target = kind;
          editedItem.type = "Item";
          this.$store.commit("setEditedItemForm", editedItem);
          this.$store.commit("setDailogStatus", {
            status: true,
            kind: "setting_form",
          });
        }
      }
    },

    // 컬러의 길이를 무게로 또는 무게를 길이로 변환 하여 반환 하는 함수
    weightToLength() {
      const quantity = this.fabric.color_list[0].quantity;

      return +this.fabric.weight_convert_unit === 0
        ? 0
        : this.weightCacul(
            quantity,
            this.fabric.unit_sign,
            this.fabric.weight_convert_unit,
            this.fabric.weight_sign,
          );
    },

    //연필버튼 동작
    openEditDialog(item, target, type) {
      this.$store.commit("setEditedItemForm");
      this.editedItem = { ...item };

      this.editedItem.type = type;
      if (type === "Item")
        this.editedItem.production = this.editedItem.production
          ? this.clientList.find((x) => x._id === this.editedItem.production)
          : "";
      this.editedItem.company = this.$store.state.auth_company;
      this.editedItem.business_num
        ? (this.editedItem.business_num = this.getBusinessNumMask(
            this.editedItem.business_num,
          ))
        : "";
      this.editedItem.unit_price = this.editedItem.unit_price
        ? this.usingComma(this.editedItem.unit_price)
        : "";
      this.editedItem.tel
        ? (this.editedItem.tel = this.getPhoneMask(this.editedItem.tel))
        : "";
      this.$store.commit("setEditedItemForm", this.editedItem);
      this.$store.commit("setDailogStatus", {
        status: true,
        kind: "setting_form",
      });
    },
  },
  async created() {
    this.setFabricItems();

    if (this.fabric.isFabricStock) await this.setFabricStocks();
  },
};
