<template>
  <v-dialog
    :value="dialog"
    max-width="800px"
    @click:outside="close()"
    @keydown.esc="close()"
  >
    <v-card>
      <v-toolbar style="background-color: rgba(0, 0, 0, 0.12); height: 60px">
        <v-card-title>
          {{ title }}
        </v-card-title>
      </v-toolbar>

      <v-card-text style="height: 600px" class="pt-3">
        <div style="height: 5%">
          <div class="docInfoDiv">
            <span style="font-weight: bold">ORDER :</span>
            <span>{{ this.$store.state.productionOrderForm.order }}</span>
          </div>

          <div class="docInfoDiv">
            <span style="font-weight: bold"> 작성날짜 :</span>
            <span>
              {{
                progress.date_register ? dateFormat(progress.date_register) : ""
              }}</span
            >
          </div>

          <div class="docInfoDiv">
            <span style="font-weight: bold">작성자 :</span>
            <span>{{ progress.user }}</span>
          </div>

          <div class="docInfoDiv">
            <span style="font-weight: bold"> 문서 종류 :</span>
            <span v-if="progress.id"> {{ progress.log }}</span>
            <div v-else style="width: 60%">
              <CustomSelectField
                v-model="progress.kind"
                :items="docKindList"
                :itemText="'name'"
                @chageSelect="setKind"
              />
            </div>
          </div>
        </div>
        <div style="height: 300px">
          내 용
          <VueEditor
            v-model="progress.memo"
            :editorToolbar="customToolbar"
            style="height: 15em"
          />
        </div>
        <div>
          첨부파일
          <div style="display: flex">
            <v-file-input
              v-model="uploadFile"
              outlined
              hide-details
              dense
              label="최대 업로드용량은 10MB입니다."
              :rules="rules"
              show-size
              class="mr-1"
              hide-input
            />
            <div style="width: 100%" class="pr-4">
              <v-text-field
                dense
                hide-details
                :value="uploadFile.name"
                :readonly="uploadFile.name ? true : false"
                clearable
                @paste="pasteImage"
                placeholder="여기에 이미지를 붙여넣으면 등록할 수 있습니다."
              >
              </v-text-field>
            </div>
            <v-btn
              color="primary"
              :disabled="fileValidate()"
              @click="fileUpload()"
              >등록</v-btn
            >
          </div>
          <div
            style="
              height: 180px;
              border: thin solid;
              overflow-y: overlay;
              overflow-x: hidden;
              margin-top: 3px;
            "
          >
            <div v-for="(file, i) in progressFileList" :key="file.name">
              <div style="display: flex; align-items: center">
                <v-icon small @click="deleteFile(i)" class="pr-1">
                  mdi-delete
                </v-icon>
                <div style="width: 70%">
                  <a v-if="file._id" @click="previewImage(file)">{{
                    file.name
                  }}</a>
                  <span v-else>{{ file.name }} (신규등록)</span>
                </div>
                <div style="width: 15%">
                  {{ formaBytes(file.size ? file.size : 0) }}
                </div>
                <div style="width: 15%">
                  <a @click="dbFileDown(file)"
                    ><v-icon color="green">mdi-download</v-icon></a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="primary" @click="close()" text> 아니오 </v-btn>
        <v-btn color="primary" @click="save()" text> 저 장 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";
import { v4 as uuidv4 } from "uuid";
import CustomSelectField from "@/components/atoms/Select/customSelectField.vue";
import {
  postfile,
  downloadFile,
  deletefile,
  getfileByIdAndName,
} from "@/api/file";

export default {
  components: {
    VueEditor,
    CustomSelectField,
  },
  computed: {
    ...mapGetters({
      dialog: "getIsProgressDialog",
      progress: "getProgressData",
      attachmentList: "getAttachmentList",
      progressFileList: "getProgressFileList",
    }),

    title() {
      return `${this.progress.log ? this.progress.log : ""} 비고`;
    },
  },

  data() {
    return {
      uploadFile: { name: "" },
      customToolbar: [
        [{ font: [] }],
        ["bold", "italic", "underline"],
        [{ color: [] }, { background: [] }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      ],
      rules: [
        (value) =>
          !value || value.size < 10000000 || "업로드 최대용량은 10MB입니다.",
      ],
      deleteFileList: [],
      docKindList: [
        {
          name: "BT 의뢰",
          value: 2,
        },
        {
          name: "BT 전달",
          value: 3,
        },
        {
          name: "BT CMF",
          value: 4,
        },
        {
          name: "MAIN 전달",
          value: 5,
        },
        {
          name: "MAIN CMF",
          value: 6,
        },
        {
          name: "이화학 의뢰",
          value: 13,
        },
        {
          name: "이화학 결과",
          value: 14,
        },
      ],
    };
  },
  methods: {
    setKind(item) {
      this.progress.log =
        item.value === 2
          ? "B/T 의뢰"
          : item.value === 3
          ? "B/T 전달"
          : item.value === 4
          ? "B/T 컨펌"
          : item.value === 5
          ? "MAIN 전달"
          : item.value === 6
          ? "MAIN CMF"
          : item.value === 13
          ? "이화학 의뢰"
          : item.value === 14
          ? "이화학 결과"
          : "";

      this.$store.commit("setProgressData", this.progress);
    },

    fileValidate() {
      return this.uploadFile &&
        this.uploadFile.name &&
        this.uploadFile.size < 10000000
        ? false
        : true;
    },

    async save() {
      if (this.deleteFileList.length) await this.fileDelete();
      await this.fileSave();

      if (!this.progress.id) this.$store.dispatch("NEW_PROGRESS");
      else this.$store.dispatch("MODIFY_PROGRESS");
      await this.$store.dispatch("SAVE_ORDER");

      this.$store.commit("setManufactureForm");
      this.$store.commit("setReleaseForm");
      this.$store.commit("setAppearanceForm");
      this.close();
    },

    async fileSave() {
      for (let i = 0; i < this.progressFileList.length; i++) {
        const progress = this.progressFileList[i];
        if (!progress._id) {
          const formData = new FormData();
          formData.append("id", this.progress.id);
          formData.append("file", this.progressFileList[i]);
          await this.attachmentSave(formData);
        }
      }
    },

    async fileDelete() {
      for (let i = 0; i < this.deleteFileList.length; i++) {
        this.attachmentDelete(this.deleteFileList[i]);
      }
    },

    async attachmentSave(formData) {
      try {
        const result = await postfile(formData);
        if (result.status !== 200) throw `error: ${result.status}`;
      } catch (e) {
        console.log(e);
      }
      this.deleteFileList = [];
    },

    async attachmentDelete(id) {
      try {
        const result = await deletefile(id);

        if (result.status !== 200) throw `error: ${result.status}`;
      } catch (e) {
        console.log(e);
        this.loading = !this.loading;
      }
    },

    async dbFileDown(file) {
      if (file._id) {
        try {
          const result = await downloadFile(
            this.$store.state.auth_company,
            file._id,
          );

          if (result.status !== 200) throw `error: ${result.status}`;

          const downFile = new Blob([result.data]);
          const downloadUrl = window.URL.createObjectURL(downFile);
          let element = document.createElement("a");
          element.href = downloadUrl;
          element.download = file.name;
          element.click();
          window.URL.revokeObjectURL(downloadUrl);
        } catch (e) {
          console.log(e);
        }
      }
    },

    close() {
      this.$store.commit("setIsProgressDialog");
      this.$store.commit("setProgressData");
      this.$store.commit("setProgressFileList");
    },

    fileUpload() {
      const check = this.progressFileList.find(
        (x) => x.name === this.uploadFile.name,
      );

      if (!check) this.progressFileList.push(this.uploadFile);
      this.uploadFile = null;
    },

    deleteFile(i) {
      if (this.progressFileList[i]._id)
        this.deleteFileList.push(this.progressFileList[i]._id);
      this.progressFileList.splice(i, 1);
    },
    async pasteImage(event) {
      let foundImage = false;
      const items = (event.clipboardData || event.originalEvent.clipboardData)
        .items;
      for (let i = 0; i < items.length; i++) {
        // MIME 타입이 "image/"로 시작하는지 확인
        if (items[i].type.startsWith("image/")) {
          const file = items[i].getAsFile();
          const newFileName =
            uuidv4() + file.name.substring(file.name.lastIndexOf("."));
          const newFile = new File([file], newFileName, { type: file.type });

          this.progressFileList.push(newFile);

          foundImage = true;
          break;
        }
      }
      if (!foundImage) return;

      var blob = items[0].getAsFile();
      var reader = new FileReader();

      reader.readAsDataURL(blob);
    },

    async previewImage(file) {
      this.$store.commit("setIsPreviewDialog", true);
      try {
        const result = await downloadFile(
          this.$store.state.auth_company,
          file._id,
        );

        if (result.status !== 200) throw `error: ${result.status}`;

        const imageFile = new Blob([result.data]);
        const reader = new FileReader();
        reader.onload = () => {
          const imageUrl = reader.result; // Base64 인코딩된 이미지 URL
          this.$store.commit("setPreviewImage", imageUrl);
        };

        reader.readAsDataURL(imageFile);
      } catch (e) {
        console.log(e);
      }
    },
  },
  created() {},
};
</script>

<style>
.docInfoDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  width: 25%;
  height: 100%;
  gap: 5px;
}
</style>
