var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"borderTable"},[_c('div',{staticClass:"gridTable"},_vm._l((_vm.productionLogHeader),function(col){return _c('div',{key:col._id,class:col.value === 'special_note' ? 'gridTheadRight' : 'gridThead'},[_vm._v(" "+_vm._s(col.text)+" ")])}),0),_c('overlay-scrollbars',{attrs:{"options":{
      overflowBehavior: {
        x: 'hidden',
      },
    }}},[_c('div',{staticStyle:{"max-height":"calc(100vh - 350px)"}},[_vm._l((_vm.form.progress),function(item,index){return _c('div',{key:item._id,staticClass:"gridTbody",class:{
          selectedRow:
            item.id === _vm.$store.state.productionProgress.id &&
            index === _vm.$store.state.productionProgress.index,
        },on:{"contextmenu":function($event){$event.preventDefault();return _vm.menuOpen($event, item)}}},_vm._l((_vm.productionLogHeader),function(head){return _c('div',{key:head.text,class:_vm.progressBodyClass(item, head.value),on:{"click":function($event){$event.stopPropagation();return _vm.clickProgress(item, index)},"dblclick":function($event){return _vm.$store.dispatch('EDIT_ROW', {
              header: head,
              items: item,
              item: item[head.value],
              index: [index],
              type: 'text',
            })}}},[(head.value === 'date_modify')?_c('span',[_vm._v(" "+_vm._s(_vm.dateFormat(item.date_register))+" ")]):(head.value === 'log_detail')?_c('span',[(item.history.length)?[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-badge',{staticClass:"progressBadge",attrs:{"color":"primary"}},[_c('span',_vm._g(_vm._b({attrs:{"slot":"badge"},slot:"badge"},'span',attrs,false),on),[_vm._v(_vm._s(item.history.length))]),_vm._v(" "+_vm._s(_vm.progressKind(item))+" ")])]}}],null,true)},[_c('span',[_vm._v(" 수정 사항이 "+_vm._s(item.history.length)+"개 있습니다. ")])])]:[_vm._v(" "+_vm._s(_vm.progressKind(item))+" ")]],2):(head.value === 'detail')?_c('span',[_vm._v(" "+_vm._s(item.log)+" "),(item.file_list && item.file_list.length)?[_c('v-menu',{staticStyle:{"position":"absolute","right":"0"},attrs:{"close-on-content-click":false,"nudge-width":200,"close-on-click":true,"max-width":"400px","offset-y":"","transition":"slide-y-transition","right":"","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"grey--text"},'div',attrs,false),on),[_vm._v(" (첨부 "),_c('span',{staticClass:"ont-weight-bold"},[_vm._v(" "+_vm._s(item.file_list.length)+" ")]),_vm._v(" 개) ")])]}}],null,true)},[_c('div',{staticStyle:{"background-color":"white","display":"flex","gap":"15px"}},[_c('v-list',{attrs:{"dense":""}},_vm._l((item.file_list),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.previewImage(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))]),_c('v-list-item-action',{staticClass:"ml-0"},[_c('span',{staticClass:"caption grey--text"},[_vm._v(" "+_vm._s(_vm.dateFormat(item.date))+" ")])])],1)}),1)],1)])]:_vm._e()],2):(head.value === 'liaison')?_c('span',[_vm._v(" "+_vm._s(item.user)+" ")]):(head.value === 'status')?_c('span',{class:{
              'red--text': item.status === '99',
              temporaryDoc: item.status === '70',
            }},[_vm._v(" "+_vm._s(item.status ? _vm.progrStatus(item.status, item.kind) : "")+" ")]):(head.value === 'special_note')?_c('span',{staticStyle:{"width":"100%"}},[(
                _vm.editRowData.index[0] === index &&
                _vm.editRowData.target === 'special_note'
              )?[(_vm.requestDocCheck(item.kind))?_c('editForm'):_vm._e()]:[_c('div',{staticStyle:{"white-space":"nowrap","text-overflow":"ellipsis","overflow":"hidden","max-width":"calc(100vw - 89vw)"}},[(item.memo)?_c('PorgressTooltip',{attrs:{"Tooltip":item.memo}}):_vm._e()],1)]],2):_vm._e()])}),0)}),(_vm.form.progress && _vm.form.progress.length <= 17)?_vm._l((_vm.fullNullLength),function(item,index){return _c('div',{key:index,staticClass:"gridTbody"},_vm._l((_vm.productionLogHeader),function(head){return _c('div',{key:head.text,staticClass:"bodyItem",class:head.value === 'special_note' ? 'nonItem' : ''})}),0)}):_vm._e()],2),_c('docContextMenu',{ref:"docMenu"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }