import { validateEmail } from "@/utils/validation";
import { getCompanyOneById } from "@/api/company";
import { login, check, checkExpired } from "@/api/auth";
import LoadingSpinner from "@/components/organisms/LoadingSpinner/LoadingSpinner";
import { getAuthIdFromCookie } from "@/utils/cookies";
import { postfile, appDownload } from "@/api/file";
export default {
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      uuid: "",
      password: "",
      company: "",
      history_uuid: [],
      show: false,
      reloginStatus: false,
      currentPage: "login",
      historyPage: "",
      company_hint: "회사 아이디를 입력하세요.",
      uuid_hint: "사용자 아이디를 입력하세요. (이메일 형식)",
      password_hint: "사용자 비밀번호를 입력하세요.",
      file: "",
    };
  },
  computed: {
    isLoginValid() {
      return !(
        validateEmail(this.uuid) &&
        this.password &&
        !this.$store.state.isAPILoading
      );
    },
    companyHint() {
      return this.company ? "" : this.company_hint;
    },
    uuidHint() {
      return validateEmail(this.uuid) ? "" : this.uuid_hint;
    },
    passwordHint() {
      return this.password ? "" : this.password_hint;
    },
  },
  methods: {
    changePage(str) {
      this.historyPage = this.currentPage;
      this.currentPage = str;
    },
    newLogin() {
      this.company = "";
      this.uuid = "";
      this.historyPage = this.currentPage;
      this.currentPage = "login";
    },
    async login() {
      if (this.isLoginValid) {
        alert("로그인 정보를 입력하세요.");
        return;
      }

      try {
        const auth = await login({
          uuid: this.uuid,
          password: this.password,
          reloginStatus: this.reloginStatus,
        });

        if (!auth.status === 200) throw `error : ${auth.status}`;

        const expiredData = await checkExpired({ company: auth.data.company });

        if (expiredData.data.errorMessage === "trialPeriodExpired")
          this.$store.commit("setIsExpired", true);
        this.$store.dispatch("LOGIN", {
          company: auth.data.company,
          uuid: this.uuid,
          token: auth.data.token,
        });

        const authCheck = await check();

        if (!authCheck.status === 200) throw `error : ${authCheck.status}`;

        if (authCheck.data.info.isActive) {
          this.$store.dispatch("SET_API_LOADING", true);
          this.$store.dispatch("GET_COMPANY");
          // this.$store.dispatch("SET_USERLIST");
          // this.$store.dispatch("SET_ORDERLIST_DATA");

          this.$store.dispatch("LOGIN_CHECK", {
            _id: authCheck.data.info._id,
            name: authCheck.data.info.name,
            grade: authCheck.data.info.grade,
            fontSizeType: authCheck.data.info.fontSizeType ?? 1,
          });

          this.$router.push("/main");

          this.$store.dispatch("SET_API_LOADING", false);
        } else {
          this.$store.dispatch("LOGOUT");
          alert(
            authCheck.data.info.signStatus === "20"
              ? "현재 로그인 하신 계정은 관리자 확인 중입니다.관리자에게 문의 해주세요."
              : authCheck.data.info.signStatus === "30"
              ? "현재 로그인 하신 계정은 관리자 권한으로 거부 된 상태입니다.확인 부탁드립니다."
              : authCheck.data.info.signStatus === "99"
              ? "현재 로그인 하신 계정은 삭제된 계정입니다. "
              : "",
          );
        }
      } catch (e) {
        alert("아이디 또는 비밀번호를 확인하세요.");
      }
    },

    async getCompany() {
      try {
        const getCompany = await getCompanyOneById(
          this.$store.state.auth_company,
        );
        if (getCompany.status !== 200) throw `error : ${getCompany.status}`;
      } catch (e) {
        alert(e);
      }
    },
    loginHistory(index) {
      this.isHistory = true;
      this.company = this.history_company[index];
      this.uuid = this.history_uuid[index];
      this.changePage("login");
    },
    removeHistory(index) {
      if (confirm("계정을 삭제하시겠습니까?")) {
        this.$store.dispatch("REMOVE_HISTORY", index);
      }
    },

    signMove() {
      this.$router.push("/signup");
      this.$store.commit("setSignType");
    },

    async downloadAndoroid(event, type) {
      const file = await appDownload(type);

      const downFile = new Blob([file.data]);
      const downloadUrl = window.URL.createObjectURL(downFile);
      let element = document.createElement("a");
      element.href = downloadUrl;
      element.download = "wondanMania.apk";
      element.click();
      window.URL.revokeObjectURL(downloadUrl);
    },

    async fileUpload() {
      const formData = new FormData();

      formData.append("file", this.file);
      const result = await postfile(formData);

      if (result.status !== 200) throw `error: ${result.status}`;
      this.file = null;
    },
  },
  created() {
    this.history_company = this.$store.state.history_company;
    this.history_uuid = this.$store.state.history_uuid;

    const current_uuid = getAuthIdFromCookie();
    if (current_uuid) this.uuid = current_uuid;
    if (this.$store.getters.isHistory) {
      this.company = this.$store.state.history_company_login;
      this.uuid = this.$store.state.history_uuid_login;
      this.changePage("login");
      this.$store.dispatch("CLEAR_LOGIN_BY_HISTORY");
    } else if (this.history_uuid.length) this.changePage("history");
  },
  mounted() {},
};
