import Vue from "vue";
import Vuex from "vuex";
import dayjs from "dayjs";
import getters from "./getters.js";
import mutations from "./mutations.js";
import actions from "./actions.js";
//오더 페이지 오더 유형 select data -> 차후 데이터 생기면 삭제
import order_kind from "@/assets/data/kind/OrderKind";
//오더 페이지 단위 select data -> 치후 데이터 생기면 삭제
import sign from "@/assets/data/unit/Sign";
import order_status from "@/assets/data/status/OrderStatus";
import document_kind from "@/assets/data/kind/DocumentKind";
import user_grade from "@/assets/data/UserGrade";
import client_kind from "@/assets/data/kind/ClientKind";
import client_type from "@/assets/data/kind/ClientType";
import newClient_Kind from "@/assets/data/kind/NewClientKind";
import receivableKind from "@/assets/data/kind/ReceivableKind";
import mainHeader from "@/assets/table/main/homeHeaders";
import orderHeader from "@/assets/table/order/orderListHeader";
import productionHeader from "@/assets/table/production/productionOrderHeaders";
import purCacheader from "@/assets/table/purchase/purchaseCalculateHeaders";
import purDedheader from "@/assets/table/purchase/purchaseDeadlineHeaders";
import salesCacheader from "@/assets/table/sales/headersCalculateSales";
import saelsDedheader from "@/assets/table/sales/headersDeadlineSales";
import receiveHeader from "@/assets/table/receivable/receivableHeaders";
import saelsreceiveHeader from "@/assets/table/receivable/receivableSalesHeaders";
import claimHeader from "@/assets/table/claim/claimHeader";
import endClaimHeader from "@/assets/table/claim/claimEndHeader";
import stockHeader from "@/assets/table/stock/stock";
// import home from "@/assets/data/Home";

import {
  getAuthCompanyFromCookie,
  getAuthUuidFromCookie,
  getAuthIdFromCookie,
  getAuthTokenFromCookie,
  getAuthNameFromCookie,
  getAuthGradeFromCookie,
  getHistoryCompanyFromCookie,
  getHistoryUuidFromCookie,
  getHistoryTokenFromCookie,
} from "@/utils/cookies";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // form_backup: {},
    //year list
    yearList: getYearList(1989),
    // selectYearList: getSelectYearList(1989),
    Today: currentDateTime(),
    //오더 페이지 오더 유형 select data -> 차후 데이터 생기면 삭제
    order_kind,
    //오더 페이지 가격 단위 select data -> 치후 데이터 생기면 삭제
    currency_sign: sign.currency,

    //오더 페이지 가격 단위 select data -> 치후 데이터 생기면 삭제
    weight_sign: sign.weight_sign,
    //오더 페이지 수량 단위 select data -> 차후 데이터 생기면 삭제
    unit_sign: sign.unit,
    // 폭 단위
    unit_width: sign.width,
    // 무게 단위
    unit_weight: sign.weight,
    // 오더 상태
    order_status,
    // 문서 종류
    document_kind,
    // 유저 등급
    user_grade,
    // 거래처 종류
    client_kind,
    client_type,
    newClient_Kind,
    // header_alert
    header_alert: [],
    // auth user
    auth_company: getAuthCompanyFromCookie() || "",
    auth_id: getAuthIdFromCookie() || "",
    auth_uuid: getAuthUuidFromCookie() || "",
    auth_token: getAuthTokenFromCookie() || "",
    auth_name: getAuthNameFromCookie() || "",
    auth_grade: getAuthGradeFromCookie() || "",
    auth_logo: "",
    auth_font_size: "1",
    auth_progress_type: 1,
    // login
    history_company: getHistoryCompanyFromCookie() || [],
    history_uuid: getHistoryUuidFromCookie() || [],
    history_token: getHistoryTokenFromCookie() || [],
    // history login
    history_company_login: "",
    history_uuid_login: "",
    pickerMax: thisYearPicker(),
    pickerMin: "1990",

    userAlert: 0,

    //signPage
    /**
     * 가입자 종류
     * 0: 미선택
     * 1: 관리자
     * 2: 일반
     */
    signType: 0,

    // ListData
    orderList: [],
    company: {},
    // route alert
    route_alert: {
      order: true,
      production: true,
      purchasecalculate: true,
      purchasedeadline: true,
      salescalculate: true,
      salesdeadline: true,
      claim: true,
      vat: true,
    },
    //오류메세지 처리
    snackbar_text: "",
    snackbar: false,
    // Loading Status
    isAPILoading: false,
    //DailogStatus
    isDialog: false,
    isPrintDialog: false,
    //DailogKind
    isKind: "",
    //DialogTitle
    dialogTitle: "",
    //Dailog Type
    dialogType: "",
    //DialogForm
    editedItem: { type_postion: "" },
    //mainPageTotalstaticsTable
    mini: false,
    //Forms
    orderForm: {
      client: "",
      item: "",
      color_list: [],
      date_register: dateFormat(new Date(Date.now())),
      order_front_year: new Date(Date.now()).getFullYear() + "",
      order_front_season: "",
      unit_sign: "y",
      eschamge_sign: "₩",
      estimate_price_sign: "₩",
      sales_unit_price: "",
      memo_request: "",
      memo_confirm: "",
      sampleStatus: "",
      RepeatStatus: "",
      style_list: [],
    },
    form: { btnDisabled: false },
    saveKind: "",
    // 생산페이지 state ///////////////////////////////////////
    form_reset_check: false,
    // approval: {},
    approvalDialog: false,
    approvalMemo: "",
    // 생산페이지 작성 데이터
    productionData: {},
    // 생산페이지 수정전 데이터
    beforProductionData: {},
    // 생산페이지 문서 dialog boolean
    productionDialog: false,

    // 생산페이지 문서 dislog 저장 확인 dialog boolean
    confirmDialog: false,

    // 생산페이지 bak 데이터
    bakData: { color: [], style: [] },
    // 생산페이지 bak 데이터 dialog boolean
    bakDataDialog: false,

    //생산페이지 form 가공의뢰서 list
    manufactureList: [],
    //생산페이지 form 출고의뢰서 list
    // releaseList: [],

    // 생산페이지 colorList 수량 total
    colorTotal: 0,

    relApprovalList: [],
    // releaseFileList: [],
    attachmentList: [],
    attachmentId: "",

    // logo
    logoImg: {},

    /////////////////////////////////////////////////////////

    // 정산페이지 state ///////////////////////////////////////
    //release data
    changeData: {},
    changeDialog: false,
    /////////////////////////////////////////////////////////

    itemList: [],
    clientList: [],
    useClientList: [],
    useItemList: [],
    userList: [],
    ctypeList: [],
    useCtypeList: [],
    approvalList: [],
    // requestReleaseList: [],
    defaultReleaseList: [],

    //checkBoxStatus
    selected: [],

    //Claim 페이지
    claimNewRows: {
      arise_month: dayjs().format("YYYY-MM"),
      arise_currency_sign: "₩",
      arise_passtype: 1,
      arise_detail: "협의중|",
      arise_vat: true,
      pass_vat: true,
      pass_month: dayjs().format("YYYY-MM"),
      pass_currency_sign: "₩",
      pass_detail: "",
    },

    claimEdit: false,
    claimMemo: "",
    claimView: "claim",
    excelUploadType: "",
    claimForm: {
      currency_sign: "₩",
      detail: "",
    },
    claimModifyForm: {},
    ariseSelected: [],
    passSelected: [],
    claimReleaseForm: {},
    otherClaimReleaseForm: {},
    ariseCheckBox: false,
    passCheckBox: false,
    endAriseCheckBox: false,
    endPassCheckBox: false,
    //수정시 오더
    beforeOrder: {},
    afterOrder: {},
    // isClaimNewTypeDialog: false,
    selectClaim: {},
    selectClaimList: [],
    //외상 매입, 매출 페이지
    receivableKind,
    approvalForm: { memo: "" },
    saveApproval: {},
    saveData: {},
    //생산 오더 폼
    productionOrderForm: {},
    // 생산 가공 폼
    manufactureForm: { dialog: false, order: "" },
    // 생산 출고 폼
    releaseForm: {
      dialog: false,
      order: "",
      salesBtn: false,
      appenBtn: false,
      requestDoc: { kind: 8 },
    },
    // 생산페이지 color list
    productionColor: [],
    // 화면 color List
    commaColor: [],

    // 생산페이지 저장 데이터
    saveReleaseColor: {},
    // 외관 검사서 폼
    appearanceForm: { dialog: false, order: "", color_list: [] },

    productionProgress: {},

    productionDocList: [],
    // 취소 dialog
    cancelDialog: { dialog: false, status: "", kind: "" },
    //releaseColorList
    releaseColorList: { style: [], color: [] },

    requsestAppearance: [],
    selectOrder: {},
    //approvalDialog
    userRequesDialog: false,
    requestDialog: false,
    requestFormDialog: false,
    requestForm: {},
    requestList: [],
    requestApprovalList: [],
    requestApprovalOriginalList: [],
    requestApprovalFilterList: [],
    requestApprovaloriginFilter: [],
    requestSetList: { name: "order", list: [] },

    saveRecivable: {},

    //print page
    manufacturePrient: { dialog: false },
    releasePrient: { dialog: false },
    prientColor: [],

    //print page
    printForm: {},
    printType: "",

    //임시 필터링용

    numDialogData: {},
    //상단 totalData용
    totalData: {},

    useStockClientList: [],
    newOrderNo: {
      order: "",
      order_back: "",
    },
    formDateOrder: 0,
    //excelList
    excelOrderList: [],
    excelMainOrderList: [],
    excelPurchaseList: [],
    excelSalesList: [],
    excelClaimList: [],
    excelVatList: [],
    excelFormList: [],
    itemExcelFormList: [],
    ctypeExcelFormList: [],

    //emailForm
    emailForm: {},
    emailInput: {
      reciver: "",
      title: "",
      content: "",
      cabonCopy: "",
    },
    emailFile: {},
    //numfilter
    numFilterList: [],
    numFilteringList: [],
    //생산페이지 출고의뢰서 계산 data
    purchasCacul: {}, // 매입 계산
    salesCacul: {}, // 매출 정산
    // windowSize
    window: {
      width: 0,
      height: 0,
    },
    // 가공/출고 의뢰서 문서 열람 기능
    docData: {},
    selectDocData: { kind: 0 },
    isDocDataBtn: false,
    docDataList: [],
    contextData: {},
    contextTargetList: [],
    targetScroll: { target: "", status: false },
    loginForm: "login",
    //필터링용 헤더
    mainHeader,
    orderHeader,
    productionHeader,
    purCacheader,
    purDedheader,
    salesCacheader,
    saelsDedheader,
    receiveHeader,
    saelsreceiveHeader,
    claimHeader,
    endClaimHeader,
    stockHeader,
    //셋팅페이지
    navSelected: { title: "회사정보", value: "common" },
    editCompany: {},
    viewTel: "",
    viewBusinessNum: "",
    editRowData: {
      status: false,
      target: "",
      index: [null, null],
      value: "",
      value2: "",
      type: "",
    },
    bakDocListDialog: false,
    saveRecivableAmount: {},
    monthList: [
      { label: "1월", value: "01" },
      { label: "2월", value: "02" },
      { label: "3월", value: "03" },
      { label: "4월", value: "04" },
      { label: "5월", value: "05" },
      { label: "6월", value: "06" },
      { label: "7월", value: "07" },
      { label: "8월", value: "08" },
      { label: "9월", value: "09" },
      { label: "10월", value: "10" },
      { label: "11월", value: "11" },
      { label: "12월", value: "12" },
    ],

    // 저장할 재고
    saveStock: [],

    //검색된 재고
    selectStock: [],

    //삭제할 재고
    deleteStock: [],

    // 재고 item list
    stockList: [],

    //재고 color List
    stockColorList: [],

    //progress dialog
    isProgressDialog: false,

    // progress Data
    progressData: {},
    prgoressFileList: [],

    //preset dalog
    presetList: [],

    isSalesSurcharge: false,
    // pageIndex
    pageIndex: 1,

    // 재고페이지 신규 재고
    newStock: {
      unit_sign: "y",
      date: dateFormat(new Date()),
      currency_sign: "₩",
    },
    editStockForm: {},
    stockCheckbox: false,
    isStockDialog: false,

    stockKind: [
      {
        name: "생산",
        kind: 1,
      },
      {
        name: "선매입",
        kind: 2,
      },
      {
        name: "원단",
        kind: 3,
      },
      {
        name: "제직",
        kind: 4,
      },
    ],
    stockView: "fabric_stock",
    stockViewIndex: 0,
    secondSaveData: {},
    usedStockList: [],
    outputSetStockList: [],
    inputSetStockList: [],

    // isFabricRelease: false,
    // fabricRelease: {},
    // isFabricManufacture: false,
    // fabricManufacture: {},
    // docReferanceGroup: [],
    fabricCaculateDialog: false,
    // 알림창 기능
    requestDocGropType: "order",
    applyFilterList: {},
    pageTotalSize: 0,
    originPageTotalSize: 0,
    initScroll: "",
    applySortList: [],

    // 필터 params
    orderPageList: [],
    orderParams: [],
    orderTotalCount: 0, // 오더 검색 total count
    orderSort: 1, // 오더 페이지 sort

    productionOrderList: [],
    productionParams: [],
    productionTotalCount: 0, //생산페이지 검색 total count
    productionSort: 1, // 생산 페이지 sort

    calculates: [], // 매입 정산 리시트
    calculateEnds: [], // 매입 마감 리스트
    salesCalculates: [], // 매출 정산 리스트
    salesCalculateEnds: [], // 매출 마감 리스트
    purchaseCaculParam: [], //매입 정산 검색 param
    purchaseEndParam: [], // 매입 마감 검색 param
    salesCaculParam: [], // 매출 정산 검색 param
    salesEndParam: [], // 매출 마감 검색 param
    calculateTotal: 0, // 정산, 마감 total
    calculatesSort: 1, // 매입 정산 페이지 sort
    calculateEndsSort: 1, // 매입 마감 페이지 sort
    salesCalculatesSort: 1, // 매출 정산 페이지 sort
    salesCalculateEndSort: 1, // 매출 마감 페이지 sort

    purchCheckBox: false, // 매입 정산 check box
    endPurchCheckBox: false, // 매입 마감 check box
    salesCheckBox: false, // 매출 정산 check box
    endSalesCheckBox: false, // 매출 마감 check box

    claimList: [], // 클레임 리스트
    endClaimList: [], // end 클레임 리스트
    claimParam: [], // 클레임 검색 param
    endClaimParam: [], //클레임 검색 param
    claimTotal: 0,
    claimSort: 1, // 클레임 페이지 sort
    endClaimSort: 1, // 클레임 마감 페이지 sort
    ariseAllChecked: false, // 클레임 페이지 발생 checkbox
    passAllChecked: false, // 클레임 페이지 전가 checkbox
    endAriseAllChecked: false, // 클레임 마감 페이지 발생 checkbox
    endPassAllChecked: false, // 클레임 마감 페이지 전가 checkbox

    mainOrderList: [], // 메인 리스트
    mainParam: [], // 메인 param
    mainTotal: 0, // 메인 total
    mainSort: 1, // 메인 페이지 sort
    mainChartData: [], // 메인페이지 차트데이터
    vatList: [], //매입 외상금 리스트
    vatSalesList: [], //매출 외상금 리스트
    purchaseVatParam: [], // 매입 외상금 검색 param
    salesVatParam: [], // 매출 외상금 검색 param
    vatTotal: 0,
    vatSelected: "common", // 외상금 구분
    vatSalesSelected: "common",
    vatSort: 1, // 외상 매입금 페이지 sort
    vatSalesSort: 1, // 외상 매출금 페이지 sort

    stockTableList: [], //재고 리스트
    stockParam: [], //재고 검색 param
    stockTotal: 0,
    stockSort: 1, //재고 매출금 페이지 sort
    stockSelected: "stock",

    checkBoxLength: 0,

    stockTableFilterList: [],
    isPreviewDialog: false,
    previewImage: "",

    apprvoalHistory: [], //approval 수정이력
    requestType: "", //요청목록 타입
    isExpired: false,
    isPayment: false, //입금 가능한상태 체크
    trialAlramDialog: false,
    trialClose: false,
    paymentList: [], //결제관리 결제내역 리스트
    adminPaymentList: [], // 백오피스 결제관리 리스트
    adminConfirmDialog: false,
    payData: {},
    membershipInfo: {},
    userInfo: {}, // 현재계정의 유저정보
    //엑셀 업로드 폼리스트
    excelUploadForm: [],
    companySetting: {}, //회사 세팅정보 ,

    // admin page
    // admin page total filter field params
    filterFieldParams: [],
    adminTotalCompanys: [],

    // 생지 form 데이터
    fabricForm: { dialog: true },
    // 축률 적용 가공의뢰서 form
    shrinkageForm: { dialog: false },
  },

  getters,
  mutations,
  actions,
});

function getYearList(start) {
  return new Array(new Date(Date.now()).getFullYear() - start)
    .fill(0)
    .map((_, i) => {
      return { value: start + i + 3 + "", label: start + i + 3 + "년" };
    })
    .reverse();
}
function getSelectYearList(start) {
  const YearList = new Array(new Date(Date.now()).getFullYear() - start)
    .fill(0)
    .map((_, i) => {
      return { value: start + i + 2 + "", label: start + i + 2 + "년" };
    })
    .reverse();
  YearList.unshift({ value: "전체", label: "전체" });
  return YearList;
}

function thisYearPicker() {
  const year = new Date(Date.now()).getFullYear() + 1;
  return year + "/12";
}
function currentDateTime() {
  const current = new Date();
  const month = 1 + current.getMonth();
  const day = current.getDate();
  const date = `${current.getFullYear()}-${month >= 10 ? month : "0" + month}-${
    day >= 10 ? day : "0" + day
  }`;
  return date;
}
function dateFormat(date) {
  if (!date) {
    date = this.form.date_register;
  }
  const temp = new Date(date);
  const year = temp.getFullYear();
  const month = 1 + temp.getMonth();
  const day = temp.getDate();
  return `${year}-${month >= 10 ? month : "0" + month}-${
    day >= 10 ? day : "0" + day
  }`;
}
