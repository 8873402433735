<template>
  <div class="tabs">
    <!-- Tab Buttons -->
    <div class="tab-buttons">
      <button
        v-for="(tab, index) in tabs"
        :key="index"
        class="tab-button"
        :class="{ active: activeTab === index }"
        @click="selectTab(index, tab)"
        :style="{ width: width + 'px' }"
      >
        {{ tab.label }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },

    commitEvent: {
      type: String,
      require: true,
    },
    activeIndex: {
      type: Number,
      require: true,
    },
    activeCommitEvent: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      activeTab: 0, // 현재 활성화된 탭 인덱스
    };
  },

  methods: {
    selectTab(index, tab) {
      this.activeTab = index;

      this.$store.commit(this.activeCommitEvent, index);
      this.$store.commit(this.commitEvent, tab.value);
    },
  },
  created() {
    this.activeTab = this.activeIndex;
  },
};
</script>

<style scoped>
/* Tabs Wrapper */
.tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* Tab Buttons */
.tab-buttons {
  display: flex;
  gap: 5px; /* 버튼 사이 간격 */
}

.tab-button {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
  color: #555;
  background-color: #f1f1f1;
  border: none;
  /* border-radius: ; */
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.tab-button:hover {
  background-color: #e0e0e0;
}

.tab-button.active {
  background-color: #007bff;
  color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Tab Content */
.tab-content {
  padding: 16px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
</style>
