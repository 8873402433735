<template>
  <div class="settingFormLayout border-bottom">
    <div class="label">회사 구분*</div>
    <div class="input">
      <span
        class="labelText mr-1"
        :class="$store.state.editedItem._id ? 'disable' : ''"
      >
        좌
      </span>
      <input
        v-model="editedItem.type_postion"
        :disabled="!editedItem.company_type"
        value="left"
        type="radio"
        class="v-checkbox mr-1"
      />
      <span
        class="labelText mr-1"
        :class="$store.state.editedItem._id ? 'disable' : ''"
      >
        우
      </span>
      <input
        :disabled="!editedItem.company_type"
        v-model="editedItem.type_postion"
        value="right"
        type="radio"
        class="v-checkbox mr-1"
      />
      <customSelectField
        v-model="editedItem.company_type"
        :items="$store.state.client_type"
        :height="'30px'"
        :clearable="true"
      />
    </div>
    <div class="label">회사명*</div>

    <div class="input">
      <customTextField
        :clearable="true"
        :height="'30px'"
        v-model="editedItem.name_only"
      >
      </customTextField>
    </div>
    <div class="label">지점</div>
    <div>
      <div class="input">
        <customTextField
          v-model="editedItem.zone"
          :clearable="true"
          :height="'30px'"
        />
      </div>
    </div>
    <div class="label">회사명 (보기)</div>
    <div>
      <div class="input">
        <customTextField :value="fullName" :height="'30px'" :disabled="true" />
      </div>
    </div>
    <div class="label">계산서 발행처</div>
    <div>
      <div class="input">
        <customAutoComplete
          v-model="editedItem.bill_client"
          :items="$store.state.useClientList"
          :itemText="'name'"
          :itemValue="'_id'"
          :height="'30px'"
          :noResultsMessage="'검색결과가 없습니다.'"
          :change="billChange"
        />
      </div>
    </div>
    <div class="label">대표명</div>
    <div>
      <div class="input">
        <customTextField
          v-model="editedItem.officer"
          :clearable="true"
          :height="'30px'"
        />
      </div>
    </div>
    <div class="label">사업자번호</div>
    <div>
      <div class="input">
        <customTextField
          v-model="editedItem.business_num"
          :clearable="true"
          :height="'30px'"
          @keyup="businessNumParse"
          :maxlength="12"
        />
      </div>
    </div>
    <div class="label">업종</div>
    <div>
      <div class="input">
        <customTextField
          v-model="editedItem.industry"
          :clearable="true"
          :height="'30px'"
        />
      </div>
    </div>
    <div class="label">전화번호</div>
    <div>
      <div class="input">
        <customTextField
          v-model="editedItem.tel"
          :clearable="true"
          :height="'30px'"
          @keyup="telNumParse"
          :maxlength="13"
        />
      </div>
    </div>
    <div class="label">팩스번호</div>
    <div>
      <div class="input">
        <customTextField
          v-model="editedItem.fax"
          :clearable="true"
          :height="'30px'"
          @keyup="faxNumParse"
          :maxlength="13"
        />
      </div>
    </div>
    <div class="label">이메일</div>
    <div>
      <div class="input">
        <customTextField
          v-model="editedItem.email"
          :clearable="true"
          :height="'30px'"
        >
        </customTextField>
      </div>
    </div>
    <div class="label">연락 담당자</div>
    <div>
      <div class="input">
        <customTextField
          v-model="editedItem.liaison"
          :clearable="true"
          :height="'30px'"
        />
      </div>
    </div>
    <div class="label">주소</div>
    <div class="mergeColumn">
      <div class="input">
        <customTextField
          v-model="editedItem.address"
          :clearable="true"
          :height="'30px'"
        >
        </customTextField>
      </div>
    </div>
  </div>
</template>
<script>
import ClienTypeAcronyms from "@/assets/data/kind/ClienTypeAcronyms";
import customTextField from "@/components/atoms/TextField/customTextField";
import customSelectField from "@/components/atoms/Select/customSelectField";
import customAutoComplete from "@/components/atoms/Autocomplete/customAutoComplete";
import { mapGetters } from "vuex";

export default {
  components: {
    // LabelSelect,
    customTextField,
    customSelectField,
    customAutoComplete,
  },
  computed: {
    fullName() {
      const name = `${
        this.editedItem.type_postion === "left" && this.editedItem.company_type
          ? ClienTypeAcronyms[this.editedItem.company_type - 1].label
          : ""
      }${this.editedItem.name_only ? this.editedItem.name_only : ""}${
        this.editedItem.type_postion === "right" && this.editedItem.company_type
          ? ClienTypeAcronyms[this.editedItem.company_type - 1].label
          : ""
      }${
        this.editedItem.name_only && this.editedItem.zone
          ? `[${this.editedItem.zone}]`
          : ""
      }`;

      return name;
    },
    ...mapGetters({
      editedItem: "getEditedItem",
    }),
  },
  data() {
    return {
      ClienTypeAcronyms,
      viewTel: "",
      viewBusinessNum: "",
      type_postion: "",
    };
  },
  methods: {
    businessNumParse(event) {
      event.target.value = this.getBusinessNumMask(event.target.value);
      this.editedItem.business_num = event.target.value;
    },
    telNumParse(event) {
      event.target.value = this.getPhoneMask(event.target.value);
      this.editedItem.tel = event.target.value;
    },
    faxNumParse(event) {
      event.target.value = this.getPhoneMask(event.target.value);
      this.editedItem.fax = event.target.value;
    },
    radioCheckChange(event) {
      for (let i = 0; i < this.type_postion.length; i++) {
        if (this.type_postion[i] !== event.target.value) {
          this.type_postion.splice(i, 1);
        }
      }
      if (event.target.checked)
        this.editedItem.type_postion = event.target.value;
    },
    billChange() {
      if (
        this.editedItem.bill_client &&
        this.editedItem.bill_client.business_num
      ) {
        this.editedItem.business_num = this.editedItem.bill_client.business_num
          ? this.getBusinessNumMask(this.editedItem.bill_client.business_num)
          : null;
      }
    },
  },
};
</script>
