<template>
  <div
    class="custom-text-field"
    :style="{ fontSize: fontSize + 'px', width: width, height: height }"
  >
    <div
      class="field-container"
      :class="{ focused: isFocused, error: !!errorMessage }"
    >
      <span v-if="prefix" class="prefix">{{ prefix }}</span>
      <slot name="prefix"></slot>
      <div class="input-wrapper">
        <label v-if="label" :class="{ hidden: isFocused || localValue }">{{
          label
        }}</label>
        <input
          :type="type"
          v-model="localValue"
          @focus="handleFocus"
          @blur="handleBlur"
          @input="handleInput"
          :disabled="disabled"
          @change="change"
          :maxlength="maxlength"
          @keyup="$emit('keyup', $event)"
        />
        <button
          v-if="clearable && localValue && !disabled"
          class="clear-button"
          @click="clearValue"
        >
          &times;
        </button>
      </div>
      <span v-if="suffix" class="suffix">{{ suffix }}</span>
      <slot name="suffix"></slot>
    </div>
    <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
  </div>
</template>

<script>
import { readonly } from "vue";

export default {
  name: "CustomTextField",
  props: {
    label: String,
    value: [String, Number],
    type: {
      type: String,
      default: "text",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    prefix: String,
    suffix: String,
    errorMessage: String,
    fontSize: {
      type: Number,
      default: 14,
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "auto",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    change: {
      type: Function,
      default: () => {},
    },
    maxlength: {
      type: Number,
      default: null,
    },
    numberFormat: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFocused: false,
      localValue: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.localValue = newValue;
    },
    localValue(newValue) {
      this.$emit("input", newValue);
    },
  },
  methods: {
    handleFocus() {
      this.isFocused = true;
      this.$emit("focus");
    },
    handleBlur() {
      this.isFocused = false;
      this.$emit("blur");
      this.resetScrollPosition();
    },
    handleInput(event) {
      let value = event.target.value;
      if (this.numberFormat && this.type === "text") {
        value = value.replace(/[^0-9]/g, ""); // 숫자가 아닌 문자는 제거
        value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ","); // 콤마 추가
      }
      this.$emit("input", value);
    },
    clearValue() {
      this.localValue = "";
      this.$emit("clear");
    },
    resetScrollPosition() {
      const inputElement = this.$el.querySelector("input");
      if (inputElement) {
        inputElement.setSelectionRange(0, 0); // 커서를 텍스트의 시작으로 이동
      }
    },
  },
};
</script>

<style scoped>
.custom-text-field {
  display: flex;
  flex-direction: column;
}

.field-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0px;
  position: relative;
  transition: border-color 0.3s;
  box-sizing: border-box;
  overflow: visible;
}

.field-container.focused {
  border-color: #007bff;
}

.field-container.error {
  border-color: #dc3545;
}

.prefix,
.suffix {
  color: #6c757d;
  margin: 0 4px;
}

.input-wrapper {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
}

label {
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  color: #6c757d;
  font-size: inherit;
  pointer-events: none;
  transition: all 0.3s;
}

label.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.3s, opacity 0.3s;
}

input {
  flex: 1;
  border: none;
  outline: none;
  font-size: inherit;
  padding: 4px 5px 4px 4px;
  box-sizing: border-box;
  /* text-overflow: ellipsis; */
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100% - 18px);
}

.clear-button {
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #6c757d;
  cursor: pointer;
  font-size: inherit;
  padding-right: 8px;
  line-height: 1;
}

.clear-button:hover {
  color: #dc3545;
}

.error-message {
  color: #dc3545;
  font-size: 12px;
  margin-top: 4px;
}
slot[name="prefix"],
slot[name="suffix"] {
  position: relative;
  z-index: 1000;
  pointer-events: auto;
}
</style>
