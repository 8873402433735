export default [
  {
    text: "",
    width: "2%",
    align: "center",
    class: "tableHeaderBody",
    value: "check",
    filterable: false,
  },
  {
    text: "날짜",
    width: "2%",
    align: "center",
    class: "tableHeaderBody",
    value: "date",
    type: "date",
    dateType: "yyyymm",
    sort: -1,
  },
  {
    text: "구분",
    width: "5%",
    value: "kind",
    align: "center",
    class: "tableHeaderBody",
  },
  {
    text: "종류",
    value: "type",
    width: "4%",
    align: "center",
    class: "tableHeaderBody",
  },
  {
    text: "오더",
    width: "5%",
    value: "order",
    align: "center",
    class: "tableHeaderBody",
    sort: -1,
  },
  {
    text: "생지명",
    width: "5%",
    value: "stock_name",
    align: "center",
    class: "tableHeaderBody",
    sort: -1,
  },
  {
    text: "아이템",
    value: "item",
    width: "5%",
    align: "center",
    class: "tableHeaderBody",
  },
  {
    text: "생산처",
    value: "client",
    width: "4%",
    align: "center",
    class: "tableHeaderBody",
  },

  {
    text: "재고 수량",
    width: "8%",
    align: "center",
    class: "tableHeaderBody",
    value: "stock_quantity",
    type: "number",
    groupable: true,
  },
  {
    text: "잔여 수량",
    width: "8%",
    align: "center",
    class: "tableHeaderBody",
    value: "remain_quantity",
    type: "number",
    filterable: true,
  },
  {
    text: "사용 수량",
    width: "8%",
    align: "center",
    class: "tableHeaderBody",
    value: "use_quantity",
    type: "number",
    filterable: true,
  },
  {
    text: "단가",
    width: "8%",
    align: "center",
    class: "tableHeaderBody",
    value: "unit_price",
    type: "number",
    filterable: true,
  },
  {
    text: "위치",
    width: "8%",
    align: "center",
    class: "tableHeaderBody",
    value: "place",
  },
  {
    text: "상태",
    width: "8%",
    align: "center",
    class: "tableHeaderBody",
    value: "status",
  },
  {
    text: "",
    width: "8%",
    align: "center",
    class: "tableHeaderBody",
    value: "edit",
    filterable: false,
  },
];
