import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import _ from "lodash";

import roundFormat from "../src/utils/constants/rounds";
import VueSocketIO from "vue-socket.io";
import SocketIO from "socket.io-client";
import { OverlayScrollbarsPlugin } from "overlayscrollbars-vue";
import { OverlayScrollbarsComponent } from "overlayscrollbars-vue";
import VueApexCharts from "vue-apexcharts";
import "@/components/pages/common/scss/global.scss";

Vue.config.productionTip = false;
Vue.prototype.$socket = VueSocketIO;
Vue.prototype.$roundFormat = roundFormat;
Vue.prototype._ = _;

Vue.use(OverlayScrollbarsPlugin);
Vue.use(VueApexCharts);
Vue.component("overlay-scrollbars", OverlayScrollbarsComponent);
Vue.component("apexchart", VueApexCharts);
Vue.filter("makeComma", (val) => {
  if (!val) return val;
  return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
});
export const eventBus = new Vue();
import "overlayscrollbars/css/OverlayScrollbars.css";
// Global Metohds ***추후 전역 메소드 많아지면 이동  ***
Vue.mixin({
  computed: {},

  methods: {
    async saveApiAction(setApi) {
      try {
        const result = await setApi.action(setApi.saveData);

        if (result.status !== 200) throw `error : ${result.status}`;

        return result.data;
      } catch (e) {
        console.log(e);
      }
    },

    // 전달 받은 수량, 단위, 변환 값 으로 무게 또는 수량을 계산 하여 반환 하는 함수
    weightCacul(value = 0, unitSign, convert = 0, weightUnitSign) {
      let caculValue = 0;

      if (unitSign === "y" || unitSign === "m") {
        caculValue = value * convert;
        if (weightUnitSign === "g") {
          caculValue = caculValue / 1000;
        }
      }

      if (unitSign === "kg") {
        caculValue =
          weightUnitSign === "g" ? (value * 1000) / convert : value / convert;
      }

      return isFinite(caculValue) ? this.roundsData(caculValue) : 0;
    },

    numFormat(num) {
      return 10 > num ? `00${num}` : 100 > num ? `0${num}` : `${num}`;
    },

    productionIdMake(order, kind, num) {
      return `${order}_${kind}_${this.numFormat(num + 1)}`;
    },

    //축률 계산
    shrigkageCacul(quantity, shrigkage) {
      const quantity_shrigkage = +quantity + (+quantity * +shrigkage) / 100;
      return this.roundsData(quantity_shrigkage);
    },

    processingQuantity(quantity_shrigkage, shrigkage) {
      const quantity = quantity_shrigkage / (shrigkage / 100 + 1);
      return this.roundsData(quantity);
    },

    // 의뢰서 체크
    requestDocCheck(kind) {
      return kind === 7 ||
        kind === 8 ||
        kind === 9 ||
        kind === 10 ||
        kind === 11 ||
        kind === 12 ||
        kind === 15 ||
        kind === 16 ||
        kind === 17 ||
        kind === 18 ||
        kind === 19
        ? true
        : false;
    },

    usingComma(str) {
      if (!str) return "";
      return (str = this.comma(this.uncomma(str)));
    },

    parsingNum(str) {
      const num = +this.uncomma(str);
      return isNaN(num) ? "" : num;
    },

    comma(str) {
      str = "" + str;

      var regx = new RegExp(/(-?\d+)(\d{3})/);
      var bExists = str.indexOf(".", 0); //0번째부터 .을 찾는다.
      var strArr = str.split(".");
      while (regx.test(strArr[0])) {
        //문자열에 정규식 특수문자가 포함되어 있는지 체크
        //정수 부분에만 콤마 달기
        strArr[0] = strArr[0].replace(regx, "$1,$2"); //콤마추가하기
      }
      if (bExists > -1) {
        //. 소수점 문자열이 발견되지 않을 경우 -1 반환
        str = strArr[0] + "." + strArr[1];
      } else {
        //정수만 있을경우 //소수점 문자열 존재하면 양수 반환
        str = strArr[0];
      }
      return str;
    },

    uncomma(str) {
      str = "" + str;
      str = str.replace(/,/gi, ""); // 콤마 제거
      str = str.replace(/(^\s*)|(\s*$)/g, ""); // trim()공백,문자열 제거

      return str;
    },
    getPhoneMask(val) {
      return (val = this.getMask("" + val));
    },
    getMask(value) {
      if (!value) {
        return "";
      }

      value = value.replace(/[^0-9]/g, "");

      let result = [];
      let restNumber = "";

      // 지역번호와 나머지 번호로 나누기
      if (value.startsWith("02")) {
        // 서울 02 지역번호
        result.push(value.substr(0, 2));
        restNumber = value.substring(2);
      } else if (value.startsWith("1")) {
        // 지역 번호가 없는 경우
        // 1xxx-yyyy
        restNumber = value;
      } else {
        // 나머지 3자리 지역번호
        // 0xx-yyyy-zzzz
        result.push(value.substr(0, 3));
        restNumber = value.substring(3);
      }

      if (restNumber.length === 7) {
        // 7자리만 남았을 때는 xxx-yyyy
        result.push(restNumber.substring(0, 3));
        result.push(restNumber.substring(3));
      } else {
        result.push(restNumber.substring(0, 4));
        result.push(restNumber.substring(4));
      }

      return result.filter((val) => val).join("-");
    },
    getBusinessNumMask(val) {
      val = this.addAutoHyphen("" + val);

      return val;
    },
    addAutoHyphen(str) {
      str = str.replace(/[^0-9]/g, "");
      return str.length < 4
        ? str
        : str.length < 6
        ? `${str.substr(0, 3)}-${str.substr(3)}`
        : str.length < 11
        ? `${str.substr(0, 3)}-${str.substr(3, 2)}-${str.substr(5)}`
        : str;
    },
    monthDateFormat(date) {
      const temp = new Date(date);
      const month = 1 + temp.getMonth();
      return month;
    },
    yearDateFormat(date) {
      const temp = new Date(date);
      const month = temp.getFullYear();
      return month;
    },
    yearMonthFormat(date) {
      if (!date) {
        date = this.form.date_register;
      }
      const temp = new Date(date);
      const year = temp.getFullYear();
      const month = temp.getMonth() + 1;

      return `${year}-${month >= 10 ? month : "0" + month}`;
    },
    dateFormat(date) {
      if (!date) {
        date = this.form.date_register;
      }
      const temp = new Date(date);
      const year = temp.getFullYear();
      const month = 1 + temp.getMonth();
      const day = temp.getDate();
      return `${year}-${month >= 10 ? month : "0" + month}-${
        day >= 10 ? day : "0" + day
      }`;
    },
    dateTimeFormat(date) {
      const temp = new Date(date);

      const year = temp.getFullYear();
      const month = 1 + temp.getMonth();
      const day = temp.getDate();
      let hour = temp.getHours();
      const week = ["일", "월", "화", "수", "목", "금", "토"];

      hour = hour < 10 ? "0" + hour.toString() : hour.toString();

      let minites = temp.getMinutes();
      minites = minites < 10 ? "0" + minites.toString() : minites.toString();
      return `${year}-${month >= 10 ? month : "0" + month}-${
        day >= 10 ? day : "0" + day
      } (${week[temp.getDay()]}) \n ${hour}:${minites}`;
    },
    // 소수인지 판별 하는 함수
    isPrime(n) {
      for (let i = 2; i <= Math.sqrt(n); i++) {
        if (n % i === 0) {
          return false;
        }
      }
      return true;
    },

    //소수점 셋째자리에서 반올림
    roundsData(num) {
      return num ? parseFloat(+num.toFixed(2)) : 0;
    },

    copyData(data) {
      return data ? JSON.parse(JSON.stringify(data)) : "";
    },

    // m/y 변경에 따라 수량 변호나
    unitChange(unit, data) {
      return ((data ? data : 0) * (unit === "y" ? 1.094 : 0.914)).toFixed(0);
    },

    // 필터링 sort
    sortHeader(source, header) {
      // date 예외처리 header 이름
      const dateHeaders = [
        "mainDay",
        "day",
        "month",
        "sale_month",
        "pur_month",
        "date",
        "year",
        "salesMonth",
        "purchaseMonth",
      ];

      // sort 규칙
      const getLevel = (s) => {
        const index = [
          /[(비어있음)]/,
          /fw/,
          /ss/,
          /\(([^)]+)\)/,
          /[0-9]/,
          /[a-zA-Z]/,
          /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/,
        ].findIndex((pattern) => pattern.test(s));
        return index;
      };

      source.sort((a, b) => {
        //order sort
        if (header === "order") return b.name.localeCompare(a.name);
        if (header !== "order") {
          if (a.name === "(비어있음)") return -1;
          if (b.name === "(비어있음)") return 1;
        }
        // date sort
        if (dateHeaders.includes(header)) {
          const [aMonth, aDay] = a.name ? a.name.split("-") : "";
          const [bMonth, bDay] = b.name ? b.name.split("-") : "";
          const aParseDate = new Date(2023, aMonth - 1, aDay);
          const bParseDate = new Date(2023, bMonth - 1, bDay);
          return bParseDate && aParseDate
            ? bParseDate - aParseDate
            : b.name.localeCompare(a.name);
        }
        // 문자열 sort
        if (typeof a.name === "string" && typeof b.name === "string") {
          const aLevel = getLevel(a.name.charAt(0));
          const bLevel = getLevel(b.name.charAt(0));
          if (header !== "season" && aLevel === bLevel) {
            if (aLevel === 1) {
              // 괄호 안에 있는 문자열 가나다 순으로 정렬
              const aValue = a.name.match(/\(([^\)]+)\)/)[1]; //eslint-disable-line
              const bValue = b.name.match(/\(([^\)]+)\)/)[1]; //eslint-disable-line
              return aValue.localeCompare(bValue, "ko-KR");
            } else {
              return a.name.localeCompare(b.name);
            }
          } else return aLevel - bLevel;
          // 오름 차순 정렬
        }
        // 숫자형 sort
        // if(header ==='season')
        return a.name - b.name;
      });

      if (header === "arise_month" || header === "pass_month") source.reverse();
      return source;
    },
    //임시
    claimSortHeader(source, header) {
      // date 예외처리 header 이름
      const dateHeaders = [
        "day",
        "arise_month",
        "pass_month",
        "month",
        "sale_month",
        "pur_month",
        "date",
        "year",
      ];
      // sort 규칙
      const getLevel = (s) => {
        const index = [
          /[(비어있음)]/,
          /\(([^)]+)\)/,
          /[0-9]/,
          /[a-zA-Z]/,
          /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/,
        ].findIndex((pattern) => pattern.test(s));
        return index;
      };
      source.sort((a, b) => {
        //order sort
        if (header === "order") return b.name.localeCompare(a.name);
        if (a.name === "" || b.name === "") return -1;
        // date sort
        else if (dateHeaders.includes(header)) {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        }
        // 문자열 sort
        else if (typeof a.name === "string" && typeof b.name === "string") {
          const aLevel = getLevel(a.name.charAt(0));
          const bLevel = getLevel(b.name.charAt(0));

          if (aLevel === bLevel) {
            if (aLevel === 1) {
              // 괄호 안에 있는 문자열 가나다 순으로 정렬
              const aValue = a.name.match(/\(([^\)]+)\)/)[1]; //eslint-disable-line
              const bValue = b.name.match(/\(([^\)]+)\)/)[1]; //eslint-disable-line
              return aValue.localeCompare(bValue, "ko-KR");
            } else {
              return a.name.localeCompare(b.name);
            }
          } else return aLevel - bLevel; // 오름 차순 정렬
        }
        // 숫자형 sort
        // return a.name - b.name;
      });

      return source;
    },

    windowResize() {
      const windowSize = {
        width: window.innerWidth,
        height: window.innerHeight,
      };

      this.$store.commit("setWindow", windowSize);
    },

    decimalCacul(num) {
      if (!num) return 0;
      return parseFloat(+num.toFixed(2));
    },

    /////////////////////////////필터링 함수/////////////////////////////
    setIsFilter(name, setName) {
      return !(name && name === setName);
    },

    setFilterSet(filterList, hederIndex, setFilter, data) {
      setFilter = {
        name: data,
        filter: filterList ? this.copyData(filterList) : [],
        hederIndex,
      };

      if (setFilter.filter.length) setFilter.filter.unshift({ checked: true });

      return setFilter;
    },

    mainFirstFilter(firSetFilter, name, filter) {
      firSetFilter.name = name ? name : "";

      firSetFilter.filter = filter ? filter : "";
    },

    mainApplyFilter(setFilter, header, filterList, firSetFilter) {
      setFilter.filter.splice(0, 1);

      filterList[setFilter.name] = setFilter.filter;

      header[setFilter.hederIndex].checked = true;

      if (
        !header.filter((x) => x.checked).length ||
        header[setFilter.hederIndex].first
      ) {
        header[setFilter.hederIndex].first = true;
        this.mainFirstFilter(firSetFilter, setFilter.name, setFilter.filter);
      }
    },

    //필터링
    mainFilterLing(filterList, originalList, setFilter, header, name) {
      let pageList = [];
      filterList[setFilter.name].forEach((z) => {
        originalList.forEach((y) => {
          if (y[setFilter.name] === z.name && z.checked) {
            pageList.push(y);
          }
        });
      });

      if (
        header.filter((x) => x.checked).length !== 1 &&
        name !== setFilter.name
      ) {
        header.forEach((x) => {
          const list = [];
          const findList = this.copyData(pageList);

          if (filterList[x.value] && x.checked && x.value !== setFilter.name) {
            filterList[x.value].forEach((z) => {
              if (z.checked) {
                findList.forEach((y) => {
                  if (y[x.value] === z.name) {
                    list.push(y);
                  }
                });
              }
            });

            pageList = list;
          }
        });
      }

      return pageList;
    },
    mainPageFilterLing(filterList, originalList, setFilter, header, name) {
      let pageList = [];
      filterList[setFilter.name].forEach((z) => {
        originalList.forEach((y) => {
          if (y[setFilter.name] === z.name && z.checked) {
            pageList.push(y);
          }
        });
      });

      if (
        header.filter((x) => x.checked).length !== 1 &&
        name !== setFilter.name
      ) {
        header.forEach((x) => {
          const list = [];
          const findList = this.copyData(pageList);

          if (filterList[x.value] && x.checked && x.value !== setFilter.name) {
            filterList[x.value].forEach((z) => {
              if (z.checked) {
                findList.forEach((y) => {
                  if (y[x.value] === z.name) {
                    list.push(y);
                  }
                });
              }
            });

            pageList = list;
          }
        });
      }

      return pageList;
    },
    //필터 list set
    mainSetFilterList(
      header,
      originFilter,
      pageList,
      filterList,
      firSetFilter,
      setFilter,
    ) {
      header.forEach((x) => {
        if (firSetFilter === setFilter) {
          if (x.value !== setFilter && filterList[x.value]) {
            const filter = [];
            this.copyData(originFilter[x.value]).forEach((y) => {
              const findData = pageList.find((z) => z[x.value] === y.name);
              if (findData) {
                filter.push(y);
              }
            });
            filterList[x.value] = filter;
          }
        } else {
          if (x.value !== setFilter && !x.checked && filterList[x.value]) {
            const filter = [];
            this.copyData(originFilter[x.value]).forEach((y) => {
              const findData = pageList.find((z) => z[x.value] === y.name);
              if (findData) {
                filter.push(y);
              }
            });
            filterList[x.value] = filter;
          }

          if (x.value !== setFilter && x.checked && filterList[x.value]) {
            filterList[x.value].forEach((y) => {
              y.checked = !!pageList.find((z) => z[x.value] === y.name);
            });
          }
        }
        if (filterList[x.value]) this.sortHeader(filterList[x.value], x.value);
      });
    },
    //필터 list set
    mainNewSetFilterList(
      header,
      originFilter,
      pageList,
      filterList,
      firSetFilter,
      setFilter,
    ) {
      header.forEach((x) => {
        if (firSetFilter === setFilter) {
          if (x.value !== setFilter && filterList[x.value]) {
            const filter = [];
            this.copyData(originFilter[x.value]).forEach((y) => {
              const findData = pageList.find((z) => z[x.value] === y.name);
              if (findData) {
                filter.push(y);
              }
            });
            filterList[x.value] = filter;
          }
        } else {
          if (x.value !== setFilter && !x.checked && filterList[x.value]) {
            const filter = [];

            this.copyData(originFilter[x.value]).forEach((y) => {
              const findData = pageList.find((z) => z[x.value] === y.name);

              if (findData) {
                filter.push(y);
              }
            });

            filterList[x.value] = filter;
          }
          if (x.value !== setFilter && x.checked && filterList[x.value]) {
            filterList[x.value].forEach((y) => {
              y.checked = !!pageList.find((z) => z[x.value] === y.name);
            });
          }
        }
        if (filterList[x.value]) this.sortHeader(filterList[x.value], x.value);
      });
    },

    mainKeyWordFilterSet(findFilterList, setFilter) {
      setFilter.filter = setFilter.filter.map(
        (x) =>
          (x = {
            name: x.name,
            checked: findFilterList
              .filter((x) => x.name && x.checked)
              .map((x) => (x = x.name))
              .includes(x.name),
          }),
      );
    },

    mainEnterFiter(findFilterList, setFilter) {
      setFilter.filter = setFilter.filter.map(
        (x) => (x = { name: x.name, checked: false }),
      );
      findFilterList.forEach((x) => {
        if (x.name) {
          const index = setFilter.filter.findIndex((y) => y.name === x.name);
          setFilter.filter[index].checked = true;
        }
      });
    },

    mainAllCheck(filter, isChecked) {
      filter = filter.map((x) => (x = { name: x.name, checked: isChecked }));
      return filter;
    },

    mainFilterCheck(setFilter, data) {
      data.checked = !data.checked;
      if (!data.checked) setFilter.filter[0].checked = false;
      else if (!setFilter.filter.find((x) => x.name && !x.checked))
        setFilter.filter[0].checked = true;
    },

    mainFindFilter(setFilter, header, findKeyword) {
      let findData = [];
      if (header[setFilter.hederIndex].type === "number") {
        findData = findKeyword
          ? setFilter.filter.filter((x) => x.name === +findKeyword)
          : [];
      } else {
        findData = findKeyword
          ? setFilter.filter.filter((x) => {
              if (x.name) return x.name.match(new RegExp(findKeyword, "i"));
            })
          : [];
      }
      if (findData.length)
        findData.unshift({
          checked: !findData.filter((x) => !x.checked).length,
        });
      return findData;
    },

    mainSortItem(stat, header, pageList) {
      pageList.sort((a, b) => {
        const prevData = a[header];
        const nextData = b[header];
        if (!!prevData && !!nextData) {
          if (stat === "asc") {
            return typeof prevData === "number"
              ? prevData - nextData
              : "" + prevData.localeCompare("" + nextData);
          } else {
            return typeof prevData === "number"
              ? nextData - prevData
              : "" + nextData.localeCompare("" + prevData);
          }
        }
      });
    },

    mainMonthFilter(monthFilterData, setFilter) {
      monthFilterData.forEach((x) => {
        const index = setFilter.filter.findIndex((y) => y.name === x.date);
        setFilter.filter[index].checked = true;
      });
    },

    mainNumFilter(filterRingData, header, setFilter) {
      setFilter.filter = setFilter.filter.map(
        (x) => (x = { name: x.name, checked: false }),
      );
      filterRingData.forEach((x) => {
        const index = setFilter.filter.findIndex((y) => y.name === x[header]);
        if (setFilter.filter[index]) setFilter.filter[index].checked = true;
      });
    },

    formaBytes(byte, decimals = 2) {
      if (byte === 0) return "0 Byte";

      const k = 1000;
      const size = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(byte) / Math.log(k));

      return parseFloat((byte / Math.pow(k, i)).toFixed(1)) + " " + size[i];
    },
    clipboardDataCheck(e) {
      const str = e.clipboardData.getData("text/plain");
      const convStr = str.replace(/-/g, "");

      e.target.value = "";
      e.target.value = convStr;
    },

    //신규 필터 함수
    SetFilterList(
      header,
      originFilter,
      pageList,
      filterList,
      firSetFilter,
      setFilter,
    ) {
      header.forEach((x) => {
        if (firSetFilter === setFilter) {
          if (x.value !== setFilter && filterList[x.value]) {
            const filter = [];
            this.copyData(originFilter[x.value]).forEach((y) => {
              const findData = pageList.find((z) => z[x.value] === y.name);
              if (findData) {
                filter.push(y);
              }
            });
            filterList[x.value] = filter;
          }
        } else {
          if (x.value !== setFilter && !x.checked && filterList[x.value]) {
            const filter = [];
            this.copyData(originFilter[x.value]).forEach((y) => {
              const findData = pageList.find((z) => z[x.value] === y.name);
              if (findData) {
                filter.push(y);
              }
            });
            filterList[x.value] = filter;
          }

          if (x.value !== setFilter && x.checked && filterList[x.value]) {
            filterList[x.value].forEach((y) => {
              y.checked = !!pageList.find((z) => z[x.value] === y.name);
            });
          }
        }
        if (filterList[x.value]) this.sortHeader(filterList[x.value], x.value);
      });
    },
    /////////////////////////////필터링 함수/////////////////////////////
    /////////////////////////////출고의로서 함수/////////////////////////////
  },
});

// socket
const options = { path: "" }; //Options object to pass into SocketIO
Vue.use(
  new VueSocketIO({
    debug: false,
    connection: SocketIO(process.env.VUE_APP_SOCKET_URL, options),
    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_",
    },
  }),
);
// END socket

new Vue({
  vuetify,
  router,
  store,

  components: {
    "overlay-scrollbars": OverlayScrollbarsComponent,
  },
  render: (h) => h(App),
}).$mount("#app");
