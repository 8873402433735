import { mapGetters } from "vuex";
import { fabricDocsCreate } from "@/api/production";

export default {
  data() {
    return {
      orderQuantity: "",
      shrigkageQuantity: "",
      shrigkageUnitPrice: "",
      unitSign: "y",

      fabricCompany: "",
      fabricItem: "",
      header: [
        {
          text: "단계",
          value: "process",
          align: "center",
          class: "tableHeaderBody",
        },
        {
          text: "업체",
          value: "company",
          align: "center",
          class: "tableHeaderBody",
        },
        {
          text: "수량",
          value: "quantity",
          align: "center",
          class: "tableHeaderBody",
        },
        {
          text: "축률",
          value: "shrigkage",
          align: "center",
          class: "tableHeaderBody",
        },
        {
          text: "수량(축률)",
          value: "shrigkageQuantity",
          align: "center",
          class: "tableHeaderBody",
        },

        {
          value: "pluse",
          align: "center",
          class: "tableHeaderBody",
        },
      ],

      colorsHeader: [
        {
          text: "No.",
          value: "no",
          align: "center",
          class: "tableHeaderBody",
        },
        {
          text: "컬러",
          value: "name",
          align: "center",
          class: "tableHeaderBody",
        },
        {
          text: "수량",
          value: "quantity",
          align: "center",
          class: "tableHeaderBody",
        },
        {
          text: "pluse",
          value: "pluse",
          align: "center",
          class: "tableHeaderBody",
        },
      ],

      colors: [
        {
          name: "",
          quantity: "",
        },
        {
          name: "",
          quantity: "",
        },
        {
          name: "",
          quantity: "",
        },
        {
          name: "",
          quantity: "",
        },
        {
          name: "",
          quantity: "",
        },
        {
          name: "",
          quantity: "",
        },
        {
          name: "",
          quantity: "",
        },
      ],

      processingSequence: [
        {
          company: "",
          shrigkage: "",
          quantity: "",
          shrigkageQuantity: "",
        },
        {
          company: "",
          shrigkage: "",
          quantity: "",
          shrigkageQuantity: "",
        },
        {
          company: "",
          shrigkage: "",
          quantity: "",
          shrigkageQuantity: "",
        },
        {
          company: "",
          shrigkage: "",
          quantity: "",
          shrigkageQuantity: "",
        },
        {
          company: "",
          shrigkage: "",
          quantity: "",
          shrigkageQuantity: "",
        },
        {
          company: "",
          shrigkage: "",
          quantity: "",
          shrigkageQuantity: "",
        },
        {
          company: "",
          shrigkage: "",
          quantity: "",
          shrigkageQuantity: "",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      dialog: "getFabricCaculateDialog",
      clientList: "getUseClientList",
      itemList: "getFabricItems",
      release: "getReleaseForm",
    }),

    unitSigns() {
      return [...this.$store.state.unit_sign, "kg"];
    },

    commaOrderQuantity() {
      return this.usingComma(this.orderQuantity);
    },

    saveForm() {
      const processingSequence = this.processingSequence.filter(
        (x) => x.company && x.shrigkage,
      );
      return {
        order: this.$store.state.productionOrderForm.order,
        company: this.$store.state.productionOrderForm.company,
        user: this.$store.state.auth_name,
        useId: this.$store.state.auth_id,
        shrigkageQuantity: this.shrigkageQuantity,
        shrigkageUnitPrice: this.shrigkageUnitPrice,
        fabricItem: this.fabricItem,
        unit_sign: this.unitSign,
        firstManufacture:
          processingSequence[processingSequence.length - 1]?.company,
        processingSequence: JSON.stringify(processingSequence),
        fabricCompany: this.fabricCompany,
        colors: JSON.stringify(this.colors.filter((x) => x.name && x.quantity)),
      };
    },

    // 가공 단계 초기화
    initProcessingSequence() {
      return Array.from({ length: 7 }, () => {
        return {
          company: "",
          shrigkage: "",
          quantity: "",
          shrigkageQuantity: "",
        };
      });
    },

    // 생지 수량
    quantity() {
      return this.usingComma(this.shrigkageQuantity);
    },

    unitPrice() {
      return this.usingComma(this.shrigkageUnitPrice);
    },
  },

  methods: {
    processStep(data, i) {
      return i === 0
        ? "최종가공"
        : data.shrigkage
        ? `${
            this.processingSequence.filter((x) => x.shrigkage).length +
            (this.processingSequence[0].shrigkage ? 0 : 1) -
            i
          } 번째 가공 `
        : "";
    },

    inputFabricQuantity($event) {
      this.shrigkageQuantity = $event;
    },

    inputFabricUnitPrice($event) {
      this.shrigkageUnitPrice = $event;
    },

    isShrigkage(i) {
      return !(
        (i === 0
          ? this.orderQuantity
          : this.processingSequence[i - 1].shrigkage) &&
        this.processingSequence[i].company
      );
    },

    isCompnays(i) {
      return i === 0 ? false : !this.processingSequence[i - 1].shrigkage;
    },

    colorQuantityInput(i) {
      this.orderQuantityCaculation();
      this.colors[i].quantity = this.usingComma(this.colors[i].quantity);
      this.colors[i].indexColorName = `${i + 1}_${this.colors[i].name}`;
      this.shrigkageQuantityCaculation();
    },

    orderQuantityCaculation() {
      this.orderQuantity = this.colors.reduce(
        (r, c) => r + +(c.quantity ? this.parsingNum(c.quantity) : 0),
        0,
      );
    },

    inputOrderQuantity(orderQuantity) {
      this.orderQuantity = this.usingComma(orderQuantity);
      this.shrigkageQuantityCaculation();
    },

    changeCompmany(i, data) {
      this.processingSequence[i].company = data;
    },

    inputShrigkage(i, shrigkage) {
      this.processingSequence[i].shrigkage = shrigkage;
      this.shrigkageQuantityCaculation();
    },

    shrigkageQuantityCaculation() {
      let shrigkageQuantity = "";
      this.processingSequence = this.processingSequence.map((x, i) => {
        const data = x;
        if (i === 0) data.quantity = this.orderQuantity;
        else data.quantity = shrigkageQuantity;

        if (data.shrigkage) {
          const quantity = this.roundsData(
            this.shrigkageCacul(
              this.parsingNum(data.quantity),
              +data.shrigkage,
            ),
          );
          shrigkageQuantity = this.usingComma(quantity);
          data.shrigkageQuantity = shrigkageQuantity;
          // data.color_list = this.setManufactureColorList(i, data.shrigkage);
        } else {
          data.shrigkageQuantity = "";
          shrigkageQuantity = "";
        }

        return data;
      });
      this.setShrigkageQuantity();
    },

    setShrigkageQuantity() {
      const process = this.copyData(this.processingSequence)
        .reverse()
        .find((x) => x.shrigkageQuantity);

      this.shrigkageQuantity = process ? process.shrigkageQuantity : "";
    },

    colorsRowPluse() {
      this.colors.push({
        name: "",
        quantity: "",
      });
    },

    colorRowsDelete(i) {
      this.colors.splice(i, 1);
      if (this.colors.length < 7) this.colorsRowPluse();
      this.orderQuantityCaculation();
      if (!this.orderQuantity) {
        this.processingSequence = [];
        this.processingSequence = this.initProcessingSequence;
      }
    },

    processingSequenceRowPluse() {
      this.processingSequence.push({
        company: "",
        shrigkage: "",
        quantity:
          this.processingSequence[this.processingSequence.length - 1]
            .shrigkageQuantity,
        shrigkageQuantity: "",
      });
    },

    processingSequenceRowsDelete(i) {
      this.processingSequence.splice(i, 1);
      if (this.processingSequence.length < 7) this.processingSequenceRowPluse();
      this.shrigkageQuantityCaculation();
    },

    close() {
      this.$store.commit("setFabricCaculateDialog");
      this.orderQuantity = "";
      this.shrigkageQuantity = "";
      this.processingSequence = [];
      this.fabricCompany = "";
      this.colors = [];

      for (let i = 0; i < 7; i++) {
        this.processingSequence.push({
          company: "",
          shrigkage: "",
          quantity: "",
          shrigkageQuantity: "",
        });
        if (i < 7)
          this.colors.push({
            name: "",
            quantity: "",
          });
      }
    },

    saveDataInputCheck() {
      if (!this.orderQuantity)
        return this.snackbarOn("가공수량을 입력해주세요.");

      if (this.colors.find((x) => !x.name && x.quantity))
        return this.snackbarOn("컬러 명을 입력해주세요.");

      if (!this.shrigkageQuantity)
        return this.snackbarOn("주문할 생지를 입력해 주세요.");

      if (!this.fabricCompany)
        return this.snackbarOn("생지 업체를 입력해주세요.");

      if (!this.fabricItem)
        return this.snackbarOn("생지 아이템을 선택해 주세요.");

      this.save();
    },

    async save() {
      try {
        const result = await fabricDocsCreate(this.saveForm);
        if (result.status !== 200) throw `ERROR`;
        await this.$store.dispatch(
          "PRODUCTION_ORDER_CHANGE",
          this.saveForm.order,
        );
        this.close();
      } catch (e) {
        console.log(e);
      }
    },

    async setSaveOrderForm() {
      await this.$store.dispatch(
        "SELECT_ORDER",
        this.$store.state.productionOrderForm,
      );
      this.saveOrder = this.copyData(this.$store.state.selectOrder);
      this.$store.commit("setSelectOrder");
      this.saveOrder.release.push(this.setRelease());
      this.setOrderManufacture();
    },

    //snacbarOn
    snackbarOn(text) {
      this.$store.commit("setSnackBar", text);
    },
  },
};
