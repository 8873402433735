<template>
  <div :class="className">{{ txt }}</div>
</template>
<script>
export default {
  props: {
    type: String,
    txt: String,
  },
  computed: {
    className() {
      return `${
        this.type === "title" || this.type === "titleEnd"
          ? "releaseCaculateTop"
          : "releaseCaculateBottom"
      } ${
        this.type === "titleEnd" || this.type === "bodyEnd"
          ? "releaseCaculateTopEnd"
          : ""
      }`;
    },
  },
};
</script>
