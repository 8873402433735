import { render, staticRenderFns } from "./OrderForm.vue?vue&type=template&id=1202a6c2&"
import script from "./OrderForm.vue?vue&type=script&lang=js&"
export * from "./OrderForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCheckbox,VMenu,VTextarea})
