import sadFace from "@/assets/image/svg/sad-face.svg";
import fileDelete from "@/assets/image/svg/file_delete.svg";
import penDraw from "@/assets/image/svg/pen-draw.svg";
import graph from "@/assets/image/svg/graph.svg";
import multiWindow from "@/assets/image/svg/multi-window.svg";
import group from "@/assets/image/svg/group.svg";
import freeFunction from "@/assets/image/svg/free-function.svg";
import compression from "@/assets/image/svg/compression.svg";
import useMobile from "@/assets/image/svg/use-mobile.svg";
import easyUi from "@/assets/image/svg/easy-ui.svg";
import useWeb from "@/assets/image/svg/use-web.svg";
import textile from "@/assets/image/svg/textile.svg";
import printDocument from "@/assets/image/svg/print-document.svg";
import simpleDocument from "@/assets/image/svg/simple-document.svg";
import pcMobile from "@/assets/image/svg/pc-mobile.svg";
import usefulSetting from "@/assets/image/svg/useful-setting.svg";
import boardGraph from "@/assets/image/svg/board-graph.svg";
import documentCheck from "@/assets/image/svg/document-check.svg";
import peopleUse from "@/assets/image/svg/people-use.svg";
import peopleShare from "@/assets/image/svg/people-share.svg";
import excelLike from "@/assets/image/svg/excel-like.svg";
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  components: {
    sadFace,
    fileDelete,
    penDraw,
    graph,
    multiWindow,
    group,
    freeFunction,
    compression,
    useMobile,
    easyUi,
    useWeb,
    textile,
    printDocument,
    simpleDocument,
    pcMobile,
    usefulSetting,
    boardGraph,
    documentCheck,
    peopleUse,
    peopleShare,
    excelLike,
  },
  name: "App",
  data() {
    return {
      showScrollTop: false,

      menuItems: [
        { text: "원단마니아 로그인", link: "/login" },
        {
          text: "도입문의",
          link: "https://docs.google.com/forms/d/e/1FAIpQLSdQpl1Zf0sgeFOtayLDIxiC0yBGtmLWrsYOuTIXPMw0HPUkFA/viewform?usp=sf_link",
        },
      ],
    };
  },

  methods: {
    navigateTo(link) {
      if (link.startsWith("http")) {
        window.open(link, "_blank");
      } else {
        this.$router.push(link);
      }
      this.menu = false;
    },
    initSliders() {
      let leftBtn = [...document.getElementsByClassName("slider-leftBtn")];
      let rightBtn = [...document.getElementsByClassName("slider-rightBtn")];
      let slider = [...document.querySelectorAll(".card-container")];
      let sliderDimension = document.querySelector(".card-container");
      slider.forEach((item, i) => {
        let containerDimension = item.getBoundingClientRect().width;
        if (item.childElementCount * 350 < containerDimension + 100) {
          rightBtn[i].style.display = "none";
          leftBtn[i].style.display = "none";
          item.style.justifyContent = "space-around";
        }
        leftBtn[i].style.display = "none";
        rightBtn[i].addEventListener("click", () => {
          item.scrollLeft += containerDimension;
          leftBtn[i].style.display = "block";
        });
        leftBtn[i].addEventListener("click", () => {
          item.scrollLeft -= containerDimension;
          rightBtn[i].style.display = "block";
        });
        item.addEventListener("scroll", () => {
          if (item.offsetWidth + item.scrollLeft >= item.scrollWidth - 100) {
            rightBtn[i].style.display = "none";
            leftBtn[i].style.display = "block";
          } else if (item.scrollLeft == 0) {
            leftBtn[i].style.display = "none";
            rightBtn[i].style.display = "block";
          } else if (item.scrollLeft > 0) {
            leftBtn[i].style.display = "block";
          }
          if (item.offsetWidth + item.scrollLeft < item.scrollWidth - 50) {
            rightBtn[i].style.display = "block";
          }
        });
        const slidingFunction = () => {
          if (item.offsetWidth + item.scrollLeft >= item.scrollWidth - 100) {
            item.scrollLeft = 0;
          }
          if (item.offsetWidth + item.scrollLeft < item.scrollWidth - 50) {
            item.scrollLeft += containerDimension;
          }
        };
        setInterval(() => slidingFunction(), 5000);
      });
    },
    initCarousel() {
      const carousel = document.querySelector(".carousel");
      const prevBtn = document.querySelector(".prev-btn");
      const nextBtn = document.querySelector(".next-btn");
      let currentIndex = 0;
      let cardWidth = document.querySelector(".carousel-card").offsetWidth + 20;
      let numCards = document.querySelectorAll(".carousel-card").length;
      const goToNext = () => {
        if (currentIndex < numCards - 3) {
          currentIndex++;
        } else {
          currentIndex = 0;
        }
        updateCarousel();
      };
      const goToPrev = () => {
        if (currentIndex > 0) {
          currentIndex--;
        } else {
          currentIndex = numCards - 3;
        }
        updateCarousel();
      };
      const updateCarousel = () => {
        carousel.style.transform = `translateX(-${currentIndex * cardWidth}px)`;
      };
      prevBtn.addEventListener("click", goToPrev);
      nextBtn.addEventListener("click", goToNext);
      let autoSlide = setInterval(goToNext, 3000);
    },
    handleScroll() {
      this.showScrollTop = this.$refs.bodyContainer.scrollTop > 100;
    },
    scrollToTop() {
      this.$refs.bodyContainer.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
  created() {},
  mounted() {
    this.initSliders();
    this.initCarousel();
    this.$refs.bodyContainer.addEventListener("scroll", this.handleScroll);

    AOS.init();
  },
  beforeDestroy() {
    this.$refs.bodyContainer.removeEventListener("scroll", this.handleScroll);
  },
};
