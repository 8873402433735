import ProcessTopBtn from "@/components/pages/production/docForm/topBtn/ProcessTopBtn.vue";
import FabricTopBtn from "@/components/pages/production/docForm/topBtn/FabricTopBtn.vue";

export default {
  components: {
    ProcessTopBtn,
    FabricTopBtn,
  },
  props: {
    fabricType: Boolean,
  },
};
