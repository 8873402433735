<template>
  <div :class="className" @click="onClick">{{ title }}</div>
</template>
<script>
export default {
  props: {
    title: String,
    disabled: Boolean,
  },

  computed: {
    className() {
      return this.disabled ? "disabled" : "unDisabled";
    },
  },

  methods: {
    onClick() {
      if (this.disabled) return;
      this.$emit("onClick");
    },
  },
};
</script>

<style lang="scss">
.unDisabled {
  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(0, 0, 0, 0.1);
  font-weight: 700;
  width: 100px;
  height: 30px;

  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }

  &:active {
    box-shadow: inset -2px -2px 7px #ffffff9d,
      inset 3px 3px 5px rgba(85, 93, 106, 0.3);
  }
}

.disabled {
  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(0, 0, 0, 0.05);
  font-weight: 700;
  color: rgba(0, 0, 0, 0.3);
  width: 100px;
  height: 30px;

  border-radius: 4px;
  font-size: 14px;
}
</style>
