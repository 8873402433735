<template>
  <div
    class="custom-select-field"
    :style="{ fontSize: fontSize + 'px', width: width, height: height }"
  >
    <div
      class="field-container"
      :class="{ focused: isFocused, error: !!errorMessage, disabled: disabled }"
      @click="toggleDropdown"
    >
      <span v-if="prefix" class="prefix">{{ prefix }}</span>
      <slot name="prefix"></slot>
      <div class="input-wrapper">
        <label v-if="label" :class="{ hidden: isFocused || localValue }">{{
          label
        }}</label>
        <div class="selected-value">{{ selectedLabel }}</div>
        <button
          v-if="clearable && localValue && !disabled"
          class="clear-button"
          @click.stop="clearValue"
        >
          &times;
        </button>
        <div class="dropdown-arrow">&#9662;</div>
      </div>
      <span v-if="suffix" class="suffix">{{ suffix }}</span>
      <slot name="suffix"></slot>
    </div>
    <div v-if="showDropdown" class="dropdown-menu">
      <div
        v-for="item in items"
        :key="item.value"
        class="dropdown-item"
        @click="selectItem(item)"
      >
        {{ item[itemText] }}
      </div>
    </div>
    <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
  </div>
</template>

<script>
export default {
  name: "CustomSelectField",
  props: {
    label: String,
    value: [String, Number],
    items: {
      type: Array,
      required: true,
    },
    itemText: {
      type: String,
      default: "label",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    prefix: String,
    suffix: String,
    errorMessage: String,
    fontSize: {
      type: Number,
      default: 14,
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "auto",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFocused: false,
      localValue: this.value,
      showDropdown: false,
    };
  },
  computed: {
    selectedLabel() {
      const selectedItem = this.items.find(
        (item) => item.value === this.localValue,
      );
      return selectedItem ? selectedItem[this.itemText] : "";
    },
  },
  watch: {
    value(newValue) {
      this.localValue = newValue;
    },
    localValue(newValue) {
      this.$emit("input", newValue);
    },
  },
  methods: {
    handleFocus() {
      this.isFocused = true;
      this.$emit("focus");
    },
    handleBlur() {
      this.isFocused = false;
      this.$emit("blur");
    },
    toggleDropdown() {
      if (!this.disabled) {
        this.showDropdown = !this.showDropdown;
      }
    },
    selectItem(item) {
      this.localValue = item.value;
      this.showDropdown = false;
      this.$emit("chageSelect", item);
    },
    clearValue() {
      this.localValue = "";
      this.$emit("clear");
    },
    handleDocumentClick(event) {
      if (this.$el.contains(event.target)) {
        return;
      }
      this.showDropdown = false;
      this.isFocused = false;
    },
  },
  mounted() {
    document.addEventListener("click", this.handleDocumentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleDocumentClick);
  },
};
</script>

<style scoped>
.custom-select-field {
  display: flex;
  flex-direction: column;
  position: relative; /* 부모 요소의 위치를 기준으로 dropdown-menu가 나타나도록 설정 */
}

.field-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0px;
  position: relative;
  transition: border-color 0.3s;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
  min-height: -webkit-fill-available;
}

.field-container.focused {
  border-color: #007bff;
}

.field-container.error {
  border-color: #dc3545;
}

.field-container.disabled {
  background-color: #f5f5f5;
  border-color: #ddd;
  cursor: not-allowed;
}

.prefix,
.suffix {
  color: #6c757d;
  margin: 0 4px;
}

.input-wrapper {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden; /* Prevent content overflow */
}

label {
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  color: #6c757d;
  font-size: inherit;
  pointer-events: none;
  transition: all 0.3s;
}

label.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.3s, opacity 0.3s;
}

.selected-value {
  flex: 1;
  padding: 4px 5px 4px 4px;
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.clear-button {
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #6c757d;
  cursor: pointer;
  font-size: inherit;
  padding: 0;
  line-height: 1;
}

.clear-button:hover {
  color: #dc3545;
}

.dropdown-arrow {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  pointer-events: none;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-item {
  padding: 8px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f5f5f5;
}

.error-message {
  color: #dc3545;
  font-size: 12px;
  margin-top: 4px;
}
</style>
