import { instance } from "./index";

function productionPageSave(param) {
  return instance.post(`production/productionPageSave`, param);
}
function productionPageDelete(param) {
  return instance.post(`production/productionPageDelete`, param);
}
function productionPageSearchApproval(param) {
  return instance.post("production/productionPageSearchApproval", param);
}

function productionPageNewDocDelete(param) {
  return instance.post("production/productionPageNewDocDelete", param);
}

// 생지 의뢰서, 가공의뢰서 일괄 생성 api
function fabricDocsCreate(param) {
  return instance.post("production/fabricDocsCreate", param);
}

// 생지,축률 가공의뢰서 를 저장 하는 api
function fabricDocSave(param) {
  return instance.post("production/fabricDocSave", param);
}

// 생지,축률 가공의뢰서 를 삭제 하는 api
function fabricDocDelete(param) {
  return instance.post("production/fabricDocDelete", param);
}

// progress 메모 수정하는 api
function progressMemoModify(param) {
  return instance.post("production/memoModify", param);
}

//생지 문서를 통하여 생성된 가공,출고,생지 의뢰서 를 호출 하는 api
function getFabricDoc({ order, company, id, kind }) {
  return instance.get(
    `production/getFabricDoc/?order=${order}&company=${company}&id=${id}&kind=${kind}`,
  );
}

//생지 재고 호출 api
function getFabricStocks({ company, use_id }) {
  return instance.get(
    `production/getFabricStocks/?company=${company}&use_id=${use_id}`,
  );
}

// 재고 item 을 반환 하는 함수
function getStockItems(company) {
  return instance.get(`production/getStockItems/?company=${company}`);
}

// 재고 colors 을 반환 하는 함수
function getStockColors(company, item) {
  return instance.get(
    `production/getStockColors/?company=${company}&item=${item}`,
  );
}

// 재고 사용 출고의뢰서 ColorList 를 생성하여 반환 하는 api
function getStockReleaseColors(colorList) {
  return instance.post(`production/getUseStockColors`, colorList);
}

export {
  productionPageSave,
  productionPageDelete,
  productionPageSearchApproval,
  productionPageNewDocDelete,
  fabricDocsCreate,
  progressMemoModify,
  getFabricDoc,
  fabricDocSave,
  fabricDocDelete,
  getFabricStocks,
  getStockItems,
  getStockColors,
  getStockReleaseColors,
};
