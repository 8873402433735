import { instance } from "./index";

function getOrder(company, params, status) {
  const query = [];

  return instance.get(`order/${company}/${query.join("&")}`);
}
function getSelectOrderList(company, status) {
  return instance.get(
    `order/list/${company}/selectOrderList/?status=${status}`,
  );
}
function getSelectOrder(company, id) {
  return instance.get(`order/${company}/?_id=${id}`);
}
function getSelectIdOrder(company, id) {
  return instance.get(`order/${company}/?order=${id}`);
}

function postOrder(data) {
  return instance.post(`order`, data);
}
function putOrder(data) {
  return instance.put(`order`, data);
}
function deleteOrder(company, id) {
  return instance.delete(`order/${company}/${id}`);
}

function orderListFind(data) {
  return instance.post(`order/${data.company}/orderList`, data);
}
function orderBakNumFind(company, orderFront, kind) {
  return instance.get(`order/${company}/${orderFront}/${kind}/orderBakNumFind`);
}
function repeatNum(company, order) {
  return instance.get(`order/${company}/${order}/repeatNum`);
}

function releaseListFind(data) {
  return instance.post(`order/${data.company}/releaseList`, data);
}

function productionListFind(data) {
  return instance.post(`order/${data.company}/productionOrderList`, data);
}
function claimListFind(company) {
  return instance.get(`order/${company}/claimList`);
}
function endClaimListFind(company) {
  return instance.get(`order/${company}/endClaimList`);
}
function purchaseCacListFind(company) {
  return instance.get(`order/${company}/purReleaseList`);
}
function excelMainListFind(param) {
  return instance.post(`order/excelMainList`, param);
}
function excelOrderListFind(company) {
  return instance.get(`order/${company}/excelOrderList`);
}
function excelClaimListFind(company) {
  return instance.get(`order/${company}/excelClaimList`);
}
function excelSettlementListFind(param) {
  return instance.post(`order/excelSettlement`, param);
}
function orderBuyerFind(company, order) {
  return instance.get(`order/${company}/${order}/buyerFind`);
}
function sseCall() {
  return instance.get(`order/sse`);
}

function mainListFind(data) {
  return instance.post(`order/${data.company}/mainOrderList/`, data);
}
function mainChartDataFind(data) {
  return instance.post(`order/${data.company}/mainChartData/`, data);
}

/**
 * 원단 마니아 웹 안정화 작업 쿼리 (24.03.11 ~)
 */
// 필터 리스트 호출 쿼리
function orderFilters(data) {
  return instance.post(`order/${data.company}/filters`, data.params);
}
function searchDocGrop(data) {
  return instance.post(`order/${data.company}/search`, data.params);
}
function totalCount(data) {
  return instance.post(`order/${data.company}/totalCount`, data.params);
}
function calculateSave(data) {
  return instance.post(`order/calculateSave`, data);
}
function calculateEditSave(data) {
  return instance.post("order/calculateEditSave", data);
}
function orderPageSave(data) {
  return instance.post("order/orderPageSave", data);
}

export {
  getOrder,
  postOrder,
  putOrder,
  deleteOrder,
  getSelectOrder,
  getSelectIdOrder,
  orderListFind,
  orderBakNumFind,
  repeatNum,
  mainListFind,
  releaseListFind,
  claimListFind,
  endClaimListFind,
  purchaseCacListFind,
  productionListFind,
  excelOrderListFind,
  excelClaimListFind,
  orderBuyerFind,
  // orderReleaseListFind,
  getSelectOrderList,
  sseCall,
  // 필터 리스트 호출 쿼리
  orderFilters,
  searchDocGrop,
  totalCount,
  calculateSave,
  calculateEditSave,
  orderPageSave,
  mainChartDataFind,
  excelMainListFind,
  excelSettlementListFind,
};
