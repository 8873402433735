import { mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      //   inputSetStockList: [],
      //   outputSetStockList: [],
      useInputStockList: [],
      useOutputStockList: [],
      setClient: "",

      inputAllCheckBox: false,
      outputAllCheckBox: false,
      editUseInputStockForm: {},
      editUseOutputStockForm: {},
      inputOnRow: "",
      outputOnRow: "",
      header: [
        {
          text: "",
          width: "2%",
          align: "center",
          class: "tableHeaderBody",
          value: "check",
          filterable: false,
        },
        {
          text: "날짜",
          width: "2%",
          align: "center",
          class: "tableHeaderBody",
          value: "date",
        },

        {
          text: "오더",
          width: "5%",
          value: "order",
          align: "center",
          class: "tableHeaderBody",
        },
        {
          text: "아이템",
          value: "item",
          width: "5%",
          align: "center",
          class: "tableHeaderBody",
        },

        {
          text: "컬러",
          value: "name",
          width: "4%",
          align: "center",
          class: "tableHeaderBody",
        },

        {
          text: "잔여 수량",
          width: "8%",
          align: "center",
          class: "tableHeaderBody",
          value: "remain_quantity",
          type: "number",
          filterable: true,
        },
        {
          text: "사용 수량",
          width: "8%",
          align: "center",
          class: "tableHeaderBody",
          value: "use_quantity",
          type: "number",
          filterable: true,
        },
        {
          text: "단가",
          width: "8%",
          align: "center",
          class: "tableHeaderBody",
          value: "unit_price",
          type: "number",
          filterable: true,
        },
        {
          text: "상태",
          width: "8%",
          align: "center",
          class: "tableHeaderBody",
          value: "status",
          type: "number",
          groupable: true,
        },
      ],
      useStockHeader: [
        {
          text: "날짜",
          width: "2%",
          align: "center",
          class: "tableHeaderBody",
          value: "date",
        },

        {
          text: "오더",
          width: "5%",
          value: "order",
          align: "center",
          class: "tableHeaderBody",
        },
        {
          text: "아이템",
          value: "item",
          width: "5%",
          align: "center",
          class: "tableHeaderBody",
        },

        {
          text: "컬러",
          value: "name",
          width: "4%",
          align: "center",
          class: "tableHeaderBody",
        },

        {
          text: "총 수량",
          width: "8%",
          align: "center",
          class: "tableHeaderBody",
          value: "stock_quantity",
          type: "number",
          groupable: true,
        },
        {
          text: "사용 수량",
          width: "8%",
          align: "center",
          class: "tableHeaderBody",
          value: "use_quantity",
          type: "number",
          groupable: true,
        },
        {
          text: "단가",
          width: "8%",
          align: "center",
          class: "tableHeaderBody",
          value: "unit_price",
          type: "number",
          groupable: true,
        },
        {
          text: "상태",
          width: "8%",
          align: "center",
          class: "tableHeaderBody",
          value: "status",
          type: "number",
          groupable: true,
        },
        {
          text: "",
          width: "8%",
          align: "center",
          class: "tableHeaderBody",
          value: "edit",
          type: "number",
          groupable: true,
        },
      ],

      setTotalQuantity_y: 0,
      setTotalQuantity_m: 0,
    };
  },
  computed: {
    ...mapGetters({
      usedInputStockList: "getInputUsedStockList",
      usedOutputStockList: "getOutputUsedStockList",
      outputSetStockList: "getOutputSetStockList",
      inputSetStockList: "getInputSetStockList",
      useStockClientList: "getUseStockClientList",
    }),
    // clientList() {
    //   return this._.uniqBy(this.$store.state.stockTableList, "client")
    //     .map((data) => {
    //       return data.kind === "제직"
    //         ? {
    //             client: data.client,
    //             original_client: data.clientId,
    //             stock_quantity_y: this.roundsData(
    //               this.$store.state.stockTableList.reduce(
    //                 (r, c) =>
    //                   r +
    //                   (data.client === c.client && c.unit_sign === "y"
    //                     ? +c.remain_quantity
    //                     : 0),
    //                 0,
    //               ),
    //             ),
    //             stock_quantity_m: this.roundsData(
    //               this.$store.state.stockTableList.reduce(
    //                 (r, c) =>
    //                   r +
    //                   (data.client === c.client && c.unit_sign === "m"
    //                     ? +c.remain_quantity
    //                     : 0),
    //                 0,
    //               ),
    //             ),
    //           }
    //         : null;
    //     })
    //     .filter((item) => item !== null);
    // },
    totalStockQuantity_y() {
      return this.roundsData(
        this.useStockClientList.reduce(
          (r, c) => r + (c.stock_quantity_y ? c.stock_quantity_y : 0),
          0,
        ),
      );
    },
    totalStockQuantity_m() {
      return this.roundsData(
        this.useStockClientList.reduce(
          (r, c) => r + (c.stock_quantity_m ? c.stock_quantity_m : 0),
          0,
        ),
      );
    },
    inputStockQuantity_y() {
      return this.roundsData(
        this.inputSetStockList.reduce(
          (r, c) =>
            r +
            (c.remain_quantity
              ? c.unit_sign === "y"
                ? c.remain_quantity
                : 0
              : 0),
          0,
        ),
      );
    },
    inputStockQuantity_m() {
      return this.roundsData(
        this.inputSetStockList.reduce(
          (r, c) =>
            r +
            (c.remain_quantity
              ? c.unit_sign === "m"
                ? c.remain_quantity
                : 0
              : 0),
          0,
        ),
      );
    },
    outputStockQuantity_y() {
      return this.roundsData(
        this.outputSetStockList.reduce(
          (r, c) =>
            r +
            (c.remain_quantity
              ? c.unit_sign === "y"
                ? c.remain_quantity
                : 0
              : 0),
          0,
        ),
      );
    },
    outputStockQuantity_m() {
      return this.roundsData(
        this.outputSetStockList.reduce(
          (r, c) =>
            r +
            (c.remain_quantity
              ? c.unit_sign === "m"
                ? c.remain_quantity
                : 0
              : 0),
          0,
        ),
      );
    },

    inputTotalStockQuantity_y() {
      return this.roundsData(
        this.usedInputStockList.reduce(
          (r, c) =>
            r +
            (c.use_quantity ? (c.unit_sign === "y" ? c.use_quantity : 0) : 0),
          0,
        ),
      );
    },
    inputTotalStockQuantity_m() {
      return this.roundsData(
        this.usedInputStockList.reduce(
          (r, c) =>
            r +
            (c.use_quantity ? (c.unit_sign === "m" ? c.use_quantity : 0) : 0),
          0,
        ),
      );
    },
    outputTotalStockQuantity_y() {
      return this.roundsData(
        this.usedOutputStockList.reduce(
          (r, c) =>
            r +
            (c.use_quantity ? (c.unit_sign === "y" ? c.use_quantity : 0) : 0),
          0,
        ),
      );
    },
    outputTotalStockQuantity_m() {
      return this.roundsData(
        this.usedOutputStockList.reduce(
          (r, c) =>
            r +
            (c.use_quantity ? (c.unit_sign === "m" ? c.use_quantity : 0) : 0),
          0,
        ),
      );
    },
  },
  methods: {
    async setStock(item) {
      this.setClient = item.original_client;
      this.setTotalQuantity_y = item.stock_quantity_y;
      this.setTotalQuantity_m = item.stock_quantity_m;

      await this.$store.dispatch("GET_SET_STOCK_LIST", item.original_client);
      await this.$store.dispatch(
        "GET_USE_STOCK_TABLE_LIST",
        item.original_client,
      );
      this.useInputStockList = [];
      this.useOutputStockList = [];
    },

    checkBoxAllChange(type) {
      if (type === "input") {
        this.inputAllCheckBox = !this.inputAllCheckBox;

        this.inputSetStockList.forEach((x) => {
          x.checkBox = this.inputAllCheckBox;
        });
        if (this.inputAllCheckBox)
          this.useInputStockList = this._.cloneDeep(this.inputSetStockList);
        if (!this.inputAllCheckBox) this.useInputStockList = [];
      }
      if (type === "output") {
        this.outputAllCheckBox = !this.outputAllCheckBox;

        this.outputSetStockList.forEach((x) => {
          x.checkBox = this.outputAllCheckBox;
        });
        if (this.outputAllCheckBox)
          this.useOutputStockList = this._.cloneDeep(this.outputSetStockList);
        if (!this.outputAllCheckBox) this.useOutputStockList = [];
      }
    },

    checkboxChange(item, type) {
      if (type === "input") {
        item.checkBox = !item.checkBox;

        if (item.checkBox) this.useInputStockList.push(item);
        if (!item.checkBox) {
          this.useInputStockList = this.useInputStockList.filter((data) => {
            return data._id !== item._id;
          });
          this.inputAllCheckBox = false;
        }
        if (this.inputSetStockList.every((data) => data.checkBox === true))
          this.inputAllCheckBox = true;
      }
      if (type === "output") {
        item.checkBox = !item.checkBox;
        if (item.checkBox) this.useOutputStockList.push(item);
        if (!item.checkBox) {
          this.useOutputStockList = this.useOutputStockList.filter((data) => {
            return data._id !== item._id;
          });
          this.outputAllCheckBox = false;
        }
        if (this.outputSetStockList.every((data) => data.checkBox === true))
          this.outputAllCheckBox = true;
      }
    },
    quantityInput(item, input, type, index) {
      if (type === "output" || type === "input") {
        item.use_quantity = this.usingComma(input);
        const inputQuantity = input.replace(/,/gi, ""); // 콤마 제거
        if (Math.abs(+item.remain_quantity) < +inputQuantity) {
          this.$store.commit(
            "setSnackBar",
            "사용수량은 잔여수량을 초과해서 사용할수 없습니다.",
          );
          this.$set(item, "use_quantity", null);
          if (type === "input") this.inputSetStockList.splice(index, 1, item);
          if (type === "output") this.outputSetStockList.splice(index, 1, item);
        }
      }
      if (type === "editInput" || type === "editOutput") {
        const editForm =
          type === "editInput"
            ? this.editUseInputStockForm
            : this.editUseOutputStockForm;
        const otherQuantities = (
          type === "editInput"
            ? this.usedInputStockList
            : this.usedOutputStockList
        ).reduce(
          (r, c) =>
            r +
            (editForm.stock === c.stock && editForm.id !== c.id
              ? +c.use_quantity
              : 0),
          0,
        );
        const sumQuantity =
          +this.uncomma(editForm.use_quantity) + otherQuantities;

        if (editForm.stock_quantity < sumQuantity) {
          this.$store.commit(
            "setSnackBar",
            "사용수량은 잔여수량을 초과해서 사용할수 없습니다.",
          );
          editForm.use_quantity = null;
          return;
        }
        editForm.use_quantity = this.usingComma(input);
      }
    },
    saveValidate(saveList) {
      if (!saveList.length) {
        this.$store.commit("setSnackBar", "사용할 수량을 선택해주세요.");
        return false;
      }
      if (
        !this._.every(
          saveList,
          (data) =>
            !this._.isNil(data.use_quantity) && +data.use_quantity !== 0,
        )
      ) {
        this.$store.commit("setSnackBar", "입력 되지 않은 수량이 존재합니다.");
        return false;
      }
      return true;
    },
    async useSaveCall(type) {
      const saveList =
        type === "input" ? this.useInputStockList : this.useOutputStockList;

      if (!this.saveValidate(saveList)) return;

      if (confirm(`${saveList.length}건을 ${"사용"}하시겠습니까?`)) {
        await this.$store.dispatch("TASK_USE_STOCK", {
          form: saveList,
          type: "use",
          client: this.setClient,
        });

        this.inputAllCheckBox = false;
        if (type === "input") this.useInputStockList = [];
        if (type === "output") this.useOutputStockList = [];
      }
    },
    clickRow(index, item, type) {
      if (type === "input") {
        this.inputOnRow = index;
        this.editUseInputStockForm = JSON.parse(JSON.stringify(item));
        this.editUseInputStockForm.use_quantity = this.usingComma(
          this.editUseInputStockForm.use_quantity,
        );
      }
      if (type === "output") {
        this.outputOnRow = index;
        this.editUseOutputStockForm = JSON.parse(JSON.stringify(item));
        this.editUseOutputStockForm.use_quantity = this.usingComma(
          this.editUseOutputStockForm.use_quantity,
        );
      }
    },
    editUseStock(item, type) {
      const form =
        type === "input"
          ? this.editUseInputStockForm
          : this.editUseOutputStockForm;
      type === "input"
        ? (form.origin_quantity = item.use_quantity)
        : (form.origin_quantity = item.use_quantity);

      const use_quantity = +form.use_quantity.replace(/,/g, "");
      const origin_quantity = +form.origin_quantity;
      type === "input" ? (this.inputOnRow = "") : (this.outputOnRow = "");
      if (use_quantity === origin_quantity) return;
      this.$store.dispatch("TASK_USE_STOCK", {
        form: form,
        type: "edit",
        client: this.setClient,
      });
    },
    deleteStock(item, type) {
      if (
        confirm(
          `${item.use_quantity ? item.use_quantity : ""}${
            item.unit_sign
          }를 ${"삭제"}하시겠습니까?`,
        )
      ) {
        this.$store.dispatch("TASK_USE_STOCK", {
          form: item,
          type: "delete",
          client: this.setClient,
        });
      }
    },
  },
  created() {
    this.$store.dispatch("GET_USE_STOCK_CLIENT_LIST");
  },
  // beforeDestroy() {
  //   this.$store.commit("setUseStockClientList");
  //   this.$store.commit("setUsedStockList");
  // },
};
