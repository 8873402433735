<template>
  <div class="settingFormLayout border-bottom">
    <div class="label">복종명</div>
    <div class="mergeColumn">
      <div class="input">
        <customTextField
          v-model="editedItem.name"
          :clearable="true"
          :height="'30px'"
        >
        </customTextField>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import customTextField from "@/components/atoms/TextField/customTextField";
export default {
  components: {
    customTextField,
  },
  computed: {
    ...mapGetters({
      editedItem: "getEditedItem",
    }),
  },
};
</script>
