export default {
  currency: [
    {
      value: "₩",
      label: "₩",
    },
    {
      value: "$",
      label: "$",
    },
  ],

  unit: [
    {
      value: "y",
      label: "y",
    },
    {
      value: "m",
      label: "m",
    },
  ],
  width: [
    {
      value: "in",
      label: "in",
    },
    {
      value: "cm",
      label: "cm",
    },
  ],

  weight: [
    {
      value: "g/y",
      label: "g/y",
    },
    {
      value: "g/m²",
      label: "g/m²",
    },
    {
      value: "oz/y",
      label: "oz/y",
    },
  ],

  weight_sign: [
    {
      value: "g",
      label: "g",
    },
    {
      value: "kg",
      label: "kg",
    },
  ],
};
