import { mapGetters } from "vuex";
import ProgressDialog from "@/components/organisms/VDialog/ProgressDialog";
import CardProgressTopBtn from "@/components/pages/production/progress/top/CardProgressTopBtn.vue";

//임시
import { putOrder } from "@/api/order";

export default {
  components: {
    ProgressDialog,
    CardProgressTopBtn,
  },
  computed: {
    ...mapGetters({
      form: "getProductionForm",
      itemList: "getItemList",
      clientList: "getClientList",
      manufactureList: "getManufactureList",
      appearanceList: "getAppearanceList",
      releaseList: "getReleaseList",
      loginUser: "getLoginUser",
      saveOrder: "getSaveOrder",
    }),
    orderQuantity() {
      return this.form._id && this.form.color_list.length
        ? this.form.color_list.reduce((r, c) => r + +c.quantity, 0)
        : 0;
    },
    orderTitle() {
      return this.form.order ? this.form.order : "ORDER";
    },

    salesReleaseQuantity() {
      return Math.round(
        this.form._id && this.form.release.length
          ? this.form.release
              .filter(
                (x) => x.kind === 9 && (x.status === "40" || x.status === "00"),
              )
              .reduce(
                (r, c) =>
                  r +
                  (!c.unit_sign || (c.unit_sign === "y" && c.sales)
                    ? +c.sales.quantity
                    : c.unit_sign === "m" && c.sales
                    ? c.sales.quantity * 1.094
                    : 0),
                0,
              )
          : 0,
      );
    },
  },

  methods: {
    ///////////////////////////// 신규 문서 작성 //////////////////////////////
    /**
     * 문서 종류 분별
     * 오더 선택 안했을 경우 return 및 알림 띄우기
     */
    async docClassification(kind) {
      if (!this.form._id) {
        this.$store.commit("setSnackBar", "오더를 선택해 주세요");
        return;
      }
      this.$store.commit("setApprovalForm");

      this.$store.dispatch("PRODUCTION_DIALOG_INIT");
      this.$store.dispatch("EDIT_FORM_CLOSE");

      await this.$store.dispatch("SET_PRODUCTION_ORDER", this.form);

      if (kind === 7) this.manufactureDocAdd();

      if (kind === 8) this.releaseDocAdd();

      if (kind === 11) {
        if (
          !this.form.release.filter((x) => x.kind === 10 && x.status === "40")
            .length
        )
          this.$store.commit("setSnackBar", "검사 의뢰서를 작성해주세요");
        else this.appearanceDocAdd();
      }

      if (this.$store.state.isDocDataBtn) this.$store.commit("setIsDocDataBtn");
    },

    // 기타 문서
    async otherDocAdd(kind) {
      if (!this.form._id) {
        this.$store.commit("setSnackBar", "오더를 선택해 주세요");
        return;
      }
      if (this.$store.state.saveStock) this.$store.commit("setSaveStock");
      if (this.$store.state.deleteStock) this.$store.commit("setDeleteStock");
      this.$store.commit("setIsProgressDialog");
      this.$store.commit("setProgressData", {
        order: this.form.order,
        date_register: new Date(Date.now()),
        kind,
        log:
          kind === 2
            ? "B/T 의뢰"
            : kind === 3
            ? "B/T 전달"
            : kind === 4
            ? "B/T 컨펌"
            : kind === 5
            ? "MAIN 전달"
            : kind === 6
            ? "MAIN CMF"
            : kind === 13
            ? "이화학 의뢰"
            : kind === 14
            ? "이화학 결과"
            : "",
        user: this.$store.state.auth_name,
      });
    },

    // 가공의뢰서 버튼 클릭시 리스너
    manufactureDocAdd() {
      let manufacture = this.$store.state.manufactureForm;
      if (manufacture.order !== this.form.order || !manufacture.id)
        this.$store.dispatch("MANUFACTURE_CLEAR", "new");
      else this.$store.commit("setProductionColor", manufacture.color_List);
      manufacture.dialog = true;
    },

    // 신규 출고의뢰서 버튼 리스너
    releaseDocAdd() {
      const release = this.$store.state.releaseForm;

      this.$store.dispatch("RELEASEFORM_CLEAR");
      this.$store.dispatch("SET_DOC_LIST", release.salesBtn);
    },

    // 신규 외관 검사서 작성
    appearanceDocAdd() {
      this.$store.dispatch("APPEARANCE_CLEAR");
    },

    colorEditedItemSet(colorList) {
      const formColor = this.copyData(colorList);
      let productionColor = [];
      if (formColor.length < 9)
        for (let i = 0; i < 9; i++) {
          productionColor.push(formColor[i] !== undefined ? formColor[i] : {});
        }
      else productionColor = formColor;
      this.$store.commit("setProductionColor", productionColor);
      return productionColor
        .filter((x) => x.quantity || x.quantity_sample)
        .reduce(
          (r, c) =>
            r +
            (c.quantity_sample
              ? +c.quantity
                ? +c.quantity + +c.quantity_sample
                : +c.quantity_sample
              : +c.quantity),
          0,
        );
    },

    // 임시 함수
    async initOrder() {
      await this.$store.dispatch("SELECT_ORDER", this.form);
      const order = {
        ...this.$store.state.selectOrder,
        manufacture: [],
        release: [],
        progress: [this.$store.state.selectOrder.progress[0]],
      };

      await this.saveApiAction({
        action: putOrder,
        saveData: order,
      });
    },

    ///////////////////////////// 생지 수량 계산 dialog open//////////////////////////////
    fabricCaculateDialogOpen() {
      if (!this.form._id)
        return this.$store.commit("setSnackBar", "오더를 선택해 주세요");
      this.$store.commit("setFabricCaculateDialog");
    },

    ///////////////////////////////////////////////////////////////////////
  },
};
