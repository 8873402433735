import fabricStock from "./fabricStock.vue";
import rawStock from "./rawStock.vue";
import useStock from "./UseStock.vue";
import { mapGetters } from "vuex";
import LoadingSpinner from "@/components/organisms/LoadingSpinner/LoadingSpinner";
import SnackBar from "@/components/organisms/SnackBar/SnackBar";
import numFilterDialog from "@/components/organisms/VDialog/numFilterDialog";
import header from "@/assets/table/stock/stock.js";
import commitName from "@/components/pages/common/ApiActionName.js";
import menuTab from "@/components/atoms/Tabs/menuTab.vue";
export default {
  components: {
    fabricStock,
    LoadingSpinner,
    SnackBar,
    numFilterDialog,
    useStock,
    rawStock,
    menuTab,
  },
  computed: {
    ...mapGetters({
      pageParams: "getStockParam",
      getStockType: "getStockSelected",
    }),
    totalData() {
      return this.setSearchData("search", this.names.totalCommitName);
    },
    searchData() {
      return this.setSearchData("search", this.names.searchCommitName);
    },
    infiniteScrollData() {
      return this.setSearchData("infinite", this.names.infiniteCommitName);
    },
    pageList() {
      return this.$store.state.stockTableList;
    },
    sumStockQuantity() {
      return parseFloat(
        this.pageList
          .reduce(
            (r, c) =>
              r +
              (c.stock_quantity !== "(비어있음)"
                ? !c.unit_sign || c.unit_sign === "y"
                  ? c.stock_quantity
                  : c.stock_quantity * 1.094
                : 0),
            0,
          )
          .toFixed(2),
      );
    },
    sumRemainQuantity() {
      return parseFloat(
        this.pageList
          .reduce(
            (r, c) =>
              r +
              (c.remain_quantity !== "(비어있음)"
                ? !c.unit_sign || c.unit_sign === "y"
                  ? c.remain_quantity
                  : c.remain_quantity * 1.094
                : 0),
            0,
          )
          .toFixed(2),
      );
    },
    sumColorQuantity() {
      return parseFloat(
        this.pageList
          .reduce((r, c) => r + (c.name !== "(비어있음)" && c.name ? 1 : 0), 0)
          .toFixed(2),
      );
    },
    sumItemQuantity() {
      return parseFloat(
        this.pageList
          .reduce((r, c) => r + (c.item !== "(비어있음)" && c.item ? 1 : 0), 0)
          .toFixed(2),
      );
    },
    sumClientQuantity() {
      return parseFloat(
        this.pageList
          .reduce(
            (r, c) => r + (c.client !== "(비어있음)" && c.client ? 1 : 0),
            0,
          )
          .toFixed(2),
      );
    },
  },
  data() {
    return {
      header,
      names: commitName["stock"],

      filterField: "", // 현재 선택한 filter field
      isFilter: false,
      allChecked: false, // 모두 선택 checkbox
      keywordAllChecked: false, // keywod 검색 filter 모두 선택 check box
      filters: [], // 현재 filter 데이터
      params: [], // checked 가 true 데이터
      infinitieSearchField: "", //무한 스크롤 field
      // sort: 1, // 정렬 선택 1: 오림차순, -1 :내림 차순
      isKeyword: false, // filter keyword 입력 여부
      findKeyword: "", // filter 검색을 위한 keyword
      keywordFilters: [], // keyword 가 포함된 filter
      listTypes: [
        {
          value: "fabric_stock",
          label: "원단 재고",
        },
        {
          value: "raw_stock",
          label: "생지 재고",
        },
        {
          value: "manu_stock",
          label: "가공 재고",
        },
      ],
      stockType: [
        { title: "재고", value: "stock" },
        { title: "마감", value: "end" },
        { title: "전체", value: "common" },
      ],
    };
  },
  async created() {
    this.$store.dispatch("SET_API_LOADING", true);
    this.$store.dispatch("GET_SELECT_ORDER_LIST");
    this.$store.dispatch("SET_USE_CLIENTLIST");
    this.$store.dispatch("SET_ITEMLIST");
    this.$store.dispatch("SET_API_LOADING", false);
  },
  methods: {
    search() {
      this.callTotalCountAPI();
      this.callSearchAPI();
    },
    async callTotalCountAPI() {
      this.$store.dispatch(this.names.totalApiName, this.totalData);
    },
    async callSearchAPI() {
      this.$store.dispatch(this.names.searchApiName, this.searchData);
      this.infinitieSearchField = this.setFilterField();
    },
    setFilterField() {
      let field = "";
      if (this.filterField) {
        field = this.filterField;
      }
      if (this.pageParams.length && !this.filterField) {
        field = this.pageParams[this.pageParams.length - 1].field;
      }
      return field;
    },
    initHeader() {
      header.map((head) => {
        head.checked = !!this.pageParams.find(
          (param) => param.field === head.value,
        );
        return head;
      });
    },
    setSearchData(type, name) {
      if (!this.pageParams.length) this.infinitieSearchField = "";
      const data = {
        param: {
          multi: JSON.stringify(this.pageParams),
          field:
            type === "infinite"
              ? this.infinitieSearchField
              : this.setFilterField(),
          sort: this.sort,
          type: this.getStockType,
          pageType:
            this.$store.state.stockView === "fabric_stock" ? "fabric" : "raw",
        },
        commitName: name,
        isInfinite: type !== "infinite",
      };
      if (type === "infinite") data.param.skip = this.pageList.length;

      return data;
    },
    async changeStockType() {
      this.$store.commit(this.names.paramName, []);
      this.search();
      this.initHeader();
    },
  },
  beforeDestroy() {
    // this.$store.commit("setOrderList");
    // this.$store.commit("setItemList");
  },
};
