<template>
  <div
    class="custom-autocomplete"
    :style="{ fontSize: fontSize + 'px', width: width, height: height }"
  >
    <div
      class="field-container"
      :class="{ focused: isFocused, error: !!errorMessage, disabled: disabled }"
    >
      <span v-if="prefix" class="prefix">{{ prefix }}</span>
      <slot name="prefix"></slot>
      <div class="input-wrapper">
        <label v-if="label" :class="{ hidden: isFocused || localValue }">{{
          label
        }}</label>
        <input
          :type="type"
          ref="input"
          :value="localValue"
          @focus="handleFocus"
          @blur="handleBlur"
          @input="handleInput"
          @keydown="handleKeydown"
          :disabled="disabled"
          @change="change"
        />
        <button
          v-if="clearable && localValue && !disabled"
          class="clear-button"
          @click="clearValue"
        >
          &times;
        </button>
        <div class="dropdown-arrow" @click="toggleDropdown">&#9662;</div>
      </div>
      <span v-if="suffix" class="suffix">{{ suffix }}</span>
      <slot name="suffix"></slot>
    </div>
    <div v-if="showDropdown" class="dropdown-menu">
      <div
        v-for="(item, index) in filteredItems"
        :key="getItemValue(item)"
        :class="{
          'dropdown-item': true,
          highlighted: index === highlightedIndex,
        }"
        @click="selectItem(item)"
      >
        {{ getItemText(item) }}
      </div>
      <div v-if="filteredItems.length === 0" class="no-results">
        {{ noResultsMessage }}
      </div>
    </div>
    <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
  </div>
</template>

<script>
import { get } from "lodash";

export default {
  name: "CustomAutocomplete",
  props: {
    label: String,
    value: [String, Number, Object],
    items: {
      type: Array,
      required: true,
    },
    itemText: {
      type: String,
      default: "label",
    },
    itemValue: {
      type: String,
      default: "value",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    prefix: String,
    suffix: String,
    errorMessage: String,
    fontSize: {
      type: Number,
      default: 14,
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "auto",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    noResultsMessage: {
      type: String,
      default: "No results found",
    },
    type: {
      type: String,
      default: "text",
    },
    change: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFocused: false,
      localValue: "",
      selectedValue: this.value || "",
      showDropdown: false,
      highlightedIndex: -1,
    };
  },
  computed: {
    filteredItems() {
      const searchTerm = (this.localValue || "").toLowerCase();
      return this.items.filter((item) =>
        get(item, this.itemText, "").toLowerCase().includes(searchTerm),
      );
    },
  },
  watch: {
    value(newValue) {
      if (typeof newValue === "object" && newValue !== null) {
        this.localValue = get(newValue, this.itemText, "");
        this.selectedValue = get(newValue, this.itemValue, "");
      } else {
        this.selectedValue = newValue;
        const selectedItem = this.items.find(
          (item) => get(item, this.itemValue) === newValue,
        );
        this.localValue = selectedItem ? get(selectedItem, this.itemText) : "";
      }
    },
  },
  methods: {
    handleFocus() {
      this.isFocused = true;
      this.$emit("focus");
      this.showDropdown = true;
    },
    handleBlur() {
      this.isFocused = false;
      this.$emit("blur");
      setTimeout(() => {
        this.showDropdown = false;
      }, 200); // Dropdown을 닫기 전에 클릭 이벤트가 처리되도록 약간의 지연을 추가
    },
    handleInput(event) {
      this.localValue = event.target.value;
      this.showDropdown = true;
      this.highlightedIndex = -1;
    },
    selectItem(item) {
      this.localValue = get(item, this.itemText);
      this.selectedValue = get(item, this.itemValue);
      this.isFocused = false;
      this.highlightedIndex = -1;
      this.$emit("input", this.selectedValue);
      this.showDropdown = false;
    },
    clearValue() {
      this.localValue = "";
      this.selectedValue = "";
      this.$emit("input", "");
    },
    moveDown() {
      if (this.highlightedIndex < this.filteredItems.length - 1) {
        this.highlightedIndex++;
        this.scrollToHighlighted();
      }
    },
    moveUp() {
      if (this.highlightedIndex > 0) {
        this.highlightedIndex--;
        this.scrollToHighlighted();
      }
    },
    selectHighlighted() {
      if (
        this.highlightedIndex >= 0 &&
        this.highlightedIndex < this.filteredItems.length
      ) {
        this.selectItem(this.filteredItems[this.highlightedIndex]);
      }
    },
    toggleDropdown() {
      if (!this.disabled) {
        this.showDropdown = !this.showDropdown;
      }
    },
    extractInitials(text) {
      const initials = text
        .split("")
        .map((char) => {
          const code = char.charCodeAt(0) - 44032;
          if (code >= 0 && code <= 11171) {
            return String.fromCharCode(4352 + Math.floor(code / 588));
          }
          return char;
        })
        .join("");
      return initials;
    },
    handleKeydown(event) {
      if (!this.filteredItems.length) return;

      if (event.key === "ArrowDown") {
        event.preventDefault();
        this.moveDown();
      } else if (event.key === "ArrowUp") {
        event.preventDefault();
        this.moveUp();
      } else if (event.key === "Enter" && this.highlightedIndex !== -1) {
        event.preventDefault();
        this.selectItem(this.filteredItems[this.highlightedIndex]);
      }
    },
    scrollToHighlighted() {
      this.$nextTick(() => {
        const dropdown = this.$el.querySelector(".dropdown-menu");
        const highlighted = dropdown?.querySelector(".highlighted");
        if (highlighted) {
          highlighted.scrollIntoView({
            block: "nearest",
            inline: "nearest",
            behavior: "smooth",
          });
        }
      });
    },
    getItemValue(item) {
      return get(item, this.itemValue);
    },
    getItemText(item) {
      return get(item, this.itemText);
    },
  },
  mounted() {
    if (typeof this.value === "object" && this.value !== null) {
      this.localValue = get(this.value, this.itemText, "");
      this.selectedValue = get(this.value, this.itemValue, "");
    } else {
      this.selectedValue = this.value;
      const selectedItem = this.items.find(
        (item) => get(item, this.itemValue) === this.value,
      );
      this.localValue = selectedItem ? get(selectedItem, this.itemText) : "";
    }
  },
};
</script>

<style scoped>
.custom-autocomplete {
  display: flex;
  flex-direction: column;
  position: relative; /* 부모 요소의 위치를 기준으로 dropdown-menu가 나타나도록 설정 */
}

.field-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0px;
  position: relative;
  transition: border-color 0.3s;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
}

.field-container.focused {
  border-color: #007bff;
}

.field-container.error {
  border-color: #dc3545;
}

.field-container.disabled {
  background-color: #f5f5f5;
  border-color: #ddd;
  cursor: not-allowed;
}

.prefix,
.suffix {
  color: #6c757d;
  margin: 0 4px;
}

.input-wrapper {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden; /* Prevent content overflow */
}

label {
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  color: #6c757d;
  font-size: inherit;
  pointer-events: none;
  transition: all 0.3s;
}

label.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.3s, opacity 0.3s;
}

input {
  flex: 1;
  border: none;
  outline: none;
  font-size: inherit;
  padding: 4px 5px 4px 4px;
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100% - 10px);
}

.clear-button {
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #6c757d;
  cursor: pointer;
  font-size: inherit;
  padding: 0;
  line-height: 1;
}

.clear-button:hover {
  color: #dc3545;
}

.dropdown-arrow {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  pointer-events: none;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-item {
  padding: 8px;
  cursor: pointer;
}

.dropdown-item:hover,
.dropdown-item.highlighted {
  background-color: #f5f5f5;
}

.no-results {
  padding: 8px;
  color: #6c757d;
  text-align: center;
}

.error-message {
  color: #dc3545;
  font-size: 12px;
  margin-top: 4px;
}
</style>
