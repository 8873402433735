<template>
  <v-dialog
    v-model="dialog"
    persistent
    no-click-animation
    width="550px"
    @keydown.esc="$emit('close')"
  >
    <v-card class="confirmDialog">
      <div class="topRow">
        <div class="topDiv topTitle">{{ title }}</div>
      </div>
      <div class="bodyRow">
        <div class="bodyTitle">{{ clientTitle }}</div>
        <div class="bodyConTent">
          {{ client }}
        </div>
      </div>
      <div class="bodyRow">
        <div class="bodyTitle">{{ dateTitle }}</div>
        <div class="bodyConTent">{{ date }}</div>
      </div>
      <div class="bodyRow">
        <div class="bodyTitle">비 고</div>
        <div class="bodyConTent">{{ memo }}</div>
      </div>
      <div class="bodyRow" style="justify-content: right; gap: 10px">
        <div class="applyBlueBtnDiv btn" @click="$emit('save')">
          {{ data.btnTitle ? data.btnTitle : "저 장" }}
        </div>
        <div class="applyBlueBtnDiv btn closeBtn" @click="$emit('close')">
          닫 기
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    dialog: Boolean,
    data: Object,
  },
  computed: {
    title() {
      return this.data?.title;
    },

    clientTitle() {
      return this.data.kind === 19 ? "출고처" : "가공처";
    },

    dateTitle() {
      return this.data.kind === 19 ? "출고 날짜" : "납기 날짜";
    },

    client() {
      return this.data?.client;
    },
    date() {
      return this.data?.date;
    },
    memo() {
      return this.data?.memo;
    },
  },
};
</script>

<style scoped>
.confirmDialog {
  height: 200px;
}
.topRow {
  display: flex;
  background-color: rgba(0, 0, 0, 0.12);
  padding: 0px 5px 0px 5px;
  align-items: center;
  height: 40px;
  width: 100%;

  .topDiv {
    width: 50%;
    display: flex;
  }
  .topTitle {
    padding-left: 10px;
    justify-content: left;
    font-weight: bold;
  }
  .topCloseBtn {
    justify-content: right;
  }
}
.bodyRow {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0px 10px 0px 10px;

  height: 40px;

  .bodyElments {
    display: flex;
    width: 50%;
  }

  .bodyTitle {
    width: 20%;
    font-weight: bold;
  }
  .bodyConTent {
    display: flex;
    align-items: center;
    height: 30px;
    border: thin solid rgba(0, 0, 0, 0.5);
    width: 85%;
    border-radius: 5px;
    padding: 0 5px 0px 5px;
  }

  .btn {
    width: 80px;
    font-size: 14px;
    font-weight: bold;
  }

  .closeBtn {
    background-color: rgba(220, 16, 16, 0.934);

    &:hover {
      background-color: rgba(220, 16, 16, 0.794);
    }
  }
}
</style>
