import { mapGetters } from "vuex";
import manuColorHeaders from "@/assets/table/production/manuColorHeaders";
import ManufactureConfirmDialog from "@/components/pages/production/docForm/manufacture/ManufactureConfirmDialog";
import pritnerFormRightHeader from "@/assets/table/print/pritnerFormRightHeader";
import settingFormDialog from "@/components/organisms/VDialog/SettingFormDialog";
import ClientComboBox from "@/components/atoms/VComboBox/DenseDialogClientComboBox";
import DocForm from "@/components/organisms/docDataForm/docForm.vue";
import DenseSubItemComboBox from "@/components/atoms/VComboBox/DenseSubItemComboBox.vue";
export default {
  components: {
    ManufactureConfirmDialog,
    ClientComboBox,
    settingFormDialog,
    DocForm,
    DenseSubItemComboBox,
  },

  data() {
    return {
      manuColorHeaders,
      pritnerFormRightHeader,
      menu_date_manufacture: false,
      menu_date_payment_manufacture: false,
      styleSelect: true,
      styleItem: "",
      dialog_manufacture_print: false,
      selectManufactures: {
        order: {},
        item: {},
        place_manufacture: {},
        liaison: {},
      },

      post_processing: "",
    };
  },
  computed: {
    ...mapGetters({
      form: "getProductionForm",
      manufacture: "getManufactureForm",
      itemList: "getItemList",
      clientList: "getUseClientList",
      attachmentList: "getAttachmentList",

      // colorList: "getCommaColor",
      saveColorList: "getProductionColor",
      beforVersion: "getBeforProductionData",
      loginUser: "getLoginUser",
      isDocDataBtn: "getIsDocDataBtn",
      docDataList: "getDoctDataList",
    }),
    purchaseCompanyList() {
      return this.clientList;
    },
    inspectionList() {
      return this.clientList;
    },

    bakDialog() {
      return this.manufacture.history
        ? this.manufacture.history.length > 0
          ? true
          : false
        : false;
    },

    colorList() {
      let colorList = this.copyData(this.saveColorList).map((x) => {
        x.quantity = this.usingComma(x.quantity);
        x.quantity_shrigkage = this.usingComma(x.quantity_shrigkage);
        x.quantity_sample = this.usingComma(x.quantity_sample);
        x.color_surcharge_unit = this.usingComma(x.color_surcharge_unit);
        x.color_surcharge_price = this.usingComma(x.color_surcharge_price);
        return x;
      });

      return colorList;
    },

    colorConvertSign() {
      return this.manufacture.unit_sign === "kg"
        ? this.manufacture.weight_unit_sign
        : "kg";
    },

    manufactureUnitSigns() {
      return [...this.$store.state.unit_sign, "kg"];
    },

    // 가공의뢰서 헤더
    formTitleManufacture() {
      return !this.manufacture.id
        ? "신규 가공의뢰서"
        : this.manufacture.status === "00"
        ? "수정전 가공의뢰서"
        : this.manufacture.status === "10"
        ? "취소 요청 가공의뢰서"
        : this.manufacture.status === "20"
        ? "승인 요청 가공의뢰서"
        : this.manufacture.status === "30"
        ? "반려 가공의뢰서"
        : this.manufacture.status === "40"
        ? "가공의뢰서 수정"
        : this.manufacture.status === "50"
        ? "수정 승인 요청 가공의뢰서"
        : "가공의뢰서";
    },

    // 가공의뢰서 disabled
    formManufactureDisabled() {
      return this.loginUser.grade < 2 && this.manufacture.status === "20"
        ? this.manufacture.isModify
        : !this.manufacture.isModify;
    },

    releaseListCheck() {
      return (
        this.releaseList.filter(
          (x) => x.manufacture === this.manufacture._id && x.status !== "99",
        ).length !== 0 && this.manufacture._id
      );
    },

    priceLabel() {
      return this.manufacture.currency_sign !== "₩" ? "단 가 / 환 율" : "단 가";
    },

    width() {
      return this.manufacture.width ? this.manufacture.width : "";
    },

    currencyLable() {
      return this.manufacture.currency_sign === "₩"
        ? !this.isDocDataBtn
          ? "매입단가"
          : "단가"
        : !this.isDocDataBtn
        ? "매입단가/환율"
        : "단가환율";
    },

    totalAmount() {
      let total_amount =
        this.manufacture.total_quantity_sum *
        this.parsingNum(this.manufacture.unit_price);

      //Surcharge(제직, 단위 당 가격이 있을경우)
      if (this.manufacture.sucharge_price)
        total_amount +=
          this.manufacture.total_quantity_sum *
          this.parsingNum(this.manufacture.sucharge_price);

      //Surcharge(제직, 건 당 가격이 있을경우)
      if (this.manufacture.surcharge_unit)
        total_amount += this.parsingNum(this.manufacture.surcharge_unit);

      // color 별 surcahrge 곱
      total_amount += this.saveColorList.reduce((r, c) => {
        if (c.color_surcharge_unit) r += c.color_surcharge_unit * c.quantity;
        if (c.color_surcharge_price) r += c.color_surcharge_price;
        return r;
      }, 0);

      return this.roundsData(total_amount);
    },

    averageCalculator() {
      let average = "";
      if (this.manufacture.total_quantity_sum) {
        const total_amount = this.totalAmount;

        average = this.usingComma(
          this.decimalCacul(total_amount / this.manufacture.total_quantity_sum),
        );

        if (this.manufacture.eschamge) {
          let eschamge = this.decimalCacul(
            (total_amount * this.parsingNum(this.manufacture.eschamge)) /
              this.manufacture.total_quantity_sum,
          );

          average = `${average} (₩ ${this.usingComma(eschamge)})`;
        }
      }

      return average;
    },

    fabricReferences() {
      return this.copyData(
        [...this.form.release, ...this.form.manufacture].filter(
          (x) =>
            (x.kind === 7 || x.kind === 18 || x.kind === 8 || x.kind === 19) &&
            x.status === "40",
        ),
      ).map((x, i) => {
        const doc = x;
        const client = this.clientList.find(
          (x) =>
            x.id ===
            (x.kind === 7 || x.kind === 18
              ? x.place_manufacture
              : x.place_forward),
        );
        doc.referenceName = `${i + 1}. ${client ? client.name : "미지정"}(${
          x.kind === 7
            ? "가공의뢰서"
            : x.kind === 18
            ? "생지 가공"
            : x.kind === 8
            ? "출고의뢰서"
            : "생지매입"
        })`;
        return doc;
      });
    },
  },
  methods: {
    //////////////////////////////////////// 가공의뢰서 입력 기능 /////////////////////////////////////
    keyborderItemChange(item, refs) {
      if (item.key !== "Enter") return;

      this.checkNewSettingForm(item, "manuItem", refs);

      // this.manufacture.item = this.copyData(item.target.value);

      this.setItemWidth();
    },

    itemChange(item) {
      this.manufacture.item = this.copyData(item);
      this.setItemWidth();
    },

    setItemWidth() {
      this.manufacture.width = this.manufacture.item.width
        ? this.manufacture.item.width
        : "";
      this.manufacture.full_width = this.manufacture.item.full_width
        ? this.manufacture.item.full_width
        : "";
      this.manufacture.post_processing = this.manufacture.item.post_processing
        ? this.manufacture.item.post_processing
        : "";
    },

    styleItemsPlus() {
      const styleList = this.manufacture.style_list;
      if (this.styleItem === "" || this.styleItem === null) {
        this.snackbarOn("스타일을 입력해주세요");
        return;
      }
      if (
        this.manufacture.style_list.filter((x) => x === this.styleItem).length >
        0
      ) {
        this.snackbarOn("중복된 스타일 입니다.");
        this.styleItem = "";
        return;
      }
      styleList.push(this.styleItem);
      this.styleItem = "";
    },

    //  가공의뢰서 style List 삭제 로직
    styleItemDelete(index) {
      this.manufacture.style_list.splice(index, 1);
    },

    colorInput(kind, data, index) {
      const color = this.saveColorList;
      if (kind !== "name" && data !== "") data = this.parsingNum(data);

      kind === "surcharge_dyeing_unit" || kind === "surcharge_dyeing_total"
        ? (color[index] = this.surchargeTextileCheck(color[index], kind, data))
        : (color[index][kind] = data);

      if (kind === "quantity" || kind === "quantity_sample")
        this.colroTotalQuantityCacul();

      this.$store.commit("setProductionColor", color);
      if (kind !== "name" && data !== "")
        this.commaColorCheck(kind, data, index);
      if (
        this.manufacture.shrigkage &&
        (!this.manufacture.referenceDoc ||
          this.manufacture.referenceDoc.kind === 19)
      )
        this.colorShrigkageCacul(index);
    },

    colorShrigkageCacul(index) {
      const color = this.saveColorList[index];

      color.quantity_shrigkage = "";

      color.quantity_shrigkage = +this.decimalCacul(
        this.shrigkageCacul(color.quantity, this.manufacture.shrigkage),
      );
    },

    colorShrigkageInput(shrigkage, i) {
      this.saveColorList[i].quantity_shrigkage = this.parsingNum(shrigkage);
      this.colorList[i].quantity_shrigkage = this.usingComma(shrigkage);

      if (
        this.manufacture.referenceDoc &&
        this.manufacture.referenceDoc.kind !== 19 &&
        this.manufacture.shrigkage
      ) {
        const quantity = this.processingQuantity(
          this.saveColorList[i].quantity_shrigkage,
          this.manufacture.shrigkage,
        );
        this.saveColorList[i].quantity = +quantity;
        this.colorList[i].quantity = this.usingComma(quantity);
        this.colroTotalQuantityCacul();
      }
    },

    shrigkageInput() {
      if (
        !this.manufacture.referenceDoc ||
        this.manufacture.referenceDoc.kind === 19
      )
        this.setShrinkageQuantity();
      else this.setProcessingQuantity();
    },

    setShrinkageQuantity() {
      const colorList = this.saveColorList.map((x, i) => {
        const color = x;
        if (!this.manufacture.shrigkage) {
          color.quantity_shrigkage = "";
          this.colorList[i].quantity_shrigkage = "";
        } else {
          if (x.quantity) {
            color.quantity_shrigkage = +this.decimalCacul(
              this.shrigkageCacul(x.quantity, this.manufacture.shrigkage),
            );
            this.colorList[i].quantity_shrigkage = this.usingComma(
              color.quantity_shrigkage,
            );
          }
        }
        return color;
      });
      this.$store.commit("setProductionColor", colorList);
    },

    setProcessingQuantity() {
      const colorList = this.saveColorList.map((x, i) => {
        const color = x;
        if (color.quantity_shrigkage) {
          color.quantity = this.processingQuantity(
            color.quantity_shrigkage,
            this.manufacture.shrigkage ? this.manufacture.shrigkage : 0,
          );
          this.colorList[i].quantity = this.usingComma(color.quantity);
        }
        return color;
      });
      this.$store.commit("setProductionColor", colorList);
      this.colroTotalQuantityCacul();
    },
    colorRowPluse() {
      this.saveColorList.push({});
    },

    commaColorCheck(kind, color, index) {
      const commaColor = this.colorList;
      const num = this.usingComma(color);
      if (kind === "quantity") commaColor[index].quantity = num;
      else commaColor[index].quantity_sample = num;
    },

    //styleSelectChange() -> styleSelect 값을 변경 하는 함수
    styleSelectChange() {
      this.styleItem = null;
      this.styleSelect = !this.styleSelect;
    },

    colorSurchargeCheck(type, i, data) {
      const saveNum = data ? this.parsingNum(data) : "";
      const num = this.usingComma(saveNum);
      const color = this.colorList[i];
      const saveColor = this.saveColorList[i];

      if (type === "price") {
        color.color_surcharge_price = num;
        saveColor.color_surcharge_price = saveNum;
      }
      if (type === "unit") {
        color.color_surcharge_unit = num;
        saveColor.color_surcharge_unit = saveNum;
      }

      this.$set(this.saveColorList, i, saveColor);
    },

    weavingSurcharge(type, data) {
      const num = this.usingComma(data);
      if (type === "price") this.manufacture.sucharge_price = num;
      if (type === "unit") this.manufacture.surcharge_unit = num;
    },

    unitSignChange() {
      // let colorTotal = 0;

      if (
        this.manufacture.unit_sign !== "g" &&
        this.manufacture.unit_sign !== "kg"
      )
        this.manufacture.weight_unit_sign = this.manufacture.unit_sign;

      // this.saveColorList.map(x => {
      //   return {
      //     ...x,
      //     quantity: '',
      //     quantity: ''
      //   }
      // })

      // if (
      //   this.manufacture.quantity_by_color &&
      //   this.manufacture.quantity_by_color !== ""
      // ) {
      //   const num = this.unitChange(
      //     this.manufacture.unit_sign,
      //     this.parsingNum(this.copyData(this.manufacture.quantity_by_color)),
      //   );
      //   this.manufacture.quantity_by_color = this.usingComma(num);
      // }

      // this.colorList.forEach((x, i) => {
      //   if (x.quantity && x.quantity !== "") {
      //     const num = this.unitChange(
      //       this.manufacture.unit_sign,
      //       this.parsingNum(x.quantity),
      //     );
      //     colorTotal += +num;
      //     this.saveColorList[i].quantity = num;
      //     x.quantity = this.usingComma(num);
      //   }
      //   if (x.quantity_sample && x.quantity_sample !== "") {
      //     const num = this.unitChange(
      //       this.manufacture.unit_sign,
      //       this.parsingNum(x.quantity_sample),
      //     );
      //     colorTotal += +num;
      //     this.saveColorList[i].quantity_sample = num;
      //     x.quantity_sample = this.usingComma(num);
      //   }
      // });
      // this.manufacture.total_quantity_sum = this.decimalCacul(this.saveColorList);

      // this.shrigkageInput();
    },

    colroTotalQuantityCacul() {
      const total = this.decimalCacul(
        this.saveColorList
          .filter((x) => x.quantity || x.quantity_sample)
          .reduce(
            (r, c) =>
              r +
              (c.quantity_sample
                ? +c.quantity
                  ? +c.quantity + +c.quantity_sample
                  : +c.quantity_sample
                : +c.quantity),
            0,
          ),
      );
      this.manufacture.total_quantity_sum = total;
    },

    //폭 입력 기능
    inputWidth(width) {
      if (!width) return;
      if (
        this.manufacture.full_width &&
        +this.manufacture.full_width < +width
      ) {
        this.manufacture.width = "";
        this.snackbarOn("폭값이 전체폭값을 넘길수 없습니다.");
      } else this.manufacture.width = width;
    },
    inputPullWidth(fullWidth) {
      if (!fullWidth) return;
      if (this.manufacture.width && +fullWidth < +this.manufacture.width) {
        this.manufacture.width = "";
        this.snackbarOn("폭값이 전체폭값을 넘길수 없습니다.");
      }
    },

    setPreset(preset) {
      this.$store.dispatch("MANUFACTURE_PRESET", preset);
    },

    // 컬러의 길이를 무게로 또는 무게를 길이로 변환 하여 반환 하는 함수
    weightToLength(value) {
      return this.weightCacul(
        value,
        this.manufacture.unit_sign,
        this.manufacture.weight_convert_unit,
        this.manufacture.weight_sign,
      );
    },

    // 입력 index 와 color 종류 에 따라 수량을 무게로 바꾸는 함수
    quantityToWeight(index, kind) {
      const value = this.saveColorList[index][kind];
      return this.usingComma(this.weightToLength(value));
    },

    //////////////////////////////////////////////////////////////////////////////////////////////

    //////////////////////////////////////// 가공의뢰서 기타 기능 /////////////////////////////////////

    //4 beforVersion function

    snackbarOn(text) {
      this.$store.commit("setSnackBar", text);
    },

    checkNewSettingForm(e, kind, refs) {
      if (e.key !== "Enter") return;

      this.$store.commit("setEditedItemForm");

      const editedItem = {};
      editedItem.doc = "manufacture";
      const input =
        typeof e.target.value === "string" && e.target.value !== null
          ? e.target.value.trim().toLowerCase()
          : e.target.value.name.trim().toLowerCase();
      if (kind === "place_manufacture") {
        if (!this.clientList.some((x) => x.name.toLowerCase() === input)) {
          editedItem.name_only = input;
          editedItem.target = kind;
          editedItem.type = "Client";
          this.$store.commit("setEditedItemForm", editedItem);
          this.$store.commit("setDailogStatus", {
            status: true,
            kind: "setting_form",
          });
        }
        return true;
      }
      if (kind === "manuItem") {
        if (
          !this.itemList.some((x) => x.production_item.toLowerCase() === input)
        ) {
          editedItem.unit_price_sign = "₩";
          editedItem.unit_length = "y";
          editedItem.unit_width = "in";
          editedItem.unit_weight = "g/y";
          editedItem.inspect_status = true;
          editedItem.production_item = input;
          editedItem.target = kind;
          editedItem.type = "Item";
          this.$store.commit("setEditedItemForm", editedItem);
          this.$store.commit("setDailogStatus", {
            status: true,
            kind: "setting_form",
          });
          return true;
        }
      }
      if (kind === "inspection") {
        if (!this.clientList.some((x) => x.name.toLowerCase() === input)) {
          editedItem.name_only = input;
          editedItem.target = kind;
          editedItem.type = "Client";
          this.$store.commit("setEditedItemForm", editedItem);
          this.$store.commit("setDailogStatus", {
            status: true,
            kind: "setting_form",
          });
          return true;
        }
      }
    },

    // 연필버튼 동작
    openEditDialog(item, target, type) {
      this.$store.commit("setEditedItemForm");
      this.editedItem = { ...item };
      this.editedItem.type = type;
      if (type === "Item")
        this.editedItem.production = this.editedItem.production
          ? this.clientList.find(
              (x) => x._id === this.editedItem.production._id,
            )
          : "";
      this.editedItem.company = this.$store.state.auth_company;
      this.editedItem.business_num
        ? (this.editedItem.business_num = this.getBusinessNumMask(
            this.editedItem.business_num,
          ))
        : "";
      this.editedItem.unit_price = this.editedItem.unit_price
        ? this.usingComma(this.editedItem.unit_price)
        : "";
      this.editedItem.tel
        ? (this.editedItem.tel = this.getPhoneMask(this.editedItem.tel))
        : "";

      this.$store.commit("setEditedItemForm", this.editedItem);
      this.$store.commit("setDailogStatus", {
        status: true,
        kind: "setting_form",
      });
    },

    //생지 가공의뢰서 기능
    fabricBtnClick() {
      if (this.manufacture.fabricBtn) {
        this.manufacture.kind = 18;
      } else {
        this.manufacture.kind = 7;
      }
    },
    setManufactureReference(data) {
      this.$store.dispatch("SET_MANUFACTURE_REFERENCE", data);
    },
  },
};
