<template>
  <div>
    <div class="settingFormLayout pb-4">
      <div class="label border-bottom">아이템 구분</div>
      <div class="mergeColumn border-bottom d-flex">
        <div class="itemTypeTabs pl-4">
          <div
            class="itemTypeTab"
            :class="{ 'active-itemTypeTab': editedItem.item_type === 1 }"
            @click="itemTypeChange(1)"
            :value="editedItem.item_type"
          >
            완제품
          </div>
          <div
            class="itemTypeTab"
            :class="{ 'active-itemTypeTab': editedItem.item_type === 2 }"
            @click="itemTypeChange(2)"
            :value="editedItem.item_type"
          >
            생지
          </div>
        </div>
      </div>
    </div>
    <!-- <template v-if="activeTab === 'complete'"> -->
    <div class="settingFormLayout border-bottom">
      <div class="label">아이템명</div>
      <div class="input">
        <customTextField
          :clearable="true"
          :height="'30px'"
          v-model="editedItem.name"
          :disabled="$store.state.editedItem._id ? true : false"
        >
          <template v-slot:suffix>
            <span class="textNowrap">{{ inspectStatusText }}</span>
            <v-checkbox
              v-model="editedItem.inspect_status"
              item-text="label"
              hide-details
              :ripple="false"
              class="pt-0"
              @change="StatusCheck()"
            ></v-checkbox>
          </template>
        </customTextField>
      </div>
      <div class="label">가격</div>

      <div class="input">
        <customTextField
          :clearable="true"
          :height="'30px'"
          v-model="editedItem.unit_price"
          :numberFormat="true"
        >
          <template v-slot:prefix>
            <customSelectField
              v-model="editedItem.currency_sign"
              :items="$store.state.currency_sign"
              :height="'30px'"
              width="60px"
            />
          </template>
        </customTextField>
      </div>

      <div class="label">생산처</div>
      <div>
        <div class="input">
          <customAutoComplete
            v-model="editedItem.production"
            :items="clientList"
            :height="'30px'"
            :itemText="'name'"
            :itemValue="'_id'"
            :noResultsMessage="'검색 결과가 없습니다.'"
          />
        </div>
      </div>
      <div class="label">생산처 ITEM NO</div>
      <div>
        <div class="input">
          <customTextField
            v-model="editedItem.production_item"
            :height="'30px'"
          />
        </div>
      </div>
      <div class="label">경사</div>
      <div>
        <div class="input">
          <customTextField
            v-model="editedItem.slope"
            :clearable="true"
            :height="'30px'"
          />
        </div>
      </div>
      <div class="label">위사</div>
      <div>
        <div class="input">
          <customTextField
            v-model="editedItem.oblique"
            :clearable="true"
            :height="'30px'"
          />
        </div>
      </div>
      <div class="label">혼용률</div>
      <div>
        <div class="input">
          <customTextField
            v-model="editedItem.variety"
            :clearable="true"
            :height="'30px'"
          />
        </div>
      </div>
      <div class="label">가용 폭/전체 폭</div>
      <div>
        <div class="input">
          <customTextField
            v-model="editedItem.width"
            :clearable="true"
            :height="'30px'"
            width="100px"
            :change="itemWidthCheck()"
          >
          </customTextField>
          &nbsp;/&nbsp;
          <customTextField
            v-model="editedItem.full_width"
            :clearable="true"
            :height="'30px'"
            width="100px"
            :change="itemWidthCheck()"
          >
          </customTextField>

          <customSelectField
            v-model="editedItem.unit_width"
            :items="$store.state.unit_width"
            :height="'30px'"
            width="60px"
          />
        </div>
      </div>
      <div class="label">밀도</div>
      <div>
        <div class="input">
          <customTextField
            v-model="editedItem.density"
            :clearable="true"
            :height="'30px'"
          />
        </div>
      </div>
      <div class="label">중량</div>
      <div>
        <div class="input">
          <customTextField
            v-model="editedItem.weight"
            :clearable="true"
            :height="'30px'"
          >
            <template v-slot:suffix>
              <customSelectField
                v-model="editedItem.unit_weight"
                :items="$store.state.unit_weight"
                :height="'30px'"
                width="80px"
              />
            </template>
          </customTextField>
        </div>
      </div>
      <div class="label">Finish</div>
      <div>
        <div class="input">
          <customTextField
            v-model="editedItem.post_processing"
            :clearable="true"
            :height="'30px'"
          />
        </div>
      </div>
      <div class="label">단위(길이)</div>
      <div>
        <div class="input">
          <customSelectField
            v-model="editedItem.unit_length"
            :items="$store.state.unit_sign"
            :height="'30px'"
          />
        </div>
      </div>
      <div class="label">절단</div>
      <div>
        <div class="input">
          <customTextField
            v-model="editedItem.cut_length"
            :clearable="true"
            :height="'30px'"
            :numberFormat="true"
          >
            <template v-slot:suffix>
              <customSelectField
                v-model="editedItem.cut_length_unit"
                :items="$store.state.unit_sign"
                :height="'30px'"
                :width="'40px'"
              />
            </template>
          </customTextField>
        </div>
      </div>
      <div class="label">Gauge</div>
      <div>
        <div class="input">
          <customTextField
            :clearable="true"
            :height="'30px'"
            v-model="editedItem.gague"
            :numberFormat="true"
          />
        </div>
      </div>
      <div class="label">특이사항</div>
      <div>
        <div class="input">
          <customTextField
            v-model="editedItem.content"
            :clearable="true"
            :height="'30px'"
          />
        </div>
      </div>
      <div class="label">담당자</div>
      <div>
        <div class="input">
          <customTextField
            v-model="editedItem.liaison"
            :clearable="true"
            :height="'30px'"
          />
        </div>
      </div>
    </div>
    <!-- </template> -->
    <!-- <template v-if="activeTab === 'raw'">
      <div class="settingFormLayout">
        <div class="label">아이템명</div>
        <div class="input">
          <customTextField
            :clearable="true"
            :height="'30px'"
            v-model="editedItem.name"
            :disabled="$store.state.editedItem._id ? true : false"
          >
          </customTextField>
        </div>
        <div class="label">거래처</div>
        <div>
          <div class="input">
            <customAutoComplete
              :value="editedItem.production"
              :items="clientList"
              :height="'30px'"
              :itemText="'name'"
              :itemValue="'_id'"
              :noResultsMessage="'검색 결과가 없습니다.'"
            />
          </div>
        </div>
        <div class="label">거래처 ITEM NO</div>
        <div>
          <div class="input">
            <customTextField
              :clearable="true"
              :height="'30px'"
              v-model="editedItem.production_item"
            />
          </div>
        </div>
        <div class="label">Gauge</div>
        <div>
          <div class="input">
            <customTextField
              :clearable="true"
              :height="'30px'"
              v-model="editedItem.gague"
              :numberFormat="true"
            />
          </div>
        </div>

        <div class="label">일일생산량</div>
        <div>
          <div class="input">
            <customTextField
              :clearable="true"
              :height="'30px'"
              v-model="editedItem.daily_production"
              :numberFormat="true"
            />
          </div>
        </div>
        <div class="label">생산 rpm</div>
        <div>
          <div class="input">
            <customTextField
              :clearable="true"
              :height="'30px'"
              v-model="editedItem.rpm"
              :numberFormat="true"
            />
          </div>
        </div>
      </div>
      <div class="subTitle">원사</div>
      <div class="yarnArea">
        <div class="header">
          <div class="headerText">사종</div>
          <div class="headerText">Bar</div>
          <div class="headerText">함량</div>
          <div class="headerText">
            <div @click="addYarnRow" class="addButton">
              <v-icon> mdi-plus </v-icon>
            </div>
          </div>
        </div>
        <overlay-scrollbars
          :options="{
            overflowBehavior: {
              x: 'hidden',
            },
          }"
          ref="scrollBar"
          defer
        >
          <div class="yarnGrid">
            <div v-for="item in yarnItems" :key="item._id" class="yarnItem">
              <div class="yarnItemText">
                <customTextField
                  :clearable="true"
                  :height="'30px'"
                  v-model="item.yarn_type"
                >
                </customTextField>
              </div>
              <div class="yarnItemText">
                <customTextField
                  :clearable="true"
                  :height="'30px'"
                  v-model="item.bar"
                >
                </customTextField>
              </div>
              <div class="yarnItemText">
                <customTextField
                  :clearable="true"
                  :height="'30px'"
                  v-model="item.percentage"
                >
                </customTextField>
              </div>
              <div class="yarnItemText"></div>
            </div>
          </div>
        </overlay-scrollbars>
      </div>
      <div class="subTitle">가공</div>
      <div class="manufacutreArea">
        <div class="header">
          <div class="headerText">구분</div>
          <div class="headerText">
            웨일수
            <customSelectField
              v-model="editedItem.unit_width"
              :items="$store.state.unit_width"
              :height="'30px'"
              :width="'60px'"
            />
          </div>
          <div class="headerText">
            밀도
            <customSelectField
              v-model="editedItem.unit_width"
              :items="$store.state.unit_width"
              :height="'30px'"
              :width="'60px'"
            />
          </div>
          <div class="headerText">
            폭
            <customSelectField
              v-model="editedItem.unit_width"
              :items="$store.state.unit_width"
              :height="'30px'"
              :width="'60px'"
            />
          </div>
          <div class="headerText">
            중량
            <customSelectField
              v-model="editedItem.unit_weight"
              :items="$store.state.unit_weight"
              :height="'30px'"
              :width="'60px'"
            />
          </div>
          <div class="headerText">
            절단
            <customSelectField
              v-model="editedItem.unit_length"
              :items="$store.state.unit_sign"
              :height="'30px'"
              :width="'40px'"
            />
          </div>
          <div class="headerText">정단 중량(kg)</div>
        </div>
        <div class="manuItem">
          <div class="manuItemText">
            <span class="typeText">생지 조건</span>
          </div>
          <div class="manuItemText">
            <customTextField
              :clearable="true"
              :height="'30px'"
              v-model="editedItem.raw_wale"
            >
            </customTextField>
          </div>
          <div class="manuItemText">
            <customTextField
              :clearable="true"
              :height="'30px'"
              v-model="editedItem.raw_density"
            >
            </customTextField>
          </div>
          <div class="manuItemText">
            <customTextField
              :clearable="true"
              :height="'30px'"
              v-model="editedItem.raw_width"
            >
            </customTextField>
          </div>
          <div class="manuItemText">
            <customTextField
              :clearable="true"
              :height="'30px'"
              v-model="editedItem.raw_weight"
            >
            </customTextField>
          </div>
          <div class="manuItemText">
            <customTextField
              :clearable="true"
              :height="'30px'"
              v-model="editedItem.raw_cut"
            >
            </customTextField>
          </div>
          <div class="manuItemText">
            <customTextField
              :clearable="true"
              :height="'30px'"
              v-model="editedItem.raw_cut_weight"
            >
            </customTextField>
          </div>
        </div>
        <div class="manuItem">
          <div class="manuItemText">
            <span class="typeText">가공 조건</span>
          </div>
          <div class="manuItemText">
            <customTextField
              :clearable="true"
              :height="'30px'"
              v-model="editedItem.wale"
            >
            </customTextField>
          </div>
          <div class="manuItemText">
            <customTextField
              :clearable="true"
              :height="'30px'"
              v-model="editedItem.density"
            >
            </customTextField>
          </div>
          <div class="manuItemText">
            <customTextField
              :clearable="true"
              :height="'30px'"
              v-model="editedItem.width"
            >
            </customTextField>
          </div>
          <div class="manuItemText">
            <customTextField
              :clearable="true"
              :height="'30px'"
              v-model="editedItem.weight"
            >
            </customTextField>
          </div>
          <div class="manuItemText">
            <customTextField
              :clearable="true"
              :height="'30px'"
              v-model="editedItem.cut"
            >
            </customTextField>
          </div>
          <div class="manuItemText">
            <customTextField
              :clearable="true"
              :height="'30px'"
              v-model="editedItem.cut_weight"
            >
            </customTextField>
          </div>
        </div>
      </div>
    </template> -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import customTextField from "@/components/atoms/TextField/customTextField";
import customSelectField from "@/components/atoms/Select/customSelectField";
import customAutoComplete from "@/components/atoms/Autocomplete/customAutoComplete";
export default {
  components: {
    customTextField,
    customSelectField,
    customAutoComplete,
  },
  computed: {
    ...mapGetters({
      editedItem: "getEditedItem",
      clientList: "getUseClientList",
    }),
    yarnItems() {
      return Array.from({ length: 4 }, () => ({
        yarn_type: "",
        bar: "",
        percentage: "",
      }));
      // this.editedItem.yarn;
    },
  },
  data() {
    return {
      inspectStatusText: "검사포함",
    };
  },
  methods: {
    itemTypeChange(type) {
      this.editedItem.item_type = type;
      this.$forceUpdate();
    },
    itemWidthCheck() {
      if (
        this.editedItem.width !== "" &&
        this.editedItem.full_width !== "" &&
        +this.editedItem.width > +this.editedItem.full_width
      ) {
        this.$store.commit(
          "setSnackBar",
          `가용 폭은 전체 폭을 초과할 수 없습니다.`,
        );
        this.editedItem.width = "";
      }
    },
    StatusCheck() {
      this.editedItem.inspect_status
        ? (this.inspectStatusText = "검사포함")
        : (this.inspectStatusText = "검사미포함");
    },
    addYarnRow() {
      this.editedItem.yarn.push({
        type: "",
        bar: "",
        content: "",
      });
    },
    created() {},
  },
};
</script>
