<template>
  <div class="areaRight">
    <RightTopBtn :fabricType="fabricType" />
    <template v-if="!fabricType">
      <ManufactureForm v-if="$store.state.manufactureForm.dialog" />
      <ReleaseForm v-else-if="$store.state.releaseForm.dialog" />
      <AppearanceForm v-else-if="$store.state.appearanceForm.dialog" />
      <div
        v-else
        style="
          text-align: center;
          padding-top: 41vmin;
          color: #c0c0c0;
          font-size: larger;
          font-weight: bolder;
        "
      >
        문서를 선택해 주세요
      </div>
    </template>
    <template v-if="fabricType">
      <FabricForm v-if="$store.state.fabricForm.dialog" />
      <ShrinkageForm v-if="$store.state.shrinkageForm.dialog" />
    </template>
  </div>
</template>
<script>
import RightTopBtn from "@/components/pages/production/docForm/RightTopBtn.vue";
import ManufactureForm from "@/components/pages/production/docForm/manufacture/ManufactureForm.vue";
import ReleaseForm from "@/components/pages/production/docForm/release/ReleaseForm.vue";
import AppearanceForm from "@/components/pages/production/docForm/appearance/AppearanceForm.vue";

import FabricForm from "@/components/pages/production/docForm/fabricForm/FabricForm.vue";
import ShrinkageForm from "@/components/pages/production/docForm/shrinkageForm/ShrinkageForm.vue";

export default {
  components: {
    RightTopBtn,
    ManufactureForm,
    ReleaseForm,
    AppearanceForm,
    // FabricDocTopBtn,
    FabricForm,
    ShrinkageForm,
  },

  computed: {
    fabricType() {
      return (
        this.$store.state.productionProgress.kind === 18 ||
        this.$store.state.productionProgress.kind === 19
      );
    },
  },
};
</script>
