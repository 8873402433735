<template>
  <div class="text-bold releaseGridBorderLeft">{{ title }}</div>
</template>
<script>
export default {
  props: {
    title: String,
  },
};
</script>
