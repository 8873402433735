<template>
  <div class="areaRightTop">
    <div class="areaRightTopLeft">{{ docTitle }}</div>
    <div class="areaRightTopRight">
      <ProductionBtn
        @onClick="openSaveConfirm(1)"
        :title="saveBtnTitle"
        :disabled="isDisabled"
      />
      <ProductionBtn
        @onClick="openSaveConfirm(2)"
        :title="'삭 제'"
        :disabled="isDisabled"
      />
    </div>
    <FabricConfirmDialog
      :dialog="isSaveConfirm"
      :data="confirmData"
      @save="classificationSave"
      @close="closeSaveConfirm"
    />
  </div>
</template>
<script>
import ProductionBtn from "@/components/atoms/Buttons/ProductionBtn.vue";
import FabricConfirmDialog from "@/components/pages/production/docForm/common/FabricConfirmDialog";
import { fabricDocSave, fabricDocDelete } from "@/api/production";

export default {
  components: {
    ProductionBtn,
    FabricConfirmDialog,
  },

  data() {
    return {
      isSaveConfirm: false,
      confirmData: {},
      saveType: 1, // 1번 저장, 2번 삭제
    };
  },

  computed: {
    progress() {
      return this.$store.state.productionProgress;
    },

    order() {
      return this.$store.state.productionOrderForm.order;
    },

    company() {
      return this.$store.state.auth_company;
    },

    saveBtnTitle() {
      return this.progress.status === "70" ? "저 장" : "수 정";
    },

    docTitle() {
      let title = "";
      if (this.progress.kind === 19) title = "생지 의뢰서";
      if (this.progress.kind === 18) title = "가공의뢰서 (축률)";
      return title;
    },

    fabricForm() {
      return this.$store.state.fabricForm;
    },

    shrinkageForm() {
      return this.$store.state.shrinkageForm;
    },

    isDisabled() {
      return this.progress.status === "99";
    },

    saveForm() {
      const form = {
        order: this.order,
        company: this.company,
      };

      form.saveDoc =
        this.progress.kind === 19
          ? this.$store.state.fabricForm
          : this.$store.state.shrinkageForm;

      return form;
    },

    btnTitle() {
      return this.saveType === 1 ? "저 장" : "삭 제";
    },
  },

  methods: {
    // 승인요청창 출력 데이터 set
    setConfirmData() {
      const data =
        this.progress.kind === 19 ? this.fabricForm : this.shrinkageForm;

      const form = {
        title: this.docTitle,
        kind: this.progress.kind,
        btnTitle: this.btnTitle,
      };

      if (this.progress.kind === 19) {
        form.client =
          data.place_forward && data.place_forward._id
            ? data.place_forward.name
            : data.place_forward;

        form.date = data.date_delivery;
      }

      if (this.progress.kind === 18) {
        form.client =
          data.place_manufacture && data.place_manufacture._id
            ? data.place_manufacture.name
            : data.place_manufacture;

        form.date = data.date_payment;
      }

      this.confirmData = form;
    },

    // 승인요청창 출력 여부 변환
    clickSaveConfirm() {
      this.isSaveConfirm = !this.isSaveConfirm;
    },

    // fabric 데이터 체크
    fabircInputCheck() {
      const fabirc = this.fabricForm;
      if (!fabirc.isFabricStock && !fabirc.purchaseUnitPrice)
        throw "매입 단가를 입력해 주세요";
      if (!fabirc.color_list[0].quantity) throw "출고 수량을 입력해 주세요";
    },

    // 가공의뢰서 (축률) 데이터 체크
    shrinkageInputCheck() {
      const shrinkage = this.shrinkageForm;

      if (!shrinkage.unit_price) throw "매입 단가를 입력해 주세요";
      if (shrinkage.unit_sign === "kg" && !shrinkage.weight_convert_unit)
        throw "중량 단위 를 입력해 주세요";
      if (shrinkage.color_list.filter((x) => x.name && !x.quantity).length)
        throw "컬러 수량을 입력해 주세요";
      if (shrinkage.color_list.filter((x) => !x.name && x.quantity).length)
        throw "컬러명을 입력해 주세요";
      if (shrinkage.color_list.reduce((r, c) => +r + +c.quantity, 0) === 0)
        throw "가공 수량을 입력해 주세요";
    },

    saveInputCheck() {
      if (this.progress.kind === 19) this.fabircInputCheck();
      if (this.progress.kind === 18) this.shrinkageInputCheck();
    },

    // 승인요청 OR 저장 버튼 클릭
    openSaveConfirm(type) {
      try {
        this.saveType = type;

        if (this.saveType === 1) this.saveInputCheck();

        this.setConfirmData();
        this.clickSaveConfirm();
      } catch (e) {
        this.snackbarOn(e);
      }
    },

    // 승인요청창 닫기
    closeSaveConfirm() {
      this.confirmData = {};
      this.clickSaveConfirm();
    },

    // 컨펌 클릭시 saveType 에따라 저장 함수 실행
    async classificationSave() {
      if (this.saveType === 1) await this.saveDoc();
      if (this.saveType === 2) await this.docDelete();
      this.closeSaveConfirm();
      await this.$store.dispatch("PRODUCTION_SAVE_END");
    },

    async saveDoc() {
      try {
        await fabricDocSave(this.saveForm);
      } catch (e) {
        console.log(e);
      }
    },

    async docDelete() {
      try {
        await fabricDocDelete(this.saveForm);
      } catch (e) {
        console.log(e);
      }
    },

    snackbarOn(text) {
      this.$store.commit("setSnackBar", text);
    },
  },
};
</script>
