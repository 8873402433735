<template>
  <div class="comapnyForm pt-4">
    <div class="settingGreyCard">
      <div class="company">
        <div class="buttonRows pb-4">
          <div class="titleText">
            <span> 기본 설정 </span>
          </div>
          <div class="companyButtonArea">
            <v-btn
              color="#2c98f0"
              @click="modifyUserSetting"
              width="80"
              height="30"
            >
              <span class="whiteText">저장</span>
            </v-btn>
          </div>
        </div>
        <div class="companyField">
          <v-select
            v-model="user.fontSizeType"
            :items="fontSizeList"
            class="pb-0 mb-0"
            dense
            item-text="label"
            item-value="value"
            return-object
            label="글자크기 선택"
            @change="user.fontSizeType = $event.value"
            :disabled="$store.state.auth_grade < 5"
          />
        </div>
        <div class="companyField selectDiv">
          <v-select
            v-model="user.progress_type"
            :items="progressTypeList"
            class="pb-0 mb-0"
            dense
            item-text="label"
            item-value="value"
            return-object
            label="프로그래스 타입"
            @change="user.progress_type = $event.value"
            :disabled="$store.state.auth_grade < 5"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { putUser } from "@/api/user";
export default {
  computed: {
    ...mapGetters({
      user: "getUserInfo",
    }),
  },
  data() {
    return {
      saveLogoImg: null,
      preview: "",

      fontSizeList: [
        {
          value: 1,
          label: "작게(11px)",
        },
        {
          value: 2,
          label: "보통(12px)",
        },
        {
          value: 3,
          label: "크게(13px)",
        },
      ],

      progressTypeList: [
        {
          value: 1,
          label: "카드 형",
        },
        {
          value: 2,
          label: "리스트 형",
        },
      ],
    };
  },
  methods: {
    async modifyUserSetting() {
      try {
        const result = await putUser({
          ...this.user,
        });

        if (result.status !== 200) throw `error: ${result.status}`;

        await this.$store.dispatch("GET_FIND_USER_INFO");
        this.$store.commit("setSnackBar", `설정이 저장되었습니다.`);
        const fontSize =
          this.user.fontSizeType === 1
            ? "var(--body-font-size-small)"
            : this.user.fontSizeType === 2
            ? "var(--body-font-size-medium)"
            : this.user.fontSizeType === 3
            ? "var(--body-font-size-large)"
            : "var(--body-font-size-small)";

        this.$store.state.auth_progress_type = this.user.progress_type;
        document.documentElement.style.setProperty(
          "--body-font-size",
          fontSize,
        );
      } catch (e) {
        console.log(e);
      }
    },
  },

  created() {
    // 만약 프로그래스 기본 설정이 없는 경우 카드형으로 출력 한다.
    if (!this.user.progress_type) this.user.progress_type = 1;
  },
};
</script>

<style scoped>
.selectDiv {
  margin-top: 10px;
}
</style>
