import { mapGetters } from "vuex";
import manuColorHeaders from "@/assets/table/production/manuColorHeaders";
// import ManufactureConfirmDialog from "@/components/pages/production/docForm/manufacture/ManufactureConfirmDialog";
import pritnerFormRightHeader from "@/assets/table/print/pritnerFormRightHeader";
import settingFormDialog from "@/components/organisms/VDialog/SettingFormDialog";
import ClientComboBox from "@/components/atoms/VComboBox/DenseDialogClientComboBox";
import DocForm from "@/components/organisms/docDataForm/docForm.vue";
import DenseSubItemComboBox from "@/components/atoms/VComboBox/DenseSubItemComboBox.vue";

import TitleForm from "@/components/atoms/InputForm/TitleForm";

export default {
  components: {
    // ManufactureConfirmDialog,
    ClientComboBox,
    settingFormDialog,
    DocForm,
    DenseSubItemComboBox,
    TitleForm,
  },

  data() {
    return {
      manuColorHeaders,
      pritnerFormRightHeader,
      menu_date_manufacture: false,
      menu_date_payment_manufacture: false,
      styleSelect: true,
      styleItem: "",
      dialog_manufacture_print: false,
      selectManufactures: {
        order: {},
        item: {},
        place_manufacture: {},
        liaison: {},
      },

      post_processing: "",
    };
  },
  computed: {
    ...mapGetters({
      form: "getProductionForm",
      shrinkageForm: "getShrinkageForm",
      itemList: "getItemList",
      clientList: "getUseClientList",
      attachmentList: "getAttachmentList",

      beforVersion: "getBeforProductionData",
      loginUser: "getLoginUser",
      isDocDataBtn: "getIsDocDataBtn",
      docDataList: "getDoctDataList",
    }),
    purchaseCompanyList() {
      return this.clientList;
    },
    inspectionList() {
      return this.clientList;
    },

    bakDialog() {
      return this.shrinkageForm.history
        ? this.shrinkageForm.history.length > 0
          ? true
          : false
        : false;
    },

    colorList() {
      let colorList = this.copyData(this.shrinkageForm.color_list).map((x) => {
        x.quantity = this.usingComma(x.quantity);
        x.quantity_shrigkage = this.usingComma(x.quantity_shrigkage);
        x.quantity_sample = this.usingComma(x.quantity_sample);
        x.color_surcharge_unit = this.usingComma(x.color_surcharge_unit);
        x.color_surcharge_price = this.usingComma(x.color_surcharge_price);
        return x;
      });

      return colorList;
    },

    // 가공의뢰서 헤더
    formTitleManufacture() {
      return !this.shrinkageForm.id
        ? "신규 가공의뢰서"
        : this.shrinkageForm.status === "00"
        ? "수정전 가공의뢰서"
        : this.shrinkageForm.status === "10"
        ? "취소 요청 가공의뢰서"
        : this.shrinkageForm.status === "20"
        ? "승인 요청 가공의뢰서"
        : this.shrinkageForm.status === "30"
        ? "반려 가공의뢰서"
        : this.shrinkageForm.status === "40"
        ? "가공의뢰서 수정"
        : this.shrinkageForm.status === "50"
        ? "수정 승인 요청 가공의뢰서"
        : "가공의뢰서";
    },

    // 가공의뢰서 disabled
    formManufactureDisabled() {
      return this.shrinkageForm.isModify;
      //  this.loginUser.grade < 2 && this.shrinkageForm.status === "20"
      //   ? this.shrinkageForm.isModify
      //   : !this.shrinkageForm.isModify;
    },

    releaseListCheck() {
      return (
        this.releaseList.filter(
          (x) => x.manufacture === this.shrinkageForm._id && x.status !== "99",
        ).length !== 0 && this.shrinkageForm._id
      );
    },

    priceLabel() {
      return this.shrinkageForm.currency_sign !== "₩"
        ? "단 가 / 환 율"
        : "단 가";
    },

    width() {
      return this.shrinkageForm.width ? this.shrinkageForm.width : "";
    },

    currencyLable() {
      return this.shrinkageForm.currency_sign === "₩"
        ? !this.isDocDataBtn
          ? "매입단가"
          : "단가"
        : !this.isDocDataBtn
        ? "매입단가/환율"
        : "단가환율";
    },

    total_quantity_sum() {
      const total = this.decimalCacul(
        this.shrinkageForm.color_list
          .filter((x) => x.quantity || x.quantity_sample)
          .map((x) => {
            return {
              quantity_sample: this.parsingNum(x.quantity_sample),
              quantity: this.parsingNum(x.quantity),
            };
          })
          .reduce(
            (r, c) =>
              r +
              (c.quantity_sample
                ? +c.quantity
                  ? +c.quantity + +c.quantity_sample
                  : +c.quantity_sample
                : +c.quantity),
            0,
          ),
      );

      return total;
    },

    shrinkageUnitSigns() {
      return [...this.$store.state.unit_sign, "kg"];
    },

    totalAmount() {
      let total_amount =
        +this.total_quantity_sum *
        this.parsingNum(this.shrinkageForm.unit_price);

      //Surcharge(제직, 단위 당 가격이 있을경우)
      if (this.shrinkageForm.sucharge_price)
        total_amount +=
          +this.total_quantity_sum *
          this.parsingNum(this.shrinkageForm.sucharge_price);

      //Surcharge(제직, 건 당 가격이 있을경우)
      if (this.shrinkageForm.surcharge_unit)
        total_amount += this.parsingNum(this.shrinkageForm.surcharge_unit);

      // color 별 surcahrge 곱
      total_amount += +this.shrinkageForm.color_list.reduce((r, c) => {
        if (c.color_surcharge_unit)
          r += this.parsingNum(c.color_surcharge_unit) * +c.quantity;
        if (c.color_surcharge_price)
          r += this.parsingNum(c.color_surcharge_price);
        return r;
      }, 0);

      return this.roundsData(total_amount);
    },

    averageCalculator() {
      let average = "";
      if (this.total_quantity_sum) {
        const total_amount = this.totalAmount;

        average = this.usingComma(
          this.decimalCacul(total_amount / this.total_quantity_sum),
        );

        if (this.shrinkageForm.eschamge) {
          let eschamge = this.decimalCacul(
            (total_amount * this.parsingNum(this.shrinkageForm.eschamge)) /
              this.total_quantity_sum,
          );

          average = `${average} (₩ ${this.usingComma(eschamge)})`;
        }
      }

      return average;
    },

    colorConvertSign() {
      return this.shrinkageForm.unit_sign === "kg"
        ? this.shrinkageForm.weight_unit_sign
        : "kg";
    },
  },
  methods: {
    //////////////////////////////////////// 가공의뢰서 입력 기능 /////////////////////////////////////
    commitShringkage() {
      this.$store.commit("setShrinkageForm", this.shrinkageForm);
    },

    // 컬러의 길이를 무게로 또는 무게를 길이로 변환 하여 반환 하는 함수
    weightToLength(value) {
      return this.weightCacul(
        value,
        this.shrinkageForm.unit_sign,
        this.shrinkageForm.weight_convert_unit,
        this.shrinkageForm.weight_sign,
      );
    },

    // 입력 index 와 color 종류 에 따라 수량을 무게로 바꾸는 함수
    quantityToWeight(index, kind) {
      const value = this.parsingNum(this.colorList[index][kind]);
      return this.usingComma(this.weightToLength(value));
    },

    keyborderItemChange(item, refs) {
      if (item.key !== "Enter") return;

      this.checkNewSettingForm(item, "manuItem", refs);

      this.setItemWidth();
    },

    itemChange(item) {
      this.shrinkageForm.item = this.copyData(item);
      this.setItemWidth();
    },

    setItemWidth() {
      this.shrinkageForm.width = this.shrinkageForm.item.width
        ? this.shrinkageForm.item.width
        : "";
      this.shrinkageForm.full_width = this.shrinkageForm.item.full_width
        ? this.shrinkageForm.item.full_width
        : "";
      this.shrinkageForm.post_processing = this.shrinkageForm.item
        .post_processing
        ? this.shrinkageForm.item.post_processing
        : "";
    },

    styleItemsPlus() {
      const styleList = this.shrinkageForm.style_list;
      if (this.styleItem === "" || this.styleItem === null) {
        this.snackbarOn("스타일을 입력해주세요");
        return;
      }
      if (
        this.shrinkageForm.style_list.filter((x) => x === this.styleItem)
          .length > 0
      ) {
        this.snackbarOn("중복된 스타일 입니다.");
        this.styleItem = "";
        return;
      }
      styleList.push(this.styleItem);
      this.styleItem = "";
    },

    //  가공의뢰서 style List 삭제 로직
    styleItemDelete(index) {
      this.shrinkageForm.style_list.splice(index, 1);
    },

    colorInput(kind, data, index) {
      const colors = this.shrinkageForm.color_list;

      if (colors[index]) colors[index][kind] = data;
      else
        colors[index] = {
          [`${kind}`]: data,
        };

      if (kind === "quantity")
        colors[index].quantity_shrigkage = this.shrigkageCacul(
          data,
          this.shrinkageForm.shrigkage,
        );

      if (kind === "quantity_shrigkage")
        colors[index].quantity = this.processingQuantity(
          data,
          this.shrinkageForm.shrigkage,
        );

      this.commitShringkage();
    },

    shrigkageInput(shrigkage) {
      const colors = this.shrinkageForm.color_list.map((x) => {
        if (x.quantity)
          x.quantity_shrigkage = this.shrigkageCacul(x.quantity, shrigkage);

        if (!x.quantity && x.quantity_shrigkage)
          x.quantity = this.processingQuantity(x.quantity_shrigkage, shrigkage);

        return x;
      });

      this.shrinkageForm.color_list = colors;
      this.commitShringkage();
    },

    colorRowPluse() {
      this.shrinkageForm.color_list.push({});
      this.commitShringkage();
    },

    //styleSelectChange() -> styleSelect 값을 변경 하는 함수
    styleSelectChange() {
      this.styleItem = null;
      this.styleSelect = !this.styleSelect;
    },

    weavingSurcharge(type, data) {
      const num = this.usingComma(data);
      if (type === "price") this.shrinkageForm.sucharge_price = num;
      if (type === "unit") this.shrinkageForm.surcharge_unit = num;
    },

    unitSignChange() {
      if (
        this.shrinkageForm.unit_sign !== "g" &&
        this.shrinkageForm.unit_sign !== "kg"
      )
        this.shrinkageForm.weight_unit_sign = this.shrinkageForm.unit_sign;
      // const colors = this.shrinkageForm.color_list.map((x) => {
      //   x.quantity = this.unitChange(
      //     this.shrinkageForm.unit_sign,
      //     this.parsingNum(x.quantity),
      //   );

      //   x.quantity_sample = this.unitChange(
      //     this.shrinkageForm.unit_sign,
      //     this.parsingNum(x.quantity_sample),
      //   );

      //   x.quantity_shrigkage = this.unitChange(
      //     this.shrinkageForm.unit_sign,
      //     this.parsingNum(x.quantity_shrigkage),
      //   );

      //   return x;
      // });

      // this.shrinkageForm.color_list = colors;
      // this.commitShringkage();
    },

    //폭 입력 기능
    inputWidth(width) {
      if (!width) return;
      if (
        this.shrinkageForm.full_width &&
        +this.shrinkageForm.full_width < +width
      ) {
        this.shrinkageForm.width = "";
        this.snackbarOn("폭값이 전체폭값을 넘길수 없습니다.");
      } else this.shrinkageForm.width = width;
    },
    inputPullWidth(fullWidth) {
      if (!fullWidth) return;
      if (this.shrinkageForm.width && +fullWidth < +this.shrinkageForm.width) {
        this.shrinkageForm.width = "";
        this.snackbarOn("폭값이 전체폭값을 넘길수 없습니다.");
      }
    },

    setPreset(preset) {
      this.$store.dispatch("MANUFACTURE_PRESET", preset);
    },

    //////////////////////////////////////////////////////////////////////////////////////////////

    //////////////////////////////////////// 가공의뢰서 기타 기능 /////////////////////////////////////

    //4 beforVersion function

    snackbarOn(text) {
      this.$store.commit("setSnackBar", text);
    },

    checkNewSettingForm(e, kind, refs) {
      if (e.key !== "Enter") return;

      this.$store.commit("setEditedItemForm");

      const editedItem = {};
      editedItem.doc = "manufacture";
      const input =
        typeof e.target.value === "string" && e.target.value !== null
          ? e.target.value.trim().toLowerCase()
          : e.target.value.name.trim().toLowerCase();
      if (kind === "place_manufacture") {
        if (!this.clientList.some((x) => x.name.toLowerCase() === input)) {
          editedItem.name_only = input;
          editedItem.target = kind;
          editedItem.type = "Client";
          this.$store.commit("setEditedItemForm", editedItem);
          this.$store.commit("setDailogStatus", {
            status: true,
            kind: "setting_form",
          });
        }
        return true;
      }
      if (kind === "manuItem") {
        if (
          !this.itemList.some((x) => x.production_item.toLowerCase() === input)
        ) {
          editedItem.unit_price_sign = "₩";
          editedItem.unit_length = "y";
          editedItem.unit_width = "in";
          editedItem.unit_weight = "g/y";
          editedItem.inspect_status = true;
          editedItem.production_item = input;
          editedItem.target = kind;
          editedItem.type = "Item";
          this.$store.commit("setEditedItemForm", editedItem);
          this.$store.commit("setDailogStatus", {
            status: true,
            kind: "setting_form",
          });
          return true;
        }
      }
      if (kind === "inspection") {
        if (!this.clientList.some((x) => x.name.toLowerCase() === input)) {
          editedItem.name_only = input;
          editedItem.target = kind;
          editedItem.type = "Client";
          this.$store.commit("setEditedItemForm", editedItem);
          this.$store.commit("setDailogStatus", {
            status: true,
            kind: "setting_form",
          });
          return true;
        }
      }
    },

    //연필버튼 동작
    openEditDialog(item, target, type) {
      this.$store.commit("setEditedItemForm");
      this.editedItem = { ...item };

      this.editedItem.type = type;
      if (type === "Item")
        this.editedItem.production = this.editedItem.production
          ? this.clientList.find((x) => x._id === this.editedItem.production)
          : "";
      this.editedItem.company = this.$store.state.auth_company;
      this.editedItem.business_num
        ? (this.editedItem.business_num = this.getBusinessNumMask(
            this.editedItem.business_num,
          ))
        : "";
      this.editedItem.unit_price = this.editedItem.unit_price
        ? this.usingComma(this.editedItem.unit_price)
        : "";
      this.editedItem.tel
        ? (this.editedItem.tel = this.getPhoneMask(this.editedItem.tel))
        : "";
      this.$store.commit("setEditedItemForm", this.editedItem);
      this.$store.commit("setDailogStatus", {
        status: true,
        kind: "setting_form",
      });
    },

    initColorList() {
      let colorList = this.shrinkageForm.color_list;
      if (colorList.length < 14) {
        colorList = [
          ...colorList,
          ...Array.from({ length: 14 - colorList.length }, () => {
            return {
              quantity: "",
              quantity_shrigkage: "",
              quantity_sample: "",
              color_surcharge_unit: "",
              color_surcharge_price: "",
            };
          }),
        ];
        this.shrinkageForm.color_list = colorList;
      }
    },
  },
  created() {
    this.initColorList();
  },
};
