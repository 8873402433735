<template>
  <v-dialog
    :value="dialog_setting_form"
    @click:outside="$store.state.isDialog = false"
    @keydown.esc="$store.state.isDialog = false"
    max-width="900px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{
          `${editedItem._id ? "Edit" : "New"} ${editedItem.type}`
        }}</span>
        <div class="ml-auto">
          <v-icon color="red" @click="$store.state.isDialog = false">
            mdi-close
          </v-icon>
        </div>
      </v-card-title>

      <v-card-text>
        <v-container>
          <template
            v-if="editedItem.type === 'Client' || editedItem.type === 'Buyer'"
          >
            <clientForm />
          </template>
          <template v-if="editedItem.type === 'Item'">
            <itemForm />
          </template>
          <template v-if="editedItem.type === 'Ctype'">
            <ctypeForm />
          </template>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <div class="pr-4">
          <v-btn color="#1565c0" dark @click="saveSettingForm">
            <span class="buttonText"> 저장 </span>
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import clientForm from "@/components/atoms/SettingForm/new_client";
import itemForm from "@/components/atoms/SettingForm/new_item";
import ctypeForm from "@/components/atoms/SettingForm/new_ctype";
import ClienTypeAcronyms from "@/assets/data/kind/ClienTypeAcronyms";
export default {
  components: {
    clientForm,
    itemForm,
    ctypeForm,
  },
  data() {
    return {
      viewTel: "",
      viewBusinessNum: "",
      ClienTypeAcronyms,
    };
  },
  computed: {
    ...mapGetters({
      order: "getOrderForm",
      editedItem: "getEditedItem",
      manufacture: "getManufactureForm",
      release: "getReleaseForm",
      newStock: "getNewStock",
      editStock: "editStockForm",
    }),
    dialog_setting_form() {
      return this.$store.state.isDialog === true &&
        this.$store.state.isKind === "setting_form"
        ? true
        : false;
    },
  },
  methods: {
    validationCheck() {
      switch (this.editedItem.type) {
        case "Item":
          if (!this.editedItem.name) {
            this.$store.commit("setSnackBar", `아이템명을 입력해주세요.`);
            return false;
          }
          if (!this.editedItem.production) {
            this.$store.commit("setSnackBar", `생산처를 선택해주세요.`);
            return false;
          }
          if (!this.editedItem.production_item) {
            this.$store.commit(
              "setSnackBar",
              `아이템명(생산처)를 입력해주세요.`,
            );
            return false;
          }
          break;
        case "Client":
          if (this.editedItem.company_type && !this.editedItem.type_postion) {
            this.$store.commit("setSnackBar", `좌우 구분을 선택해주세요.`);
            return false;
          }
          if (!this.editedItem.name_only) {
            this.$store.commit("setSnackBar", `회사명을 입력해주세요.`);
            return false;
          }
          break;
        case "Buyer":
          if (this.editedItem.company_type && !this.editedItem.type_postion) {
            this.$store.commit("setSnackBar", `좌우 구분을 선택해주세요.`);
            return false;
          }
          if (!this.editedItem.name_only) {
            this.$store.commit("setSnackBar", `회사명을 입력해주세요.`);
            return false;
          }
          break;
        case "Ctype":
          if (!this.editedItem.name) {
            this.$store.commit("setSnackBar", `복종명을 입력해주세요.`);
            return false;
          }
          break;
      }
      return true;
    },
    dupleCheck() {
      if (!this.editedItem._id) {
        switch (this.editedItem.type) {
          case "Client": {
            const item = this.$store.state.clientList.find(
              (x) =>
                x.name === this.editedItem.name &&
                this.getBusinessNumMask(x.business_num) ===
                  this.editedItem.business_num,
            );
            if (item) {
              this.$store.commit("setSnackBar", `중복된 클라이언트 명입니다.`);
              return false;
            }
            break;
          }
          case "Ctype": {
            const item = this.$store.state.ctypeList.find(
              (x) => x.name === this.editedItem.name,
            );
            if (item) {
              this.$store.commit("setSnackBar", `중복된 복종 명입니다.`);
              return false;
            }
            break;
          }
        }
      }
      return true;
    },
    setFormSaveData(saveItem, type, target, post_processing, width) {
      const client = this.$store.state.clientList.find(
        (x) => x.name === saveItem,
      );
      const item = this.$store.state.itemList.find((x) => x.name === saveItem);
      const ctype = this.$store.state.ctypeList.find(
        (x) => x.name === saveItem,
      );

      if (this.$router.currentRoute.name === "order") {
        if (type === "Client") {
          this.order.client = client ? client : this.order.client;
        }
        if (type === "Buyer") {
          this.order.buyer = client ? client : this.order.client;
        }
        if (type === "Item") {
          this.order.item = item ? item : this.order.item;
          this.order.item_price = item.unit_price;
          this.order.item_slope = item.slope;
          this.order.item_oblique = item.oblique;
          this.order.item_variety = item.variety;
          this.order.item_width = item.width;
          this.order.item_density = item.density;
          this.order.item_weight = item.weight;
          this.order.item_post_processing = item.post_processing;
        } else {
          this.order.ctype = ctype ? ctype : this.order.ctype;
        }
      }
      if (this.$router.currentRoute.name === "stock") {
        if (target === "new") this.newStock.item = item;
      }
      if (this.$router.currentRoute.name === "setting") {
        return;
      }
      if (this.$router.currentRoute.name === "production") {
        if (target === "place_manufacture") {
          this.$store.state.manufactureForm.place_manufacture =
            this.$store.state.clientList.find((x) => x.name === saveItem);
        }
        if (target === "inspection") {
          this.$store.state.manufactureForm.inspection =
            this.$store.state.clientList.find((x) => x.name === saveItem);
        }
        if (target === "place_forward") {
          this.$store.state.releaseForm.place_forward =
            this.$store.state.clientList.find((x) => x.name === saveItem);
        }
        if (target === "place_receive") {
          this.$store.state.releaseForm.place_receive =
            this.$store.state.clientList.find((x) => x.name === saveItem);
        }
        if (target === "place_sales") {
          this.$store.state.releaseForm.place_sales =
            this.$store.state.clientList.find((x) => x.name === saveItem);
        }
        if (target === "manuItem") {
          this.$store.state.manufactureForm.item =
            this.$store.state.itemList.find((x) => x.name === saveItem);
          this.$store.state.manufactureForm.width = width ? width : "";
          this.$store.state.manufactureForm.post_processing = post_processing
            ? post_processing
            : "";
        }
        if (target === "item") {
          this.$store.state.releaseForm.item = this.$store.state.itemList.find(
            (x) => x.name === saveItem,
          );
        }
      }
    },
    async saveSettingForm() {
      if (!this.validationCheck()) return;
      if (this.editedItem.type === "Client" || this.editedItem.type === "Buyer")
        this.editedItem.name = `${
          this.editedItem.type_postion === "left" &&
          this.editedItem.company_type
            ? ClienTypeAcronyms[this.editedItem.company_type - 1].label
            : ""
        }${this.editedItem.name_only ? this.editedItem.name_only : ""}${
          this.editedItem.type_postion === "right" &&
          this.editedItem.company_type
            ? ClienTypeAcronyms[this.editedItem.company_type - 1].label
            : ""
        }${
          this.editedItem.name_only && this.editedItem.zone
            ? `[${this.editedItem.zone}]`
            : ""
        }`;
      if (this.editedItem.type === "Item")
        this.editedItem.unit_price = this.editedItem.unit_price
          ? this.uncomma(this.editedItem.unit_price)
          : null;
      if (!this.dupleCheck()) return;
      const saveItem = this.editedItem.name;
      const type = this.editedItem.type;
      const target = this.editedItem.target;
      const post_processing = this.editedItem.post_processing;
      const width = this.editedItem.width;

      this.editedItem.type === "Item"
        ? await this.$store.dispatch("SAVE_ITEM")
        : this.editedItem.type === "Client" || this.editedItem.type === "Buyer"
        ? await this.$store.dispatch("SAVE_CLIENT")
        : await this.$store.dispatch("SAVE_CTYPE");
      this.setFormSaveData(saveItem, type, target, post_processing, width);
    },
  },
};
</script>
