<template>
  <div>
    <ProgressTop />
    <ProgressList />
  </div>
</template>
<script>
import ProgressTop from "@/components/pages/production/progress/ProgressTop.vue";
import ProgressList from "@/components/pages/production/progress/ProgressList.vue";

export default {
  components: {
    ProgressTop,
    ProgressList,
  },
};
</script>
ㅍ
